// TODO: Unify this with boats
import {formatMultiArrayForURL} from '@dmm/lib-common/lib/formatting';
import {multiFacetUrlToArray} from '../multiFacetHelper';
import {getDefaultEnginesSort, sortTypeMap} from '../sortHelper';
import {getCurrencyCode} from '../uomHelper';
import {getCountryCodeFromURL, unslugify} from './shared';
import { getMessages } from '../../tppServices/translations/messages';

export const parseEngineAppUrlReplacers = (boatConstants, t, mappedValue) => {
  const messages = getMessages();
  const parseReplaceRegionParams = (region = '') => {
    if (boatConstants.SEARCH_URL_REGION_PATTERN.test(region)) {
      const matches = boatConstants.SEARCH_URL_REGION_PATTERN.exec(region);
      return {
        region: matches[1]//TODO add some validation to this data.
      };
    }
    return {};
  };

  const parseReplaceCountryParams = (country = '') => {
    country = country ? getCountryCodeFromURL(country) : country;
    if (boatConstants.SEARCH_URL_COUNTRY_PATTERN.test(country)) {
      const matches = boatConstants.SEARCH_URL_COUNTRY_PATTERN.exec(country);
      return {
        country: matches[1].toUpperCase()//TODO add some validation to this data.
      };
    }
    return {};
  };

  const parseReplaceCityParams = (city = '') => {
    if (boatConstants.SEARCH_URL_CITY_PATTERN.test(city)) {
      const matches = boatConstants.SEARCH_URL_CITY_PATTERN.exec(city);
      let cityValue = unslugify(matches[1], '-', ' ').split('+');
      return {
        city: cityValue.join(',')
      };
    }
    return {};
  };

  const parseReplaceLegacyMakeModelParams = (make = '', model = '') => {
    if (boatConstants.SEARCH_URL_MAKE_PATTERN.test(make)) {
      const makeMatches = boatConstants.SEARCH_URL_MAKE_PATTERN.exec(make);
      const makeValue = unslugify(makeMatches[1], ' ', '-');
      const modelMatches = model ? boatConstants.SEARCH_URL_MODEL_PATTERN.exec(model) : '';
      let modelValue = [];
      if (modelMatches) {
        modelValue = [unslugify(modelMatches[1], ' ', '-')];
      }
      return {
        multiFacetedMakeModel: formatMultiArrayForURL([[makeValue, modelValue]])
      };
    }
    return {};
  };

  const parseReplaceMakeModelParams = (makeModel = '') => {
    if (boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.test(makeModel)) {
      const matches = boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.exec(makeModel);
      return {
        multiFacetedMakeModel: formatMultiArrayForURL(multiFacetUrlToArray(matches[1], '+'))
      };
    }
    return {};
  };

  const parseReplaceCategoryParams = (category = '') => {
    if (boatConstants.SEARCH_URL_CATEGORY_PATTERN.test(category)) {
      return {
        category: mappedValue(category.substring(category.indexOf('-') + 1).toLowerCase(), messages.categoryFacet)
      };
    }
    return {};
  };

  const parseReplaceSortParams = (sort = '') => {
    const matches = boatConstants.SEARCH_URL_SORT_PATTERN.exec(sort);
    if (matches) {
      const [sortBy, sortOrder] = matches[1].split(':');
      const sortEng = `${mappedValue(sortBy, messages.search)}:${mappedValue(sortOrder, messages.sortFacet)}`;
      return {sort: sortTypeMap[sortEng] || 'year-desc'};
    }
    return {sort: sortTypeMap[getDefaultEnginesSort()]};
  };

  const parseReplaceKeywordParams = (keyword = '') => {
    if (boatConstants.SEARCH_URL_KEYWORD_PATTERN.test(keyword)) {
      const matches = boatConstants.SEARCH_URL_KEYWORD_PATTERN.exec(keyword);
      return {
        keyword: matches[1]
      };
    }
    return {};
  };

  const parseReplaceConditionParams = (condition = '') => {
    const matches = boatConstants.SEARCH_URL_CONDITION_PATTERN.exec(condition);
    if (matches) {
      return {condition: mappedValue(matches[1], messages.conditionFacet)};
    }
    return {};
  };

  const parseReplaceYearParams = (year = '') => {
    if (boatConstants.SEARCH_URL_YEAR_PATTERN.test(year)) {
      const matches = boatConstants.SEARCH_URL_YEAR_PATTERN.exec(year);
      return {
        year: `${matches[1] || ''}-${matches[2] || ''}`
      };
    }
    return {};
  };

  const parseReplaceFuelParams = (fuelType = '') => {
    if (boatConstants.SEARCH_URL_FUEL_PATTERN.test(fuelType)) {
      const matches = boatConstants.SEARCH_URL_FUEL_PATTERN.exec(fuelType);
      return {
        fuel: mappedValue(matches[1].toLowerCase(), messages.fuelTypeFacet)
      };
    }
    return {};
  };

  const parseReplacePriceParams = (price = '') => {
    if (boatConstants.SEARCH_URL_PRICE_PATTERN.test(price)) {
      const matches = boatConstants.SEARCH_URL_PRICE_PATTERN.exec(price);
      let value = `${matches[1]}-`;
      if (matches[2]) {
        value += `${matches[2]}`;
      }
      return {
        price: value,
        currency: getCurrencyCode(),
      };
    }
    return {};
  };

  const parseReplaceHorsePowerParams = (powerhp = '') => {
    if (boatConstants.SEARCH_URL_POWER_HP_PATTERN.test(powerhp)) {
      const matches = boatConstants.SEARCH_URL_POWER_HP_PATTERN.exec(powerhp);
      let value = `${matches[1]}-`;
      if (matches[2]) {
        value += `${matches[2]}`;
      }
      return {
        powerhp: value,
      };
    }
    return {};
  };

  const parseReplaceByParams = (by = '') => {
    if (boatConstants.SEARCH_URL_BY_PATTERN.test(by)) {
      const matches = boatConstants.SEARCH_URL_BY_PATTERN.exec(by);
      return {
        sellerType: mappedValue(matches[1], messages.forSaleFacet),
      };
    }
    return {};
  };

  const parseReplaceOwnerParams = (owner = '') => {
    if (boatConstants.BRANDED_SEARCH_URL_OWNER_PATTERN.test(owner)) {
      const matches = boatConstants.BRANDED_SEARCH_URL_OWNER_PATTERN.exec(owner);
      const ownerId = matches[3];
      return {
        ownerId
      };
    }
    return {};
  };

  return {
    parseReplaceRegionParams,
    parseReplaceCountryParams,
    parseReplaceCityParams,
    parseReplaceLegacyMakeModelParams,
    parseReplaceMakeModelParams,
    parseReplaceCategoryParams,
    parseReplaceSortParams,
    parseReplaceKeywordParams,
    parseReplaceConditionParams,
    parseReplaceYearParams,
    parseReplaceFuelParams,
    parseReplacePriceParams,
    parseReplaceHorsePowerParams,
    parseReplaceByParams,
    parseReplaceOwnerParams
  };
};

