import * as types from '../constants/dataLayer';

export const initialState = {
  errors: false,
  message: '',
  params: {},
  events: [],
  impressions: []
};

const reducer = (state = initialState, action) => {
  switch (action.type){
  case types.DATALAYER_INIT:
    return {
      ...state,
      errors: false,
      message: '',
      impressions: [],
      params: action.params
    };
  case types.DATALAYER_FAILURE:
    return {
      ...state,
      errors: true,
      message: action.message
    };
  case types.DATALAYER_EVENT:
    return {
      ...state,
      events: [
        ...state.events,
        {
          eventType: action.eventType,
          params: action.params
        }
      ]
    };
  case types.DATALAYER_PRODUCT_IMPRESSION:
  case types.DATALAYER_PRODUCT_ADD:
  case types.DATALAYER_PRODUCT_CLICK:
  case types.DATALAYER_ADD_LEAD:
  case types.DATALAYER_PREMIUM_PLACEMENT_IMPRESSION:
  case types.DATALAYER_PREMIUM_PLACEMENT_CLICK:
  default:
    return state;
  }
};

export default reducer;
