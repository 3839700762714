import {checkNeedsDefaults, getClassCategory, handleLegacyAllType, mapAllClassForType} from '../classHelper';
import get from 'lodash/get';
import {getCurrentLocale} from '../language';
import {multiFacetUrlToObject} from '../multiFacetHelper';
import {formatMultiArrayForURL} from '@dmm/lib-common/lib/formatting';

import {
  swapLocalizedSubdivision
} from './boats';
import {getConfig} from '../../config/portal';
import {getBoatConstantsFromI18n, getMappedValueFromI18n} from '../../tppServices/translations/constants';
import {getFormatMessageFunction} from '../../tppServices/tppDi';
import {getCountryCodeFromURL, initializeGlobalSubdivisionsURLs, unslugify} from './shared';
import { getMessages } from '../../tppServices/translations/messages';
//TODO: Improve injection of config and language for this functions, they only work as default
export const parseBoatsAppUrlParams = (boatConstants, config, t, mappedValue) => {
  const messages = getMessages();
  const parseOwnerParams = (owner = '') => {
    if (boatConstants.BRANDED_SEARCH_URL_OWNER_PATTERN.test(owner)) {
      return {
        owner
      };
    }
    return {};
  };
  const parseRepParams = (rep = '') => {
    if (boatConstants.BRANDED_SEARCH_URL_REP_PATTERN.test(rep)) {
      return {
        rep
      };
    }
    return {};
  };
  const parseSubdivisionParams = (subdivision = '') => {
    const locale = getCurrentLocale();
    initializeGlobalSubdivisionsURLs(locale);
    subdivision = swapLocalizedSubdivision(subdivision, messages, t);
    if (boatConstants.SEARCH_URL_SUBDIVISION_PATTERN.test(subdivision)) {
      const matches = boatConstants.SEARCH_URL_SUBDIVISION_PATTERN.exec(subdivision);
      let twoLetterSubdivision = matches[1].split('|');

      if (!global.url) {
        twoLetterSubdivision = twoLetterSubdivision.map((subdivision) => {
          return subdivision.toUpperCase();
        });
        return {
          subdivision: twoLetterSubdivision[0] //TODO add some validation to this data.
        };
      }
      for (const subdivisionElement of global.url[locale].subdivisions) {
        if (twoLetterSubdivision.includes(subdivisionElement.url)) {
          const subdivisionCode = subdivisionElement.code
            .toUpperCase()
            .split('-')[1];
          return {
            subdivision: subdivisionCode
          };
        }
        if (
          twoLetterSubdivision[0] ===
          subdivisionElement.code.toLowerCase().split('-')[1]
        ) {
          const subdivisionCode = subdivisionElement.code
            .toUpperCase()
            .split('-')[1];
          return {
            subdivision: subdivisionCode
          };
        }
      }
      twoLetterSubdivision = twoLetterSubdivision.map((subdivision) => {
        return subdivision.toUpperCase();
      });
      return {
        subdivision: twoLetterSubdivision[0] //TODO add some validation to this data.
      };
    }
    return {};
  };
  const parseRegionParams = (region = '') => {
    if (boatConstants.SEARCH_URL_REGION_PATTERN.test(region)) {
      const matches = boatConstants.SEARCH_URL_REGION_PATTERN.exec(region);
      return {
        region: mappedValue(matches[1], messages.regionFacet)
      };
    }
    return {};
  };
  const parseWorldRegionParams = (worldRegion = '') => {
    if (boatConstants.SEARCH_URL_WORLD_REGION_PATTERN.test(worldRegion)) {
      const matches = boatConstants.SEARCH_URL_WORLD_REGION_PATTERN.exec(worldRegion);
      return {
        worldRegion: mappedValue(matches[1], messages.worldRegionFacet)
      };
    }
    return {};
  };
  const parseCountryParams = (country = '') => {
    country = country ? getCountryCodeFromURL(country) : country;
    if (boatConstants.SEARCH_URL_COUNTRY_PATTERN.test(country)) {
      const matches = boatConstants.SEARCH_URL_COUNTRY_PATTERN.exec(country);
      return {
        country: matches[1].toUpperCase() //TODO add some validation to this data.
      };
    }
    return {};
  };
  const parseClassGroupParams = (group = '') => {
    if (boatConstants.SEARCH_URL_CLASS_GROUP_PATTERN.test(group)) {
      const matches = boatConstants.SEARCH_URL_CLASS_GROUP_PATTERN.exec(group);
      return {
        group: mappedValue(matches[1], messages.groupRoutes)
      };
    }
    return {};
  };
  const parseModelRangeParams = (modelRange = '') => {
    if (boatConstants.SEARCH_URL_MODELRANGE_PATTERN.test(modelRange)) {
      const matches = boatConstants.SEARCH_URL_MODELRANGE_PATTERN.exec(modelRange);
      return {
        modelRange: matches[1]
      };
    }
    return {};
  };
  const parseLegacyMakeModelParams = (make = '', model = '') => {
    if (boatConstants.SEARCH_URL_MAKE_PATTERN.test(make)) {
      const makeMatches = boatConstants.SEARCH_URL_MAKE_PATTERN.exec(make);
      const makeValue = unslugify(makeMatches[1], ' ', '-');
      const modelMatches = model
        ? boatConstants.SEARCH_URL_MODEL_PATTERN.exec(model)
        : '';
      let modelValue = [];
      if (modelMatches) {
        modelValue = [unslugify(modelMatches[1], ' ', '-')];
      }
      return {
        makeModel: {
          [makeValue]: modelValue
        }
      };
    }
    return {};
  };
  const parseMakeModelParams = (makeModel) => {
    if (boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.test(makeModel)) {
      const matches = boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.exec(makeModel);
      return {
        makeModel: multiFacetUrlToObject(matches[1], true, '+')
      };
    }
    return {};
  };
  const parsePostalCodeParams = (postalCode = '') => {
    if (boatConstants.SEARCH_URL_POSTALCODE_PATTERN.test(postalCode)) {
      const matches = boatConstants.SEARCH_URL_POSTALCODE_PATTERN.exec(postalCode);
      return {
        postalCode: matches[1] //TODO add some validation to this data.
      };
    }
    return {};
  };
  const parseRadiusParams = (radius = '') => {
    if (boatConstants.SEARCH_URL_RADIUS_PATTERN.test(radius)) {
      const matches = boatConstants.SEARCH_URL_RADIUS_PATTERN.exec(radius);
      const radiusVal = matches[1];
      if (radiusVal === t(messages.radiusFacet.any)) {
        return {
          radius: 4000
        };
      }
      return {
        radius: radiusVal //TODO add some validation to this data.
      };
    }
    return {radius: '0'};
  };

  const parseYearParams = (year = '') => {
    if (boatConstants.SEARCH_URL_YEAR_PATTERN.test(year)) {
      const matches = boatConstants.SEARCH_URL_YEAR_PATTERN.exec(year);
      const ret = {year: {}};
      if (matches[1]) {
        ret.year.min = matches[1];
      }
      if (matches[2]) {
        ret.year.max = matches[2];
      }
      return ret;
    }
    return {};
  };
  const parseTypeAndClassParams = (types = '', classes = '') => {
    if (boatConstants.SEARCH_URL_TYPE_PATTERN.test(types)) {
      let decodedClasses = {};
      const typeMatches = boatConstants.SEARCH_URL_TYPE_PATTERN.exec(types);
      let typeValue = typeMatches[1].toLowerCase();
      if (typeValue.split('+')[0] === t(messages.typesFacet.any)) {
        return {multiFacetedBoatTypeClass: {}};
      }
      typeValue.split('+').map((type) => {
        const engType = mappedValue(type, messages.typesFacet);
        decodedClasses = {...decodedClasses, [engType]: []};
      });
      if (boatConstants.SEARCH_URL_CLASS_PATTERN.test(classes)) {
        const classMatches = boatConstants.SEARCH_URL_CLASS_PATTERN.exec(classes);
        let classValue = classMatches[1];
        classValue = unslugify(classValue, '--', '/');
        classValue = unslugify(classValue, ' ', '+');
        classValue.split('+').map((boatClass) => {
          boatClass = mappedValue(boatClass, messages.classFacet);
          boatClass = handleLegacyAllType(typeValue, boatClass);
          if (decodedClasses[getClassCategory(boatClass)]) {
            decodedClasses[getClassCategory(boatClass)].push(boatClass);
          }
        });
        decodedClasses = checkNeedsDefaults(decodedClasses);
        return {multiFacetedBoatTypeClass: decodedClasses};
      }
      decodedClasses = checkNeedsDefaults(decodedClasses);
      return {multiFacetedBoatTypeClass: decodedClasses};
    }
    return {multiFacetedBoatTypeClass: {}};
  };
  const parseTypeAndClassParamsToArray = (types = '', classes = '') => {
    let parsedBoatTypes = parseTypeAndClassParams(types, classes);
    let multiFacetedBoatTypeClass = {
      ...parsedBoatTypes.multiFacetedBoatTypeClass
    };
    multiFacetedBoatTypeClass = mapAllClassForType(multiFacetedBoatTypeClass);
    let boatTypeArray = Object.entries(multiFacetedBoatTypeClass);
    if (boatTypeArray.length) {
      return {multiFacetedBoatTypeClass: formatMultiArrayForURL(boatTypeArray)};
    }
    return {};
  };
  const parseLengthParams = (length = '') => {
    if (boatConstants.SEARCH_URL_LENGTH_PATTERN.test(length)) {
      const matches = boatConstants.SEARCH_URL_LENGTH_PATTERN.exec(length);
      let value = {min: matches[1]};
      if (matches[2]) {
        value.max = matches[2];
      }
      return {
        length: value
      };
    }
    return {};
  };
  const parsePriceParams = (price = '') => {
    if (boatConstants.SEARCH_URL_PRICE_PATTERN.test(price)) {
      const matches = boatConstants.SEARCH_URL_PRICE_PATTERN.exec(price);
      let value = {min: matches[1]};
      if (matches[2]) {
        value.max = matches[2];
      }
      return {
        price: value
      };
    }
    return {};
  };
  const parseCurrencyParams = (currency = '') => {
    if (!currency) {
      return {};
    }

    const currencyParts = currency.split('-');
    if (!currencyParts[1]) {
      return {};
    }

    return {
      currency: currencyParts[1]
    };
  };
  const parsePageParams = (page = '', isBranded) => {
    const pageSize = get(
      config,
      `pages.${
        isBranded ? 'brandedOemSearchResults' : 'searchResults'
      }.pagination.pageSize`,
      28
    );
    const def = {page: '1', pageSize: `${pageSize}`};
    if (boatConstants.SEARCH_URL_PAGE_PATTERN.test(page)) {
      const matches = boatConstants.SEARCH_URL_PAGE_PATTERN.exec(page);
      return {
        page: matches[1],
        pageSize: `${pageSize}`
      };
    }
    return def;
  };
  const parseSortParams = (sort = '') => {
    const matches = boatConstants.SEARCH_URL_SORT_PATTERN.exec(sort);
    if (matches) {
      const [sortBy, sortOrder] = matches[1].split(':');
      return {
        sort: `${mappedValue(sortBy, messages.search)}:${mappedValue(
          sortOrder,
          messages.sortFacet
        )}`
      };
    }
    return {sort: undefined};
  };
  const parseKeywordParams = (keyword = '') => {
    if (boatConstants.SEARCH_URL_KEYWORD_PATTERN.test(keyword)) {
      const matches = boatConstants.SEARCH_URL_KEYWORD_PATTERN.exec(keyword);
      return {
        keyword: matches[1]
      };
    }
    return {keyword: ''};
  };
  const parseConditionParams = (condition = '') => {
    if (boatConstants.SEARCH_URL_CONDITION_PATTERN.test(condition)) {
      const matches = boatConstants.SEARCH_URL_CONDITION_PATTERN.exec(condition);
      return {
        condition: mappedValue(matches[1], messages.conditionFacet)
      };
    }
    return {condition: 'any'};
  };
  const parseEngineTypeParams = (engine = '') => {
    if (boatConstants.SEARCH_URL_ENGINETYPE_PATTERN.test(engine)) {
      const decodedEngineTypes = [];
      const matches = boatConstants.SEARCH_URL_ENGINETYPE_PATTERN.exec(engine);
      const engineTypes = matches[1];

      engineTypes.split('+').map((engineType) => {
        engineType = mappedValue(engineType, messages.engineFacetText);
        engineType = unslugify(engineType, '+', '-');
        decodedEngineTypes.push(engineType);
      });
      return {
        engine: decodedEngineTypes
      };
    }
    return {};
  };
  const parseEngineMakeParams = (engineMake = '') => {
    if (boatConstants.SEARCH_URL_ENGINE_MAKE_PATTERN.test(engineMake)) {
      const decodedEngineMakes = [];
      const matches = boatConstants.SEARCH_URL_ENGINE_MAKE_PATTERN.exec(engineMake);
      const engineMakes = matches[1];

      engineMakes.split('+').map((make) => {
        make = unslugify(make, '+', '-');
        decodedEngineMakes.push(make);
      });
      return {
        engineMake: decodedEngineMakes
      };
    }
    return {};
  };
  const parseFuelParams = (fuelType = '') => {
    if (boatConstants.SEARCH_URL_FUEL_PATTERN.test(fuelType)) {
      const decodedFuelTypes = [];
      const matches = boatConstants.SEARCH_URL_FUEL_PATTERN.exec(fuelType);
      const fuelTypes = matches[1];

      fuelTypes.split('+').map((fuel) => {
        fuel = mappedValue(fuel, messages.fuelTypeFacet);
        fuel = unslugify(fuel, '+', '-');
        decodedFuelTypes.push(fuel);
      });
      return {
        fuelType: decodedFuelTypes
      };
    }
    return {};
  };
  const parseHullParams = (hullMaterial = '') => {
    if (boatConstants.SEARCH_URL_HULL_PATTERN.test(hullMaterial)) {
      const decodedHullMaterials = [];
      const matches = boatConstants.SEARCH_URL_HULL_PATTERN.exec(hullMaterial);
      const hullMaterials = matches[1];
      hullMaterials.split('+').map((material) => {
        material = mappedValue(material, messages.hullMaterialFacet);
        material = unslugify(material, '+', '-');
        decodedHullMaterials.push(material);
      });
      return {
        hullMaterial: decodedHullMaterials
      };
    }
    return {};
  };
  const parseCityParams = (city = '') => {
    if (boatConstants.SEARCH_URL_CITY_PATTERN.test(city)) {
      const matches = boatConstants.SEARCH_URL_CITY_PATTERN.exec(city);
      let cityValue = matches[1].split('+');
      return {
        city: cityValue
      };
    }
    return {};
  };
  const parseModalParams = (modal = '') => {
    if (boatConstants.SEARCH_URL_MODAL_PATTERN.test(modal)) {
      const matches = boatConstants.SEARCH_URL_MODAL_PATTERN.exec(modal);
      let modals = matches[1].split('+');
      return {
        modal: modals
      };
    }
  };
  const parseByParams = (by = '') => {
    if (boatConstants.SEARCH_URL_BY_PATTERN.test(by)) {
      const matches = boatConstants.SEARCH_URL_BY_PATTERN.exec(by);
      return {
        forSale: mappedValue(matches[1], messages.forSaleFacet)
      };
    }
  };
  const parseEngineNumberParams = (numberOfEngines) => {
    if (boatConstants.SEARCH_URL_ENGINE_NUMBER_PATTERN.test(numberOfEngines)) {
      const matches = boatConstants.SEARCH_URL_ENGINE_NUMBER_PATTERN.exec(numberOfEngines);
      return {
        numberOfEngines: matches[1]
      };
    }
    return {};
  };
  const parseEngineOneHoursParams = (engineOneHours) => {
    if (boatConstants.SEARCH_URL_ENGINE_HOURS_PATTERN.test(engineOneHours)) {
      const matches = boatConstants.SEARCH_URL_ENGINE_HOURS_PATTERN.exec(engineOneHours);
      const ret = {engineOneHours: {}};
      if (matches[1]) {
        ret.engineOneHours.min = matches[1];
      }
      if (matches[2]) {
        ret.engineOneHours.max = matches[2];
      }
      return ret;
    }
    return {};
  };
  return {
    parseOwnerParams,
    parseRepParams,
    parseSubdivisionParams,
    parseRegionParams,
    parseWorldRegionParams,
    parseCountryParams,
    parseClassGroupParams,
    parseModelRangeParams,
    parseLegacyMakeModelParams,
    parseMakeModelParams,
    parsePostalCodeParams,
    parseRadiusParams,
    parseYearParams,
    parseTypeAndClassParams,
    parseLengthParams,
    parsePriceParams,
    parseCurrencyParams,
    parsePageParams,
    parseSortParams,
    parseKeywordParams,
    parseConditionParams,
    parseEngineTypeParams,
    parseEngineMakeParams,
    parseFuelParams,
    parseHullParams,
    parseCityParams,
    parseModalParams,
    parseByParams,
    parseEngineNumberParams,
    parseEngineOneHoursParams,
    parseTypeAndClassParamsToArray
  };
};

// Helper function to get the parser functions
export const getCommonBoatParsers = (i18nService, config) => {
  if (!config) {
    // TODO: remove this call when needed
    config = getConfig();
  }
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const t = getFormatMessageFunction(i18nService);
  const mappedValue = getMappedValueFromI18n(i18nService);

  const {
    parsePageParams, parseLengthParams, parseSortParams
  } = parseBoatsAppUrlParams(boatConstants, config, t, mappedValue, i18nService);
  return {
    parsePageParams, parseLengthParams, parseSortParams
  };
};
