import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import BreadCrumb from '../BreadCrumb';
import Status from '../Status';


import { MaintenanceMesage } from '@dmm/lib-react-ui-components';
import './styles.css';

import {getTPPServices} from '../../tppServices/tppDi';
import { getMessages } from '../../tppServices/translations/messages';

const RegularErrorMessage = ({wrong, message, altUrl, reset}) => {
  return (
    <>
      <h1>{wrong}</h1>
      <div>
        <p>{message}</p>
        {altUrl ? (
          <p>
            <a id="error-msg-reset" href={altUrl}>
              {reset}
            </a>
          </p>
        ) : null}
      </div>
    </>
  );
};

RegularErrorMessage.propTypes = {
  wrong: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  altUrl: PropTypes.string,
  reset: PropTypes.string.isRequired
};

const ErrorMessage = (props) => {
  const {languageService} = getTPPServices();
  const routesConstants = languageService.getRouteConstants();
  const currentI18n = languageService.getI18n();
  const t = currentI18n.intl.formatMessage;
  const { serverError } = props;
  const showMaintenance = props.statusCode >= 500;
  const messages = getMessages();
  let defaultMessage = t(messages.error.genericMessage);
  let message = get(props, 'message', defaultMessage);
  let altUrl = get(props, 'altUrl');
  if (serverError) {
    message = t(messages.error.genericMessage);
    altUrl = routesConstants.SEARCH_URL_ROOT;
  }
  return (
    <>
      <Status {...props} />
      <div id="main-content" className="error-msg">
        <BreadCrumb
          items={[
            {
              title: t(messages.home),
              link: '/'
            },
            {
              title: t(messages.error.server)
            }
          ]}
        />
        <div className="error-msg-content">
          {showMaintenance
            ?  <MaintenanceMesage message={t(messages.error.maintenance.message)} submessage={t(messages.error.maintenance.submessage)} />
            :  <RegularErrorMessage wrong={t(messages.error.wrong)} message={message} altUrl={altUrl} reset={t(messages.error.reset)} />
          }
        </div>
      </div>
    </>
  );
};

ErrorMessage.defaultProps = {
  altUrl: '',
  message: undefined,
  serverError: false,
  staticContext: null
};

ErrorMessage.propTypes = {
  /** Alt URL for "Reseat Search" */
  altUrl: PropTypes.string,
  /** Error message */
  message: PropTypes.string,
  /** Unexpected server error flag */
  serverError: PropTypes.bool,
  statusCode: PropTypes.number,
  /** StaticRouter context property */
  staticContext: PropTypes.shape({
    statusCode: PropTypes.number
  })
};

export default ErrorMessage;
