import get from 'lodash/get';

export const isServer = () => Boolean(get(process, 'release.name'));

export const isSingleTypeFilter = (params) => {
  if (Object.keys(params).length !== 1 || !params.multiFacetedBoatTypeClass) {
    return false;
  }

  try {
    return JSON.parse(params.multiFacetedBoatTypeClass).length === 1;
  } catch (err) {
    return false;
  }
};

export const isSingleClassFilter = (params) => {
  if (Object.keys(params).length !== 1 || !params.multiFacetedBoatTypeClass) {
    return false;
  }

  try {
    const parsed = JSON.parse(params.multiFacetedBoatTypeClass);
    return parsed.length === 2 && parsed[1].length === 1;
  } catch (err) {
    return false;
  }
};

export const isSingleMakeFilter = (params) => {
  try {
    if (
      Object.keys(params).length === 1 &&
      params.multiFacetedMakeModel &&
      JSON.parse(params.multiFacetedMakeModel).length === 1
    ) {
      return true;
    }
  } catch (err) {
    return false;
  }

  return false;
};

export const isSingleModelFilter = (params) => {
  try {
    if (
      Object.keys(params).length === 1 &&
      params.multiFacetedMakeModel &&
      JSON.parse(params.multiFacetedMakeModel).length === 2 &&
      JSON.parse(params.multiFacetedMakeModel)[1].length === 1
    ) {
      return true;
    }
  } catch (err) {
    return false;
  }
  return false;
};

export const isSingleCountryFilter = (params) => {
  if (Object.keys(params).length === 1 && params.country) {
    return true;
  }
  return false;
};

export const isSingleStateFilter = (params) => {
  if (Object.keys(params).length === 2 && params.country && params.subdivision) {
    return true;
  }
  return false;
};

export const isSingleCityFilter = (params) => {
  if ( ['US', 'GB', 'AU', 'CA'].includes(params.country) ){
    if (Object.keys(params).length === 3 && params.country && params.subdivision && params.city) {
      return true;
    }
  } else if (Object.keys(params).length === 2 && params.country && params.city) {
    return true;
  }
  return false;
};

export const showWordsmithContent = (params) => {
  if (isSingleClassFilter(params)) {
    return 'class';
  }
  if (isSingleMakeFilter(params)) {
    return 'make';
  }
  if (isSingleCountryFilter(params)) {
    return 'country';
  }
  if (isSingleStateFilter(params)) {
    return 'subdivision';
  }
  if (isSingleCityFilter(params)) {
    return 'city';
  }
  return undefined;
};

export const showRelatedBoatArticles = (params) => {
  if (isSingleModelFilter(params)) {
    return 'model';
  }
  if (isSingleMakeFilter(params)) {
    return 'make';
  }
  if (isSingleClassFilter(params)) {
    return 'class';
  }
  return undefined;
};

export const showVideos = (params) => {
  if (isSingleTypeFilter(params)) {
    return 'type';
  }
  if (isSingleClassFilter(params)) {
    return 'class';
  }
  if (isSingleMakeFilter(params)) {
    return 'make';
  }
  if (isSingleModelFilter(params)) {
    return 'model';
  }
  return undefined;
};
