import get from 'lodash/get';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import omit from 'lodash/omit';
import max from 'lodash/max';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';

import { unhyphenateUrlComponents } from './commonHelper';
import { slugify, unslugify } from './urlHelpers/shared';

export const toggleSubFacet = (facetName, subFacet, selectedFacets, allowEmptySubFacet) => {
  let currentSubFacets = get(selectedFacets, facetName, []);
  let newSubFacets;
  if (includes(currentSubFacets, subFacet)){
    newSubFacets = filter(currentSubFacets, facetItem => facetItem !== subFacet);
  } else {
    newSubFacets = [...currentSubFacets, subFacet];
  }
  if (newSubFacets.length || allowEmptySubFacet) {
    return {
      ...selectedFacets,
      [facetName]: newSubFacets
    };
  }
  return omit(selectedFacets, facetName);
};

export const multiFacetUrlToObject = (string, shouldSlugify, splitter = ',', pairSplitter = ':') => {
  return string.split(splitter)
    .map(facetPair => facetPair.split(pairSplitter))
    .reduce((facets, [facetName, facetValue]) => {
      let newFacet;
      if (!facetValue) {
        newFacet = [];
      } else {
        if (shouldSlugify){
          facetValue = slugify(unslugify(facetValue));
        }
        newFacet = [...get(facets, facetName, []), facetValue];
      }
      const newFacets = {
        ...facets,
        [facetName]: newFacet
      };
      return newFacets;
    }, {});
};

export const multiFacetUrlToArray = (string, splitter = ',', pairSplitter = ':') => {
  const facets = multiFacetUrlToObject(string, false, splitter, pairSplitter);
  return Object.entries(facets);
};

export const formatMultiArrayUrlToArray = (string = '', separator = '|') => {
  return string.split(separator).map(item => item ? JSON.parse(item) : item);
};

export const formatMultiArrayUrlToKeywords = (string = '') => {
  try {
    const arrMakeModel = formatMultiArrayUrlToArray(string);
    const reducedArray = arrMakeModel.reduce((acc, val) => acc.concat(flatten(val)), []);
    const normalizedArray = reducedArray.map((item) => {
      return unhyphenateUrlComponents(item);
    });
    return uniq(normalizedArray);
  } catch (e) {
    return [];
  }
};

export const emptySubFacet = (facetName, selectedFacets) => {
  return {
    ...selectedFacets,
    [facetName]: []
  };
};

export const isSingleFaceted = (multiFacetObject) => {
  if (isArray(multiFacetObject)) {
    return multiFacetObject.length < 2;
  }
  const facets = Object.entries(multiFacetObject);
  const facetsLength = facets.length;
  const maxSubFacetLength = max(facets.map(([, value]) => value.length)) || 0;
  return facetsLength < 2 && maxSubFacetLength < 2;
};

export const getFilteredFacet = (facets, selectedFacets, maxFacetCount) => {
  return facets
    .filter((facetItem, index) => {
      if (includes(selectedFacets, slugify(facetItem.value))){
        return true;
      }
      if (maxFacetCount) {
        return facetItem.count && index + 1 < maxFacetCount;
      }
      return facetItem.count;
    });
};

export const getRegions = (facets, country) => {
  const regionFacets = get(facets, 'region', []);
  const regionsCountry = find(regionFacets, ['value', country]);
  return get(regionsCountry, 'regions', []);
};

export const getSubdivisions = (facets, country) => {
  const subdivisionsFacets = get(facets, 'countrySubdivision', []);
  const countrySubdivisions = find(subdivisionsFacets, ['value', country]);
  return get(countrySubdivisions, 'subdivision', []);
};

export const getCities = (facets, country) => {
  const countryCities = get(facets, 'countryCity', []);
  const cities = find(countryCities, ['value', country]);
  return  get(cities, 'city', []);
};


export const formatMultiFacetParam = (param) => {
  if (!param) {return undefined;}

  const splittedParam = param.split('|');
  const multifacetedParamArray = splittedParam.reduce(
    (acc, current) =>
    { acc.push(JSON.parse(current));
      return acc;
    }, []
  );

  return multifacetedParamArray;
};

