import { getDefaultParams } from './getDefaultParams';

export const getActiveParams = (params) => {
  const defaults = getDefaultParams({});
  const active = Object.entries(params).filter(([key, value]) => {
    return defaults[key] !== value;
  });

  const allActive = active.reduce(
    (object, [key, value]) => ({ ...object, [key]: value }),
    {}
  );

  return allActive;
};
