import get from 'lodash/get';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';
import pullAll from 'lodash/pullAll';
import isNil from 'lodash/isNil';

export const isServer = () => get(process, 'release.name');

export const yieldToMain = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 0);
  });
};

export const entriesToString = (entries) => {
  const entriesString = Object.entries(entries)
    .map(([key, value]) => {
      if (value) {
        return key;
      }
    })
    .filter((entry) => !!entry)
    .join(' ');
  return entriesString || null;
};

export const isOverlapped = (element) => {
  return element.scrollHeight > element.clientHeight;
};

export const isOuterClick = (element, container) => {
  let target = element;
  while (target) {
    if (target === container) {
      return false;
    }
    target = target.parentNode;
  }
  return true;
};

export const isValidFilters = (params, allowedParams) => {
  let selectedParams = keys(omitBy(params, (value) => isNil(value)));
  selectedParams = pullAll(selectedParams, allowedParams);
  return selectedParams.length !== 0 ? false : true;
};

export const asArray = (ob) => (!ob ? [] : Array.isArray(ob) ? ob : [ob]);

export const asString = (str) =>
  str === null || str === undefined ? '' : String(str);

export const envProtocol = (url) => {
  if (!process.env.USE_HTTP) {
    url = url.replace('http://', 'https://');
  }
  if (process.env.REACT_APP_LOCAL_DEBUG) {
    url = url.replace('https://', 'http://');
  }
  return url;
};

export const overflowContainer = (overflow, container) => {
  const el = container || document.querySelector('.page-container');
  const className = 'overflow-hidden';
  if (overflow) {
    // eslint-disable-next-line no-unused-expressions
    el?.classList.add(className);
  } else {
    // eslint-disable-next-line no-unused-expressions
    el?.classList.remove(className);
  }
};

// only for development, no need to cover
// istanbul ignore next
export const callOnDev = (action) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
    action();
  }
};

/* istanbul ignore next */
// eslint-disable-next-line no-undef
export const objectClone = (obj) => typeof structuredClone === 'function' ? structuredClone(obj) :
  JSON.parse(JSON.stringify(obj));

// We just need to test once
export const definedValue = (val, definedDefault) => val ?? definedDefault;
