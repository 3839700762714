import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RenderHTML } from '@dmm/react-common-components';
import get from 'lodash/get';
import replace from 'lodash/replace';
import { getConfig } from '../../config/portal';

const REPLACE_URL_PATTERN = /{{baseUrl}}/g;

const EditorialItem = (props) => {
  const { classOverride, content, header, title } = props;
  let updatedContent = replace(
    content,
    REPLACE_URL_PATTERN,
    get(getConfig(), 'client.editorial.baseUrl')
  );
  return (
    <div
      className={classnames('editorial-content', {
        [classOverride]: classOverride
      })}
    >
      {title && <h1>{title}</h1>}
      {header && <h2>{header}</h2>}
      <RenderHTML html={updatedContent} />
    </div>
  );
};

EditorialItem.propTypes = {
  /** The content to be displayed either as html or plain text */
  content: PropTypes.string.isRequired,
  /** a class name to allow specific styling */
  classOverride: PropTypes.string,
  /** Header to be displayed */
  header: PropTypes.string,
  /** Title to be displayed  */
  title: PropTypes.string
};

export default EditorialItem;
