import get from 'lodash/get';
import { GDPR_COUNTRY_CODES, ONE_YEAR_MILLIS } from '../constants';
import { COOKIE_CLASSIFICATION_CMP, GDPR_COOKIE } from '../constants/cookies';
import * as httpClient from './httpClient';
import { getConfig } from '../config/portal';
import { canCreateCookies } from '@dmm/lib-node-cookies/dist/utils/trustarc';
import { Cookies } from '@dmm/lib-node-cookies';

export const cookiesLib = new Cookies();

export const getLocationIp = async (req) => {
  const apiClient = httpClient.getHttpClient();
  let url = '/location/ip';
  try {
    const { REACT_APP_TEST_IP } = process.env;
    url += REACT_APP_TEST_IP ? `?testIp=${REACT_APP_TEST_IP}` : '';
    let params = {};
    if (req) {
      params.headers = { ...req.headers };
    }
    const response = await apiClient.get(url, params);
    const countryCode = get(response, 'data.countryCode', '');
    return countryCode;
  } catch (err) {
    return '';
  }
};

export const initGdprCookie = (res, userCountryCode, gdprCountries) => {
  if (userCountryCode) {
    if (!gdprCountries || gdprCountries.length === 0) {
      gdprCountries = GDPR_COUNTRY_CODES;
    }
    res.cookie(GDPR_COOKIE, gdprCountries.includes(userCountryCode), {
      path: '/',
      expires: new Date(Date.now() + ONE_YEAR_MILLIS)
    });
  }
};

export const setCookie = (
  cookies,
  cookieName,
  cookieValue,
  options,
  classification
) => {
  // getConfig might throw. This function must be fixed
  const supportCMP = getConfig()?.supports?.cmp || false;
  const type = classification || COOKIE_CLASSIFICATION_CMP[cookieName];
  if (supportCMP && canCreateCookies(type, cookies.cookies)) {
    cookies.set(cookieName, cookieValue, options);
  } else if (!supportCMP) {
    cookies.set(cookieName, cookieValue, options);
  }
};

// getConfig might throw. This function must be fixed
export const isTypeCookieAllowed = (cookieName, cookies) =>
  canCreateCookies(COOKIE_CLASSIFICATION_CMP[cookieName], cookies) ||
  !(getConfig()?.supports?.cmp || false);
