// TODO: Unify this with engines
import {getDefaultI18Service} from '../../tppServices/tppDi';
import get from 'lodash/get';
import set from 'lodash/set';
import isString from 'lodash/isString';
import {normalizeString} from '../commonHelper';
import {allowedCharactersInUrl} from '../../constants/boats';
import {getCurrentLocale} from '../language';

const validChars = new RegExp(
  `[^${allowedCharactersInUrl}]`,
  'g'
);

export const slugify = (string, joiner = '+') =>
  isString(string) ? string.toLowerCase().split(' ').join(joiner) : '';
export const unslugify = (string, splitter = '+', joiner = ' ') =>
  isString(string) ? string.toLowerCase().split(splitter).join(joiner) : '';

export const sharedHyphenate = (
  string,
  removeSpecialCharacters = true) => {
  if (!isString(string)) {
    return '';
  }
  string = string.toLowerCase();
  // Remove accents and special characters
  if (removeSpecialCharacters) {
    string = normalizeString(string);
    string = string.replace(validChars, ' ');
  }
  string = string
  .replace(/ +/g, ' ')
  .trim()
  .replace(/&/g, '$')
  .replace(/-/g, '--')
  .replace(/ /g, '-');

  return string;
};

export const initializeGlobalCountriesURLs = (locale = '', i18Service) => {
  if (locale.length === 2) {
    // istanbul ignore next
    if (!i18Service) {
      i18Service = getDefaultI18Service();
    }
    const languageService = i18Service.languageService();
    if (!get(global, `url.${locale}`)) {
      set(global, `url.${locale}`, {});
    }
    if (!global.url[locale].countries) {
      const countriesFromTranslation = languageService.getTranslationMessages(locale);
      let countries = [];
      let country = '';
      for (let prop in countriesFromTranslation) {
        if (prop === 'routes.search.item.country') {
          country = countriesFromTranslation[prop];
        }
        if (prop.match(/app.countries.([A-Z]{2})/g)) {
          try {
            const countryTranslatedName = sharedHyphenate(
              countriesFromTranslation[prop]
            );
            countries.push({
              code: prop.replace('app.countries.', '').toLowerCase(),
              url: countryTranslatedName.trim()
            });
          } catch (e) {
            // Comment to avoid the empty block statement :(
          }
        }
      }
      global.url[locale].country = country;
      global.url[locale].countries = countries;
    }
  }
};
export const initializeGlobalSubdivisionsURLs = (locale = '', i18Service) => {
  if (locale.length === 2) {
    // istanbul ignore next
    if (!i18Service) {
      i18Service = getDefaultI18Service();
    }
    const languageService = i18Service.languageService();
    if (!get(global, `url.${locale}`)) {
      set(global, `url.${locale}`, {});
    }
    if (!global.url[locale].subdivisions) {
      const subdivisionsFromTranslation = languageService.getTranslationMessages(locale);
      let subdivisions = [];
      let subdivision = '';
      for (let prop in subdivisionsFromTranslation) {
        if (prop === 'routes.search.item.subdivision') {
          subdivision = subdivisionsFromTranslation[prop];
        }
        if (
          prop.match(
            /app.countrySubdivision.([a-zA-Z0-9]{2}-[a-zA-Z0-9]{1,5})/g
          )
        ) {
          try {
            const subdivisionTranslatedName = sharedHyphenate(
              subdivisionsFromTranslation[prop],
              true,
              true
            );
            subdivisions.push({
              code: prop.replace('app.countrySubdivision.', '').toLowerCase(),
              url: subdivisionTranslatedName.trim()
            });
          } catch (e) {
            // Comment to avoid the empty block statement :(
          }
        }
      }
      global.url[locale].subdivision = subdivision;
      global.url[locale].subdivisions = subdivisions;
    }
  }
};
export const getCountryCodeFromURL = (url, overrideLocale, i18Service) => {
  if (url) {
    const locale = overrideLocale ? overrideLocale : getCurrentLocale();
    initializeGlobalCountriesURLs(locale, i18Service);
    const prefix = global.url[locale].country;
    const prefixRegExp = new RegExp(`${prefix}-`);
    const urls = global.url[locale].countries.filter((country) => {
      return country.url === url.replace(prefixRegExp, '');
    });
    return urls.length > 0 ? `${prefix}-${urls[0].code}` : url;
  }
  return url;
};
export const getCountryURLFromCode = (code, overrideLocale) => {
  if (code) {
    const locale = overrideLocale ? overrideLocale : getCurrentLocale();
    initializeGlobalCountriesURLs(locale);
    const prefix = global.url[locale].country;
    const urls = global.url[locale].countries.filter((country) => {
      const prefixRegExp = new RegExp(`${prefix}-`, 'g');
      return country.code === code.replace(prefixRegExp, '');
    });
    return urls.length > 0 ? `${prefix}-${urls[0].url}` : code;
  }
  return code;
};

/**
 * Formats a given string by removing apostrophes, replacing multiple dashes with a single dash,
 * replacing commas and full stops with dashes, and removing round brackets.
 * Note: This function is specifically for formatting strings to be used in URLs.
 *
 * @param {string} str - The string to be formatted.
 * @returns {string} - The formatted string.
 */
export const sanitizeString = (str) => {
	const string = str
    // Remove apostrophes
    .replace(/'/g, '')
    // Replace multiple dashes
    .replace(/-+/g, '-')
    // Replace commas or fullstop
    .replace(/[,.]/g, '-')
    // Replace round brackets
    .replace(/[()]/g, '');
  return string;
};

