import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import get from 'lodash/get';

import has from 'lodash/has';
import isString from 'lodash/isString';
import omit from 'lodash/omit';

import without from 'lodash/without';
import isObject from 'lodash/isObject';
import upperFirst from 'lodash/upperFirst';
import {generatePath, matchPath} from 'react-router';
import {normalizeString as normalizeMake} from '@dmm/lib-common/lib/formatting';
import {toggleSubFacet} from '../multiFacetHelper';
import {normalizeString, translateMake} from '../commonHelper';
import {getCurrentLocale, getOverridableBoats, getOverridableRoutes} from '../language';
import {getCurrencyFormat, getCurrencySymbol} from '../uomHelper';
import {getBoatsConstants} from '../../constants/boats/index';
import {getConfig} from '../../config/portal';
import { getDefaultI18Service, getFormatMessageFunction } from '../../tppServices/tppDi';
import {getBoatConstantsFromI18n, getMappedValueFromI18n} from '../../tppServices/translations/constants';
import {parseEngineAppUrlReplacers} from './engineConstantsReplacers';
import {parseEnginesAppUrlParams} from './engineConstantsParsers';
import {getCountryURLFromCode, initializeGlobalSubdivisionsURLs, slugify, unslugify} from './shared';
import { getMessages } from '../../tppServices/translations/messages';
import { syncTranslationElements } from '../../tppServices/translations/intlManager';

export const searchPathIsActive = () => false;

export const searchBrandedPathIsActive = url =>
  !!matchPath(decodeURI(url), getBoatConstantsFromI18n().ENGINES_BRANDED_SEARCH_URL_PATTERN);

export const hyphenateUrlComponents = (string, removeSpecialCharacters = true) => {
  if (!isString(string)) {return '';}
  string = string.toLowerCase();
  // Remove accents and special characters
  if (removeSpecialCharacters) {
    string = normalizeString(string);
    const validChars = new RegExp(`[^${getBoatsConstants().ALLOWED_CHARACTERS_IN_URL}]+`, 'g');
    string = string.replace(validChars, ' ');
  }
  string = string.replace(/[ ]+/g, ' ');
  string = string.split('&').join('$');
  string = string.split('-').join('--');

  string = slugify(string, '-');
  string = string.replace(/-$/, '');
  string = string.replace(/^-/, '');
  return string;
};

const generateMakeAndModelPathParam = (makeModel) => {
  let makes = Object.keys(makeModel).length;
  if (makes === 1) {
    let models = 0;
    Object.values(makeModel).map( item => {
      models += item.length;
    });
    if (models < 2) {
      let make = Object.keys(makeModel)[0];
      let makeMod = { 'make': make };
      if (models === 1) {
        makeMod.model = makeModel[make][0];
      }
      return makeMod;
    }
  }
  return null;
};

export const getEnginesDealerGalleryPattern = (cfg, bt, owner, dealerStr) => {
  const re = new RegExp(`${dealerStr}-(.*)`);
  const dealerPrefix = re.test(owner);
  const searchPattern = dealerPrefix ?
    bt.GENERATE_ENGINES_DEALER_GALLERY_URL_PATTERN :
    bt.GENERATE_ENGINES_BRANDED_SEARCH_URL_PATTERN;
  return searchPattern;
};

export const generateSearchPath = (changes, urlProps, newSearch, overrideLocale, i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  const t = getFormatMessageFunction(i18Service, overrideLocale);
  const messages = getMessages();
  // locale is overriden only in translating location
  const config = getOverridableRoutes(overrideLocale);
  const bt = getOverridableBoats(overrideLocale);
  const tr = (message, params) => t(message, params, overrideLocale);

  let removeLastSlash = false;

  let initialParams = {
    ...urlProps,
    ...changes
  };

  let finalParams = {};

  if (initialParams.modal) {
    delete initialParams.modal;
  }

  if (initialParams.makeModel) {
    if (!isEmpty(initialParams.makeModel)) {
      let seperateMakeModel = generateMakeAndModelPathParam(initialParams.makeModel);
      if ( seperateMakeModel ) {
        finalParams.make = `${config.SEARCH_URL_ITEM_MAKE}-${seperateMakeModel.make}`;
        if (seperateMakeModel.model) {
          finalParams.model = `${config.SEARCH_URL_ITEM_MODEL}-${seperateMakeModel.model}`;
        }
      }
      else {
        const models = flatten(Object.keys(initialParams.makeModel)
          .map(makeName => {
            const models = Object.values(get(initialParams.makeModel, makeName, []));
            if (models.length) {
              return models.map(modelName => {
                return `${makeName}:${modelName}`;
              });
            }
            return makeName;
          })).filter(makeModel => !!makeModel);
        finalParams.makeModel = `${config.SEARCH_URL_ITEM_MAKEMODEL}-${models.join('+')}`;
      }
    }
    delete initialParams.makeModel;
  }

  if (initialParams.sort) {
    const [sortBy, sortOrder] = initialParams.sort.split(':');
    const translatedSort = `${tr(messages.search[sortBy])}:${tr(messages.sortFacet[sortOrder])}`;
    finalParams.sort = `${config.SEARCH_URL_ITEM_SORT}-${translatedSort}`;
    delete initialParams.sort;
  }

  if (initialParams.condition) {
    if (initialParams.condition !== 'any') {
      const translatedCondition = tr(messages.conditionFacet[initialParams.condition]);
      finalParams.condition = `${config.SEARCH_URL_ITEM_CONDITION}-${translatedCondition}`;
    }
    delete initialParams.condition;
  }

  if (initialParams.keyword) {
    if (initialParams.searchKeywordParamFormat && initialParams.searchKeywordParamFormat === 'query-string') {
      removeLastSlash = true;
      finalParams.keyword = `?${config.SEARCH_URL_ITEM_KEYWORD}=${initialParams.keyword}`;
    } else {
      finalParams.keyword = `${config.SEARCH_URL_ITEM_KEYWORD}-${initialParams.keyword}`;
    }
    delete initialParams.searchKeywordParamFormat;
    delete initialParams.keyword;
  }

  if (!isEmpty(initialParams.year)) {
    let year = [initialParams.year.min, bt.DEFAULT_MAX_YEAR + 1];

    if (initialParams.year.max && initialParams.year.max < bt.DEFAULT_MAX_YEAR + 1) {
      year[1] = initialParams.year.max;
    }

    finalParams.year = config.SEARCH_URL_ITEM_YEAR + '-' + year.join(',');

    delete initialParams.year;
  }

  if (initialParams.fuel) {
    const translatedFuel = tr(messages.fuelTypeFacet[initialParams.fuel]);
    finalParams.fuel = `${config.SEARCH_URL_ITEM_FUEL}-${translatedFuel.toLowerCase()}`;
    delete initialParams.fuel;
  }

  if (initialParams.category) {
    const translatedCategory = tr(messages.categoryFacet[initialParams.category]);
    finalParams.category = `${config.SEARCH_URL_ITEM_CATEGORY}-${translatedCategory.toLowerCase()}`;
    delete initialParams.category;
  }

  if (!isEmpty(initialParams.country)) {
    if (initialParams.country !== 'all'){
      finalParams.country = getCountryURLFromCode(initialParams.country.toLowerCase(), overrideLocale);
    }
    delete initialParams.country;
  }

  if (initialParams.city) {
    if (initialParams.city.length > 0) {
      finalParams.city = `${config.SEARCH_URL_ITEM_CITY}-${initialParams.city.join('+')}`;
    }
    delete initialParams.city;
  }

  if (!isEmpty(initialParams.price)) {
    if (initialParams.price.max && initialParams.price.max !== bt.SLIDER_MAX_VALUE) {
      finalParams.price = `${config.SEARCH_URL_ITEM_PRICE}-${initialParams.price.min || 0},${initialParams.price.max}`;
    } else {
      finalParams.price = `${config.SEARCH_URL_ITEM_PRICE}-${initialParams.price.min || 0}`;
    }
    delete initialParams.price;
  }

  if (!isEmpty(initialParams.powerhp)) {
    finalParams.powerhp = `${config.SEARCH_URL_ITEM_POWER_HP}-${initialParams.powerhp.min || 0}`;
    if (initialParams.powerhp.max && initialParams.powerhp.max !== bt.SLIDER_MAX_VALUE) {
      finalParams.powerhp = `${finalParams.powerhp},${initialParams.powerhp.max}`;
    }
    delete initialParams.powerhp;
  }

  if (!isEmpty(initialParams.region)) {
    if (initialParams.region !== 'all'){
      finalParams.region = `${config.SEARCH_URL_ITEM_REGION}-${initialParams.region.toLowerCase()}`;
    }
    delete initialParams.region;
  }

  if (!isEmpty(initialParams.subdivision)) {
    if (initialParams.subdivision !== 'all') {
      const country = ((urlProps && urlProps.country) ? urlProps.country : get(getConfig(), 'country')).toLowerCase();
      const subdivision = initialParams.subdivision.toLowerCase();
      const subdivisionURLCode = getSubdivisionURLFromCode(country, subdivision, overrideLocale);
      finalParams.subdivision = `${config.SEARCH_URL_ITEM_SUBDIVISION}-${subdivisionURLCode}`;
    }
    delete initialParams.subdivision;
  }

  if (initialParams.page) {
    if (newSearch) {
      delete initialParams.page;
      delete initialParams.pageSize;
    }
    else {
      if (initialParams.page !== '1' && initialParams.page !== 1) {
        finalParams.page = `${config.SEARCH_URL_ITEM_PAGE}-${initialParams.page}`;
      }
      delete initialParams.page;
      delete initialParams.pageSize;
    }
  }

  if (initialParams.forSale) {
    if (initialParams.forSale !== 'all') {
      finalParams.by = `${config.SEARCH_URL_ITEM_BY}-${tr(messages.forSaleFacet[initialParams.forSale])}`;
    }
    delete initialParams.forSale;
  }

  if (initialParams.owner) {
    finalParams.owner = initialParams.owner;
    delete initialParams.owner;
  }

  for (let key in initialParams) {
    if (initialParams[key] !== bt.DEFAULT_REFINE_SEARCH[key] && initialParams[key] &&
        !(typeof (bt.DEFAULT_REFINE_SEARCH[key]) === 'object' && isEmpty(bt.DEFAULT_REFINE_SEARCH[key]))) {
      const translatedKey = has(messages.search, key)
        ? tr(messages.search[key])
        : (key.charAt(0).toLowerCase() + key.slice(1));
      finalParams[key] = `${translatedKey}-${initialParams[key]}`;
    }
  }

  let searchPattern = bt.GENERATE_ENGINES_SEARCH_URL_PATTERN;
  if (finalParams.owner) {
    const dealerStr = tr(messages.dealerPrefix);
    searchPattern = getEnginesDealerGalleryPattern(getConfig(), bt, finalParams.owner, dealerStr);
  }

  if (removeLastSlash) {
    return generatePath(searchPattern, finalParams).slice(0,-1);
  }

  return generatePath(searchPattern, finalParams);

};

export const getEnginesSearchUrlPattern = (url, isBranded) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const {ENGINES_BRANDED_SEARCH_URL_PATTERN, ENGINES_SEARCH_URL_PATTERN, ENGINES_DEALER_GALLERY_URL_PATTERN} = getBoatConstantsFromI18n();
  if (!isBranded) {
    return ENGINES_SEARCH_URL_PATTERN;
  }
  const dealerStr = t(messages.dealerPrefix);
  const clearedPattern = ENGINES_DEALER_GALLERY_URL_PATTERN.replace(/:owner.+/, `${dealerStr}-`);
  const re = new RegExp(clearedPattern);
  return re.test(url) ? ENGINES_DEALER_GALLERY_URL_PATTERN : ENGINES_BRANDED_SEARCH_URL_PATTERN;
};

export const parseSearchEngineAppParams = (url, isBranded = false, config, i18nService) => {
  if (!url) {
    return {};
  }
  const searchPattern = getEnginesSearchUrlPattern(url, isBranded);
  let {params} = matchPath(decodeURI(url), searchPattern);
  return getDefaultParams(params, config, i18nService);
};

export const parseSearchParams = (url, isBranded = false, config, i18nService) => {
  if (!url) {return {};}
  // istambul ignore next
  if (!config) {
    // TODO: remove this call when needed
    config = getConfig();
  }
  const searchPattern = getEnginesSearchUrlPattern(url, isBranded);
  let { params } = matchPath(decodeURI(url), searchPattern);
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const t = getFormatMessageFunction(i18nService);
  const mappedValue = getMappedValueFromI18n(i18nService);
  const parseEngineReplacers = parseEngineAppUrlReplacers(boatConstants, t, mappedValue);
  const parseEngines = parseEnginesAppUrlParams(boatConstants, config, t, mappedValue, i18nService);
  const {
    parseReplaceRegionParams, parseReplaceCountryParams, parseReplaceCityParams,
    parseReplaceLegacyMakeModelParams, parseReplaceMakeModelParams, parseReplaceCategoryParams,
    parseReplaceSortParams, parseReplaceKeywordParams, parseReplaceConditionParams,
    parseReplaceYearParams, parseReplaceFuelParams, parseReplacePriceParams,
    parseReplaceHorsePowerParams, parseReplaceByParams, parseReplaceOwnerParams
  } = parseEngineReplacers;
  const  {parseSubdivisionParams, parseEnginePageParams} = parseEngines;
  return {
    ...parseSubdivisionParams(params.subdivision),
    ...parseReplaceRegionParams(params.region),
    ...parseReplaceCountryParams(params.country),
    ...parseReplaceCityParams(params.city),
    ...parseReplaceLegacyMakeModelParams(params.make, params.model),
    ...parseReplaceMakeModelParams(params.makeModel),
    ...parseReplaceCategoryParams(params.category),
    ...parseEnginePageParams(params.page),
    ...parseReplaceSortParams(params.sort, params.postalCode, 'price:desc'),
    ...parseReplaceKeywordParams(params.keyword),
    ...parseReplaceConditionParams(params.condition),
    ...parseReplaceYearParams(params.year),
    ...parseReplaceFuelParams(params.fuel),
    ...parseReplacePriceParams(params.price),
    ...parseReplaceHorsePowerParams(params.powerhp),
    ...parseReplaceByParams(params.by),
    ...parseReplaceOwnerParams(params.owner)
  };
};

export const getDefaultParams = (params, config, i18nService) => {
  // TODO: remove this call when possible
  if (!config) {
    config = getConfig();
  }
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const t = getFormatMessageFunction(i18nService);
  const mappedValue = getMappedValueFromI18n(i18nService);
  const parseEngines = parseEnginesAppUrlParams(boatConstants, config, t, mappedValue, i18nService);
  const {
    parseSubdivisionParams, parseRegionParams, parseCountryParams, parseCityParams,
    parseLegacyMakeModelParams, parseMakeModelParams, parseCategoryParams,
    parseEnginePageParams, parseEngineSortParams, parseConditionParams, parseYearParams,
    parseFuelParams, parsePriceParams, parseHorsePowerParams, parseModalParams,
    parseByParams, parseOwnerParams, parseKeywordParams
  } = parseEngines;
  let parsed = {
    ...parseSubdivisionParams(params.subdivision),
    ...parseRegionParams(params.region),
    ...parseCountryParams(params.country),
    ...parseCityParams(params.city),
    ...parseLegacyMakeModelParams(params.make, params.model),
    ...parseMakeModelParams(params.makeModel),
    ...parseCategoryParams(params.category),
    ...parseEnginePageParams(params.page),
    ...parseEngineSortParams(params.sort),
    ...parseEngineSortParams(params.sort),
    ...parseConditionParams(params.condition),
    ...parseYearParams(params.year),
    ...parseFuelParams(params.fuel),
    ...parsePriceParams(params.price),
    ...parseHorsePowerParams(params.powerhp),
    ...parseModalParams(params.modal),
    ...parseByParams(params.by),
    ...parseOwnerParams(params.owner),
    ...parseKeywordParams(params.keyword),
  };

  let defaults = {
    ...boatConstants.DEFAULT_REFINE_SEARCH
  };

  defaults = Object.assign(defaults, parsed);
  return defaults;
};

export const reduceURLForRedirect = (props) => {
  let params = get(props, 'match.params', {});
  let lastParam;
  let modified = false;
  const boatsContants = getBoatConstantsFromI18n();
  forEach(params, (value, key) => {
    if (!boatsContants.INDEXABLE_PARAMS.includes(key)) {
      if (params[key]) {
        params[key] = undefined;
        modified = true;
      }
    }
  });
  let reducedUrl = generateSearchPath({}, getDefaultParams(params), true);
  if (!modified) {
    boatsContants.INDEXABLE_PARAMS.forEach(matchParam => {
      if (params[matchParam]) {
        lastParam = matchParam;
      }
    });
    delete params[lastParam];
    reducedUrl = generateSearchPath({}, getDefaultParams(params, lastParam), true);
  }

  return reducedUrl;

};

export const getActiveParams = (params) => {
  const defaults = getDefaultParams({});
  const active = Object.entries(params).filter(([key, value]) => {
    return defaults[key] !== value;
  });

  const allActive = active
    .reduce((object, [key, value]) => ({ ...object, [key]: value }), {});

  return allActive;
};

export const unhyphenateUrlComponents = (string) => {
  string = string.toLowerCase();
  string = string.split('--').join('..');
  string = string.split('-').join(' ');
  string = string.split('$').join('&');
  string = unslugify(string, '..', '-');
  return string;
};

const formatCountry = (facetKey, selectedValues, noFacet, facetMeta, defaultParams) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const newValueSlug = defaultParams[facetKey];
  const href = generateSearchPath({ ...noFacet, [facetKey]: newValueSlug }, defaultParams, true);
  const formattedValue = t(messages.countries[selectedValues]);
  const isFormatted = true;
  return { ...facetMeta, key: facetKey, href, value: formattedValue, newValue: newValueSlug, isFormatted };
};

const formatCity = (facetKey, selectedValues, noFacet, facetMeta, defaultParams) => {
  return selectedValues.map((facetValue) => {
    const newValueSlug = without(selectedValues, facetValue);
    const formattedValue = unhyphenateUrlComponents(facetValue);
    const indexKey = `${facetKey}.${facetValue}`;
    const href = generateSearchPath({ ...noFacet, [facetKey]: newValueSlug }, defaultParams, true);
    return { ...facetMeta, href, key: facetKey, indexKey, value: formattedValue, newValue: newValueSlug };
  });
};

export const getFormattedMakeForFacet = (selectedMakeSlug, selectedMakeModels, makeModel) => {
  const selectedMake = makeModel.find(({ value: makeFormattedName }) => {
    const slugMake = normalizeMake(hyphenateUrlComponents(makeFormattedName));
    return has(selectedMakeModels, slugMake) && slugMake === selectedMakeSlug;
  });
  return get(selectedMake, 'value');
};

// eslint-disable-next-line max-len
const formatModel = (facetKey, selectedValues, noFacet, facetMeta, defaultParams, facetValue, subFacetValue, formattedMake, makeModel) => {
  const newValueSlug = {
    ...omit(selectedValues, facetValue),
    ...toggleSubFacet(facetValue, subFacetValue, selectedValues)
  };
  let formattedValue;
  let isFormatted;

  const makeObj = makeModel.find(e=> hyphenateUrlComponents(e.value) === facetValue)
    || { value: unslugify(facetValue), model: []};
  const modelObj = makeObj.model.find( e=> hyphenateUrlComponents(e.value) ===  subFacetValue )
    || { value: unslugify(subFacetValue)};
  formattedValue = `${formattedMake || makeObj.value} - ${modelObj.value}`;
  isFormatted = !!formattedMake;

  const indexKey = `${facetKey}.${facetValue}.${subFacetValue}`;
  const href = generateSearchPath({ ...noFacet, [facetKey]: newValueSlug }, defaultParams, true);

  return { ...facetMeta, href, key: facetKey, indexKey, value: formattedValue, newValue: newValueSlug, isFormatted };
};

const formatMake = (facetKey, selectedValues, noFacet, facetMeta, defaultParams, facetValue, formattedMake) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const newValueSlug = omit(selectedValues, facetValue);
  const formattedValue = `${t(messages.all)} ${translateMake(formattedMake || unslugify(facetValue))}`;
  const isFormatted = !!formattedMake;
  const indexKey = `${facetKey}.${facetValue}`;
  const href = generateSearchPath({ ...noFacet, [facetKey]: newValueSlug }, defaultParams, true);

  return { ...facetMeta, href, key: facetKey, indexKey, value: formattedValue, newValue: newValueSlug, isFormatted };
};

const formatSubdivision = (facetKey, selectedValues, noFacet, facetMeta, defaultParams) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const newValueSlug = defaultParams[facetKey];
  const href = generateSearchPath({ ...noFacet, [facetKey]: newValueSlug }, defaultParams, true);
  const formattedValue = t(messages.countrySubdivision[selectedValues]);
  const isFormatted = true;
  return { ...facetMeta, key: facetKey, href, value: formattedValue, newValue: newValueSlug, isFormatted };
};

const formatRegion = (facetKey, selectedValues, noFacet, facetMeta, defaultParams) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const newValueSlug = defaultParams[facetKey];
  const href = generateSearchPath({ ...noFacet, [facetKey]: newValueSlug }, defaultParams, true);
  const formattedValue = t(messages.countryRegions[selectedValues]);
  const isFormatted = true;
  return { ...facetMeta, key: facetKey, href, value: formattedValue, newValue: newValueSlug, isFormatted };
};

const formatDefaultFacets = (facetKey, selectedValues, noFacet, facetMeta, defaultParams) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const newValueSlug = defaultParams[facetKey];
  const href = generateSearchPath({ ...noFacet, [facetKey]: newValueSlug }, defaultParams, true);
  let isFormatted = false;
  let { prefix = '', suffix = '' } = facetMeta;
  if (isObject(selectedValues)) {
    if (selectedValues.min && !selectedValues.max) {
      suffix = '+ ' + suffix;
    } else if (!selectedValues.min && selectedValues.max) {
      prefix = `${t(messages.upTo)} ${prefix}`;
    } else if (!isEmpty(suffix)) {
      suffix = ' ' + suffix;
    }
  }

  let formattedValue = isString(selectedValues) && facetKey !== 'keyword' ? unslugify(selectedValues) : selectedValues;
  if (facetKey === 'condition' || facetKey === 'forSale') {
    formattedValue = t(messages[selectedValues]);
    isFormatted = true;
  }
  if (facetKey === 'category') {
    formattedValue = upperFirst(t(messages.categoryFacet[selectedValues]));
    isFormatted = true;
  }
  if (facetKey === 'fuel') {
    const translatedFuelType = upperFirst(t(messages.fuelTypeFacet[selectedValues]));
    formattedValue = selectedValues === 'lpg' ? translatedFuelType.toUpperCase() : upperFirst(translatedFuelType);
    isFormatted = true;
  }
  if (facetKey === 'sort') {
    const [sortBy, sortOrder] = selectedValues.split(':');
    formattedValue = `${t(messages[sortBy])}:${t(messages.sortFacetValues[sortOrder])}`;
    isFormatted = true;
  }
  if (facetKey === 'keyword') {
    isFormatted = true;
  }

  // eslint-disable-next-line max-len
  return { ...facetMeta, href, key: facetKey, value: formattedValue, newValue: newValueSlug, prefix, suffix, isFormatted };
};

export const getFormattedParamsArray = (active, makeModel = []) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const facetMetaMap = {
    price: {
      [getCurrencyFormat() === 'postfix' ? 'suffix' : getCurrencyFormat()]: getCurrencySymbol(),
      format: 'number',
    }
  };
  const defaultParams = getDefaultParams({});
  return flatten(Object.entries(active).map(([facetKey, selectedValues]) => {
    const facetMeta = { ...facetMetaMap[facetKey], formattedKey: t(messages[facetKey]) };
    const noFacet = omit(active, facetKey);
    switch (facetKey) {
    case 'country':
      return formatCountry(facetKey, selectedValues, omit(noFacet, ['city']), facetMeta, defaultParams);
    case 'city':
      return formatCity(facetKey, selectedValues, noFacet, facetMeta, defaultParams);
    case 'makeModel':
      return flatten(Object.entries(selectedValues).map(([facetValue, subFacets]) => {
        const formattedMake = getFormattedMakeForFacet(facetValue, active.makeModel, makeModel);
        if (subFacets && subFacets.length) {
          return subFacets.map(
            (subFacetValue) => formatModel(facetKey, selectedValues, noFacet, facetMeta, defaultParams,
              facetValue, subFacetValue, formattedMake, makeModel)
          );
        }
        return formatMake(facetKey, selectedValues, noFacet, facetMeta, defaultParams, facetValue, formattedMake);
      }));
    case 'subdivision':
      return formatSubdivision(facetKey, selectedValues, omit(noFacet, ['city']), facetMeta, defaultParams);
    case 'region':
      return formatRegion(facetKey, selectedValues, omit(noFacet, ['subdivision']), facetMeta, defaultParams);
    default:
      return formatDefaultFacets(facetKey, selectedValues, noFacet, facetMeta, defaultParams);
    }
  }));
};

export const getSubdivisionURLFromCode = (countryCode, code, overrideLocale) => {
  if (countryCode && code) {
    const locale = overrideLocale ? overrideLocale : getCurrentLocale();
    initializeGlobalSubdivisionsURLs(locale);
    const urls = global.url[locale].subdivisions.filter(subdivision => {
      return subdivision.code === `${countryCode}-${code}`;
    });
    return (urls.length > 0) ? `${urls[0].url}` : code;
  }
  return code;
};


