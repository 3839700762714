import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { generatePath } from 'react-router';
import { normalizeString as normalizeMakeModel } from '@dmm/lib-common/lib/formatting';
import { generateMakeAndModelPathParam } from '../boats';
import { getBoatsConstants } from '../../../constants/boats/index';
import {getOverridableBoats, getOverridableRoutes} from '../../language';
import { syncTranslationElements } from '../../../tppServices/translations/intlManager';

/**
 * Generates a search path that links to the branded OEM SRP
 * Format = /brands/<brand-name>/<filter>/../<filter-n>
 * If no brand or an invalid brand is provided, it defaults to an empty string ""
 */
export const generateBrandedOemSearchPath = (
  changes,
  urlProps,
  newSearch,
  overrideLocale,
  i18Service
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  // locale is overriden only in translating location

  const config = getOverridableRoutes(overrideLocale, i18Service);
  const bt = getOverridableBoats(overrideLocale, i18Service);
  const tr = (message, params) =>
    t(message, params, overrideLocale, i18Service);
  let removeLastSlash = false;
  let initialParams = {
    ...urlProps,
    ...changes
  };

  if (!initialParams?.brand) {
    return '';
  }

  let finalParams = {
    brand: initialParams.brand
  };
  delete initialParams.brand;

  if (initialParams.modal) {
    delete initialParams.modal;
  }

  /* istanbul ignore next */
  if (initialParams.makeModel) {
    if (!isEmpty(initialParams.makeModel)) {
      let seperateMakeModel = generateMakeAndModelPathParam(
        initialParams.makeModel
      );
      if (seperateMakeModel) {
        finalParams.make = `${config.SEARCH_URL_ITEM_MAKE}-${normalizeMakeModel(
          seperateMakeModel.make,
          getBoatsConstants().ALLOWED_CHARACTERS_IN_URL
        )}`;
        if (seperateMakeModel.model) {
          if (initialParams.modelRange) {
            delete initialParams.modelRange;
          }
          /* istanbul ignore next */
          finalParams.model = `${
            config.SEARCH_URL_ITEM_MODEL
          }-${normalizeMakeModel(
            seperateMakeModel.model,
            getBoatsConstants().ALLOWED_CHARACTERS_IN_URL
          )}`;
        }
      } else {
        if (finalParams.brand) {
          const models = initialParams.makeModel[finalParams.brand].map(
            (model) => {
              return `${config.SEARCH_URL_ITEM_MODEL}-${normalizeMakeModel(
                model,
                getBoatsConstants().ALLOWED_CHARACTERS_IN_URL
              )}`;
            }
          );
          finalParams.model = models.join('+');
        }
      }
    }
    delete initialParams.makeModel;
  }

  if (initialParams.sort) {
    if (initialParams.sort.includes('-')) {
      initialParams.sort = initialParams.sort.split('-')[1];
    }
    const [sortBy, sortOrder] = initialParams.sort.split(':');
    const translatedSort = `${tr(messages.search[sortBy])}:${tr(
      messages.sortFacet[sortOrder]
    )}`;
    finalParams.sort = `${config.SEARCH_URL_ITEM_SORT}-${translatedSort}`;
    delete initialParams.sort;
  }

  if (initialParams.keyword) {
    if (
      initialParams.searchKeywordParamFormat &&
      initialParams.searchKeywordParamFormat === 'query-string'
    ) {
      removeLastSlash = true;
      finalParams.keyword = `?${config.SEARCH_URL_ITEM_KEYWORD}=${initialParams.keyword}`;
    } else {
      finalParams.keyword = `${config.SEARCH_URL_ITEM_KEYWORD}-${initialParams.keyword}`;
    }
    delete initialParams.searchKeywordParamFormat;
    delete initialParams.keyword;
  }

  if (!isEmpty(initialParams.length)) {
    if (
      initialParams.length.max &&
      initialParams.length.max !== bt.SLIDER_MAX_VALUE
    ) {
      finalParams.length =
        config.SEARCH_URL_ITEM_LENGTH +
        `-${initialParams.length.min || 0},${initialParams.length.max}`;
    } else {
      finalParams.length = `${config.SEARCH_URL_ITEM_LENGTH}-${
        initialParams.length.min || 0
      }`;
    }
    delete initialParams.length;
  }

  if (initialParams.page) {
    if (newSearch) {
      delete initialParams.page;
      delete initialParams.pageSize;
    } else {
      if (initialParams.page !== '1' && initialParams.page !== 1) {
        finalParams.page = `${config.SEARCH_URL_ITEM_PAGE}-${initialParams.page}`;
      }
      delete initialParams.page;
      delete initialParams.pageSize;
    }
  }

  for (const [key, value] of Object.entries(initialParams)) {
    const valueHasUnknownDefault = value !== bt.DEFAULT_REFINE_SEARCH[key];
    const valueIsDefined = !!value;
    const defaultValueIsObject =
      typeof bt.DEFAULT_REFINE_SEARCH[key] === 'object';
    const defaultValueIsEmpty = isEmpty(bt.DEFAULT_REFINE_SEARCH[key]);

    if (
      valueIsDefined &&
      valueHasUnknownDefault &&
      !(defaultValueIsEmpty && defaultValueIsObject)
    ) {
      const translatedKey = has(messages.search, key)
        ? tr(messages.search[key])
        : key.charAt(0).toLowerCase() + key.slice(1);
      finalParams[key] = `${translatedKey}-${initialParams[key]}`;
    }
  }

  // NOTE: To generate the URL we need to encode the value for each search parameter, but to accept it we don't ¯\_(⊙︿⊙)_/¯
  // NOTE: Maybe someone doesn't understand the previous note, left it here for posterity ¯\_(⊙︿⊙)_/¯
  const url = generatePath(
    bt.GENERATE_BRANDED_OEM_SEARCH_URL_PATTERN,
    finalParams
  );
  return removeLastSlash ? url.slice(0, -1) : url;
};
