export const createDefinedMessages = () => {
  let messages;
  const NOT_LOADED = 'Messages not loaded';
  const noMessages = () => {
    throw new Error(NOT_LOADED);
  };

  const loadDefinedMessages = async (defaultMessages) => {
    const {intlDefinedMessages} = await import('./translationMessages');
    messages = intlDefinedMessages(defaultMessages);
    return messages;
  };

  // We can only call this function after the messages have been loaded
  const getMessages = () => messages || noMessages();

  const startMessages = async (defaultMessages) => {
    if (messages) {
      return true;
    }
    try {
      messages = await loadDefinedMessages(defaultMessages);
      return !!getMessages();
    } catch (e) {
      return false;
    }
  };

  const fetchAndStartMessages = async (defaultMessages) => {
    const startedMessages = await startMessages(defaultMessages);
    if (!startedMessages) {
      throw new Error(NOT_LOADED);
    }
    return startedMessages;
  };

  return {
    loadDefinedMessages,
    getMessages,
    startMessages,
    fetchAndStartMessages
  };
};

const { getMessages, startMessages, fetchAndStartMessages } = createDefinedMessages();

export { getMessages, startMessages, fetchAndStartMessages };
