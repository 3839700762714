import { applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import thunk from 'redux-thunk';
import * as utils from './utils';
import { initialABTestContext } from '../context/ABTestContext';
import {loadConfigService, TPPServiceContainer} from '../tppServices/tppDi';
import {
  pathNameFromHistory, TPP_CONTAINER,
} from '../tppServices/crossEnvHelpers';
import { fetchAndStartMessages } from '../tppServices/translations/messages';
import {createRootReducer} from './reducer';
import {createClientStore, createServerStore} from './initialState';

//const rootReducer = reducer;


// TODO: on config load when config is async we should dispatch
// to review live that services load fine. i.e:
// const addClientListeners = setUpListeners();
const startAndStoreServices = async (history, host, containerName) => {
  // server side we have configService already
  let tpp;
  try {
    tpp = TPPServiceContainer(containerName);
    if (tpp?.started()) {
      return tpp;
    }
    // TODO: on config load when config is async
    // addClientListeners(tpp);
    const configService = await loadConfigService(containerName);
    const pathName = pathNameFromHistory(history);
    const started = await tpp.start(configService, pathName, host);
    if (!started) {
      // eslint-disable-next-line no-console
      console.error('Failed to start TPP');
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw e;
  }
  return tpp;
};

export default async (url = '/', httpClient, containerName = TPP_CONTAINER) => {
  let host = '';
  if (!utils.isServer()) {
    // const {protocol, hostname, port} = window.location;
    // use this in development -> host = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    host = window.location.href;
  }
  // TODO Reenable client-side CRA.
  // Create a history depending on the environment
  const history = utils.isServer()
    ? createMemoryHistory({ initialEntries: [url] })
    : createBrowserHistory({ basename: url });

  const enhancers = [];

  const tpp = await startAndStoreServices(history, host, containerName);
  const langService = tpp.languageService();
  const enDefaults = langService.defaultTranslation();
  await fetchAndStartMessages(enDefaults);
  // Development mode only code
  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development' && !utils.isServer()) {
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const rootReducer = createRootReducer();

  const middleware = [thunk.withExtraArgument(httpClient), routerMiddleware(history)];
  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
  const connectedReducers = connectRouter(history)(rootReducer);
  const abTestContext = utils.isServer() ? initialABTestContext : window.__AB_TEST__;

  let store = utils.isServer() ?
    createServerStore(connectedReducers, composedEnhancers) :
    createClientStore(connectedReducers, composedEnhancers);

  // Development mode only code
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(connectRouter(history)(rootReducer));
      });
    }
  }

  return {
    store,
    history,
    host,
    abTestContext,
    tpp
  };
};


