import React from 'react';
import ReactJWPlayer from 'react-jw-player';
import PropTypes from 'prop-types';
import './styles.css';
import { v4 as uuid } from 'uuid';
import { getConfig } from '../../config/portal';
import get from 'lodash/get';

const displayName = 'JWPlayer';

class JWPlayer extends React.Component {

  state = {
    id: uuid()
  }

  render() {
    const { media } = this.props;
    return (
      <div className="react-jw-player-container">
        <ReactJWPlayer
          playerId={this.state.id}
          playerScript={get(getConfig(), 'scripts.jwPlayer')}
          file={media.videoEmbedUrl}
          image={media.videoVideoThumbnailUri}
        />
      </div>
    );
  }
}

JWPlayer.propTypes = {
  /** Is the media object into the media array of the listing */
  media: PropTypes.object,
};

JWPlayer.displayName = displayName;

export default JWPlayer;
