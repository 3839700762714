import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { entriesToString } from '../../utils';
import omit from 'lodash/omit';
import { getNoIndex } from '../../pages/SearchResults/utils/meta';
import { getNoIndex as getNoIndexEngines } from '../../pages/SearchResults/Engines/utils/meta';
import {getBoatConstantsFromI18n} from '../../tppServices/translations/constants';
import {getFormatMessageFunction} from '../../tppServices/tppDi';

const IGNORE_PROPS = ['nofollow', 'type', 'pageType'];

export default class Link extends PureComponent {
  render() {
    const boatConstants = getBoatConstantsFromI18n();
    const t = getFormatMessageFunction();
    const { to, href, type, pageType } = this.props;
    const path = to || href;
    if (type === 'facet' && pageType === boatConstants.PAGE_TYPES.brandedOemSearch) {
      return this.renderLink({ nofollow: true });
    }

    switch (boatConstants.getPageTypeFromPath(path, t)) {
      case boatConstants.PAGE_TYPES.brandedSearch:
        return this.renderLink({
          nofollow: true
        });
      case boatConstants.PAGE_TYPES.enginesDealerGallery:
        return this.renderLink({
          nofollow: getNoIndexEngines(path, true)
        });
      case boatConstants.PAGE_TYPES.boats:
        return this.renderLink({
          nofollow: getNoIndex(path)
        });
      case boatConstants.PAGE_TYPES.engines:
        return this.renderLink({
          nofollow: getNoIndexEngines(path)
        });
      case boatConstants.PAGE_TYPES.dealerGallery:
        return this.renderLink({
          nofollow: true
        });
      default:
        return this.renderLink(this.props);
    }
  }

  renderLink = ({ nofollow }) => {
    const { to, title } = this.props;
    if (to) {
      return (
        <RouterLink
          rel={entriesToString({ nofollow: nofollow })}
          {...omit(this.props, IGNORE_PROPS)}
        >
          {this.props.children}
        </RouterLink>
      );
    }
    return (
      <a
        title={title}
        rel={entriesToString({ nofollow: nofollow })}
        {...omit(this.props, IGNORE_PROPS)}
      >
        {this.props.children}
      </a>
    );
  };
}

Link.propTypes = {
  children: PropTypes.node,
  nofollow: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  pageType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['regular', 'facet']),
};
