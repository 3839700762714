import { PureComponent } from 'react';
import PropTypes from 'prop-types';

const VALID_STATUS_CODES = [500, 400, 404, 410];

export default class Status extends PureComponent {
  render() {
    const { staticContext, message, statusCode } = this.props;
    if (staticContext) {
      const returnStatusCode = VALID_STATUS_CODES.includes(statusCode) ? statusCode :
        VALID_STATUS_CODES.find((validStatusCode) => message.includes(validStatusCode));
      if (returnStatusCode) {
        staticContext.statusCode = returnStatusCode;
      }
    }
    return null;
  }
}

Status.defaultProps = {
  message: '',
  staticContext: null,
  statusCode: null,
};

Status.propTypes = {
  /** Error message */
  message: PropTypes.string,
  /** StaticRouter context property */
  staticContext: PropTypes.shape({
    statusCode: PropTypes.number,
  }),
  statusCode: PropTypes.number,
};
