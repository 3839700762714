import React, {
  useContext,
  useEffect,
  useState,
  createRef,
  useRef,
  memo
} from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import includes from 'lodash/includes';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';

import { PortalConfigContext } from '../../config/portal';
import { getAssetUrl } from '../../utils/commonHelper';
import { APP_BANNER_COOKIE } from '../../constants/cookies';
import { registerDownloadApp } from '../../store/actions/dataLayer';
import { getPageParams } from '../../utils/dataLayerHelper';
import classnames from 'classnames';
import './styles.css';
import { getPortalName } from '../../utils/language';
import { setCookie } from '../../utils/cookies';
import { getMessages } from '../../tppServices/translations/messages';

const AppBanner = ({
  intl,
  cookies,
  pageType,
  registerDownloadApp,
  getAppBannerHeight
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [appStoreLink, setAppStoreLink] = useState('');
  const [bannerHeight, setBannerHeight] = useState(0);
  const context = useContext(PortalConfigContext);

  const bannerRef = createRef();
  const prevIsVisible = useRef(isVisible);

  const handleCloseButton = () => {
    const inOneDay = new Date();
    inOneDay.setDate(inOneDay.getDate() + 1);
    const data = {
      value: 1,
      expires: inOneDay,
      path: '/'
    };
    setCookie(cookies, APP_BANNER_COOKIE, 1, data);
    setIsVisible(false);
    setBannerHeight(0);
    getAppBannerHeight(bannerHeight);
  };

  const handleDownloadButton = () => {
    const portal = getPortalName(context);
    const params = getPageParams(pageType, null, context.country, context.fullName);
    const detailedPageName = params.detailedPageName;
    registerDownloadApp(detailedPageName, portal);

    setTimeout(() => {
      window.location.assign(appStoreLink);
    }, 400);
  };

  useEffect(() => {
    const pagesAllowedToShowBanner = [
      'Home',
      'SearchResults',
      'BoatDetails',
      'BoatDetailsOEM'
    ];
    const isPageAllowedToShowBanner = includes(
      pagesAllowedToShowBanner,
      pageType
    );

    if (isPageAllowedToShowBanner) {
      const bannerContext = context.banner;
      if (
        bannerContext &&
        bannerContext.layout !== 'none' &&
        cookies?.cookies &&
        !cookies?.cookies[APP_BANNER_COOKIE]
      ) {
        const iphone = window.navigator.userAgent.match(/iPhone/g);
        const android = window.navigator.userAgent.match(/Android/g);
        if (iphone || android) {
          setIsVisible(true);
          setAppStoreLink(
            iphone
              ? bannerContext.appStoreLink.iphone
              : bannerContext.appStoreLink.android
          );
          getAppBannerHeight(bannerHeight);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isVisible !== prevIsVisible.current) {
      setBannerHeight(isVisible ? bannerRef.current.clientHeight || 52 : 0);
      getAppBannerHeight(bannerHeight);
    }
    prevIsVisible.current = isVisible;
  });

  const bannerContext = useContext(PortalConfigContext).banner;
  const portal = getPortalName(context);
  const bannerLayout = bannerContext?.layout;

  if (!bannerContext || bannerLayout === 'none') {
    return null;
  }

  const { formatMessage: t } = intl;
  const messages = getMessages();
  return (
    <div
      ref={bannerRef}
      className={classnames(`app-banner-${bannerLayout}`, 'js-app-banner', {
        'is-visible': isVisible
      })}
    >
      {bannerLayout === 'top' && (
        <div
          onClick={handleCloseButton}
          className="app-banner__close js-app-banner-close"
        >
          ✕
        </div>
      )}
      <img
        className={`app-banner__logo-${bannerLayout}`}
        alt={t(messages.banner.download.title, { portal })}
        src={getAssetUrl(bannerContext.logo)}
      />
      <div className={`app-banner__text-${bannerLayout}`}>
        <span className="app-banner__title">
          {t(messages.banner.download.title, { portal })}
        </span>
        <span className={`app-banner__sub-title-${bannerLayout}`}>
          {t(messages.banner.download.description)}
        </span>
      </div>
      <span
        className={classnames(
          `app-banner__button-${bannerLayout}`,
          'js-app-download-button'
        )}
        onClick={handleDownloadButton}
      >
        {t(messages.banner.download.open)}
      </span>
      {bannerLayout === 'overlay' && (
        <span
          className="app-banner__close js-app-banner-close"
          onClick={handleCloseButton}
        >
          {t(messages.banner.download.close)}
        </span>
      )}
    </div>
  );
};

AppBanner.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  cookies: PropTypes.instanceOf(Cookies),
  pageType: PropTypes.string,
  registerDownloadApp: PropTypes.func,
  getAppBannerHeight: PropTypes.func
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      registerDownloadApp
    },
    dispatch
  )
)(injectIntl(memo(AppBanner)));
