import fs from 'fs';
import { isServer } from '../store/utils';

export const UI_THEME = 'ui-theme';

const startPlatformStyles = () => {
  let platformStyles = '';
  const getStyles = () => platformStyles;
  const setStyles = (styles) => {
    platformStyles = styles;
  };
  return {
    getStyles,
    setStyles,
  };
};

const platformStyles = startPlatformStyles();

const serverPlatformStyles = (portal) => {
  const uiThemePath = `./node_modules/@dmm/lib-react-ui-components/dist/${portal}.css`;
  try {
    const uiTheme = fs.readFileSync(uiThemePath, 'utf-8');
    // we cache styles to load only once
    platformStyles.setStyles(uiTheme);
    return uiTheme;
  } catch (e) {
    throw Error(`Error loading UI theme for portal ${portal}`, e);
  }
};

const clientPlatformStyles = () => {
  const styleTag = document.getElementById(UI_THEME);
  // we use this for development, injected in config-overrides.js
  const uiLocalStyles = process.env.UI_THEME_STYLES;
  const uiTheme = styleTag?.innerHTML || uiLocalStyles || '';
  // we cache styles to load only once
  platformStyles.setStyles(uiTheme);
  return uiTheme;
};

const loadUiThemeStyles = (portal) => isServer() ? serverPlatformStyles(portal) : clientPlatformStyles();

export const getUiLibThemeStyles = (portal) => platformStyles.getStyles() || loadUiThemeStyles(portal);
export const setUiLibThemeStyles = (styles) => platformStyles.setStyles(styles);

