import { matchPath } from 'react-router';
import get from 'lodash/get';
import last from 'lodash/last';
import { hyphenateUrlComponents } from './engines';
// import { getCurrentRoutes as rts } from '../language';
import { getConfig } from '../../config/portal';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';

export const parseEngineDetailParams = (url, i18nService) => {
  const routeConstants = getRouteConstantsFromI18n(i18nService);
  let { params } = matchPath(url, routeConstants.ENGINE_DETAILS_URL_PATTERN);
  return {
    id: last(params.id.split('-'))
  };
};

export const getEngineUrl = (listing, fullUrl, i18nService) => {
  const routeConstants = getRouteConstantsFromI18n(i18nService);
  let url = routeConstants.ENGINE_DETAILS_ROOT;
  if (fullUrl) {
    url = get(getConfig(), 'host') + url;
  }
  if (listing.year) {
    url += listing.year + '-';
  }
  if (listing.make) {
    url += hyphenateUrlComponents(listing.make) + '-';
  }
  if (listing.model) {
    url += hyphenateUrlComponents(listing.model) + '-';
  }
  url += listing.id + '/';

  return url;
};
