import React, { PureComponent } from 'react';
import './styles.css';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getConfig } from '../../config/portal';
import { OptionSet, Option } from '../OptionSet';

import { addEvent } from '../../store/actions/dataLayer';
import { getBoatsConstants } from '../../constants/boats';
import { PRODUCT_CATEGORY, TRACKING_EVENTS } from '../../constants/dataLayer';
import { generateSearchPath as generateSearchBoatsPath } from '../../utils/urlHelpers/boats';
import { generateSearchPath as generateSearchEnginesPath } from '../../utils/urlHelpers/engines';
import { getBreakpoint, BREAKPOINTS } from '../../utils/commonHelper';
import { getMessages } from '../../tppServices/translations/messages';

class KeywordSearch extends PureComponent {
  inputRef = React.createRef();

  state = {
    inputValue: '',
    searchType: [],
    breakpoint: 'desktop'
  };

  resizeHandler = () => {
    this.setState((prev) => ({
      ...prev,
      breakpoint: getBreakpoint()
    }));
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  supportsFeature = feature => {
    return getConfig().supports[feature];
  }

  validateKeyword = word => {
    const keywordPattern = getBoatsConstants().KEYWORD_SEARCH_PATTERN;
    const validationRegExp = RegExp(keywordPattern);
    return !word.length || validationRegExp.test(word);
  }

  handleInputFocus = () => {
    this.inputRef.current.focus();
  }

  handleInputChange = e => {
    const searchKeyword = e.target.value;
    this.validateKeyword(searchKeyword) && this.setState({ inputValue: searchKeyword });
  }

  getParams = () => ({
    keyword: this.state.inputValue,
    searchType: this.state.searchType
  });

  handleSubmit = (history) => async (e) => {
    e.preventDefault();

    if (this.state.inputValue.trim().length) {
      let engines, params, searchType, supportsEngines;
      setTimeout(() => {
        params = this.getParams();
        engines = getBoatsConstants().SEARCH_TYPES.engines;
        searchType = params.searchType[0] && params.searchType[0].value;
        supportsEngines = this.supportsFeature(engines);

        this.props.addEvent(TRACKING_EVENTS.KEYWORD_SEARCH, {
          keyword: `${params.keyword}`.trim().toLowerCase(),
          'product_category': searchType === engines && supportsEngines ? PRODUCT_CATEGORY.ENGINE : PRODUCT_CATEGORY.BOAT
        });
      }, 0);
      // TODO: move this component to functional and remove this dangerous requestAnimationFrame + setTimeout.
      requestAnimationFrame(() => {
        setTimeout(() => {
          const keywordSearchOptions = get(this.props, 'keywordSearchOptions.paramFormat', '');
          const requiresReload = get(this.props, 'keywordSearchOptions.requiresReload', false);
          params.searchKeywordParamFormat = keywordSearchOptions;
          // for some weird reason, unit test randomly fail with coverage here
          // executing the test on isolation it covers the line
          // istanbul ignore next
          let path = searchType === engines && supportsEngines ?
            generateSearchEnginesPath(params) :
            generateSearchBoatsPath(params);
          // for some weird reason, unit test randomly fail with coverage here
          // istanbul ignore next
          if (keywordSearchOptions === 'query-string') {
            path = path.replace('%3F', '?');
          }
          // istanbul ignore next
          if (requiresReload) {
            window.location.assign(path);
          } else {
            history.push(path);
            this.setState({ inputValue: '' });
          }
        }, 1);
      });
    }
  }

  handleSelectChange = value => {
    this.setState({ searchType: value });
    this.handleInputFocus();
  }

  keywordButtonClick = (history) => {
    if (
      this.supportsFeature('enableTopNavigationV2') &&
      this.state.breakpoint === BREAKPOINTS.desktop
    ) {
      return this.handleSubmit(history);
    }

    return this.handleInputFocus;
  }

  render() {
    const { intl: { formatMessage: t } } = this.props;
    const messages = getMessages();
    const searchTypes = [{ value: getBoatsConstants().SEARCH_TYPES.boats, label: t(messages.searchTypeBoats) }];
    if (this.supportsFeature(getBoatsConstants().SEARCH_TYPES.engines)) {
      searchTypes.push({ value: getBoatsConstants().SEARCH_TYPES.engines, label: t(messages.searchTypeEngines) });
    }

    return (
      <Route render={({ history }) => (
        <div className="keyword-search">
          <button
            className="keyword-button"
            onClick={this.keywordButtonClick(history)}
            aria-label="Search"
          />
          <form onSubmit={this.handleSubmit(history)}>
            <input
              className="keyword-input"
              ref={this.inputRef}
              placeholder={t(messages.header.keywordSearch)}
              value={this.state.inputValue}
              onChange={this.handleInputChange}
              aria-label="Keyword Search"
              type="search"
              enterKeyHint="search"
            />
            {
              this.supportsFeature('enableTopNavigationV2') && (
                <button
                  aria-label="Reset"
                  type="reset"
                  className="keyword-reset"
                  onClick={() => this.setState({ inputValue: '' })}
                />
              )
            }
          </form>
          {this.supportsFeature(getBoatsConstants().SEARCH_TYPES.engines) ?
            <Dropdown
              label={t(messages.header.keywordSearch)}
              setSize={true}
              multiple={false}
              setValue={this.handleSelectChange}
              options={searchTypes}
              optionKey="label"
              value={this.state.searchType.length ? this.state.searchType : [searchTypes[0]]}
              render={(options, inheritProps) => (
                <OptionSet {...inheritProps}>
                  {options.map((option, idx) => {
                    return (
                      <Option key={'searchType-' + idx} option={option} {...inheritProps}>
                        {option.label}
                      </Option>
                    );
                  })}
                </OptionSet>
              )}
            /> : null}
        </div>
      )} />
    );
  }
}

KeywordSearch.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  addEvent: PropTypes.func.isRequired
};

export default connect(null, (dispatch) =>
  bindActionCreators({ addEvent }, dispatch)
)(injectIntl(KeywordSearch));
