import React, { useCallback } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.css';

const ConfirmationModal = (props) => {

  const hideModalTimeout = useCallback(() => {
    const { close } = props;
    setTimeout(close, 3000);
  }, [props.close]);

  const { show, close, message } = props;

  return (
    <Modal
      isOpen={show}
      className="confirmation-modal"
      overlayClassName="confirmation-modal-overlay"
      contentLabel="Confirmation modal"
      onAfterOpen={hideModalTimeout}
      ariaHideApp={false}
    >
      <div className={classNames('check', 'check-icon')}/>
      <div id="confirmation-modal-container" className="container">
        <div className="message">
          {message}
        </div>
        <button id="confirmation-modal-close" className={classNames('close-button', 'close-icon')} onClick={close}/>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  close: PropTypes.func,
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default ConfirmationModal;
