import { parse, stringify } from 'qs';

import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';

import { getPartySearchValidParams } from '../partiesHelper';
import { hyphenateUrlComponents } from './boats';
import { hyphenateUrlComponents as enginesHyphenateUrlComponents } from './engines';
// import { getCurrentRoutes as rts } from '../language';
import { getConfig } from '../../config/portal';
import { asString } from '../index';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';
import { syncTranslationElements } from '../../tppServices/translations/intlManager';

const salesRepUrl = (salesRep) => {
  let repUrl = '';
  const { formatMessage: t, messages } = syncTranslationElements();

  if (salesRep && salesRep.id && salesRep.normalizedName) {
    repUrl = `${t(messages.search.salesRep)}-${salesRep.normalizedName}-${
      salesRep.id
    }/`;
  }
  return repUrl;
};

const oemMakeUrl = (make) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const routeConstants = getRouteConstantsFromI18n();
  const conditionUrl = `${t(messages.search.condition)}-${t(
    messages.conditionFacet.new
  )}`;
  const makeUrl = `${t(messages.search.make)}-${make
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase()}`;
  return `${routeConstants.SEARCH_URL_ROOT}${[conditionUrl, makeUrl].join('/')}/`;
};

const brokerUrl = (party, normalizedName, salesRep) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const routeConstants = getRouteConstantsFromI18n();
  let urlParts = [t(messages.brandedSearchBroker), normalizedName, party?.id];
  return `${routeConstants.BRANDED_SEARCH_URL_ROOT}${urlParts.join('-')}/${salesRepUrl(
    salesRep
  )}`;
};

const dealerUrl = (
  party,
  normalizedName,
  rootPath,
  brandedRootPath,
  salesRep
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  // to allow redirections from previous dealer page with ids
  if (!normalizedName) {
    return `${brandedRootPath}${party?.id}/`;
  }
  let urlParts = [t(messages.dealerPrefix), normalizedName, party.id];
  return `${rootPath}${urlParts.join('-')}/${salesRepUrl(salesRep)}`;
};

// TPP-25: In order to fix the special characters issue in the URL, the "hyphenateUrlComponents" function is used,
// the only issue is that it duplicates the hyphens found in the string, so we need to get rid of them before.
export const getPartyBrandedSRP = (
  party,
  isOemModel,
  make,
  salesRep = null
) => {
  const normalizedName = hyphenateUrlComponents(
    asString(party?.normalizedName).replace(/-/g, ' ')
  );
  const oemMake = isOemModel && make;
  const broker = get(getConfig(), 'supports.useBrokerUrlPrefix', false);
  if (oemMake) {
    return oemMakeUrl(make);
  }
  // Currently, only YW
  if (broker) {
    return brokerUrl(party, normalizedName, salesRep);
  }
  const routeConstants = getRouteConstantsFromI18n();
  const rootPath = routeConstants.DEALER_SEARCH_URL_ROOT;
  const brandedRootPath = routeConstants.BRANDED_SEARCH_URL_ROOT;
  return dealerUrl(party, normalizedName, rootPath, brandedRootPath, salesRep);
};

export const getPartyEnginesBrandedSRP = (party) => {
  const normalizedName = enginesHyphenateUrlComponents(
    asString(party?.normalizedName).replace(/-/g, ' ')
  );
  const routeConstants = getRouteConstantsFromI18n();
  const rootPath = routeConstants.ENGINES_DEALER_SEARCH_URL_ROOT;
  const brandedRootPath = routeConstants.ENGINES_BRANDED_SEARCH_URL_ROOT;
  return dealerUrl(party, normalizedName, rootPath, brandedRootPath);
};

export const getPartySearchParams = ({ location }) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const validQueryParams = getPartySearchValidParams().filter(
    ({ query }) => query
  );
  const rawParams = parse(get(location, 'search', '').slice(1));
  const filteredParams = pick(
    rawParams,
    validQueryParams.map(({ translateParamKey }) =>
      t(get(messages, translateParamKey))
    )
  );

  const mappedParams = reduce(
    Object.keys(filteredParams),
    (acc, key) => {
      const { name, type, translateValueKey } = validQueryParams.find(
        ({ translateParamKey }) => t(get(messages, translateParamKey)) === key
      );

      let value;
      if (translateValueKey) {
        const paramsTranslations = get(messages, translateValueKey, {});
        // This only works if the message key matches with the param value that we need to send to the API
        value = get(
          Object.entries(paramsTranslations).find(
            ([, item]) => t(item) === filteredParams[key]
          ),
          '[0]'
        );
      } else {
        value = filteredParams[key];
      }

      return { ...acc, [name]: type === 'number' ? Number(value) : value };
    },
    {}
  );

  return pickBy(mappedParams, (value) => value || !isNaN(value));
};

export const getPartySearchUrl = (params = {}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const validQueryParams = getPartySearchValidParams().filter(
    ({ query }) => query
  );
  const filteredQueryParams = omitBy(
    // omits params matching default value specified in config
    pick(
      params,
      validQueryParams.map(({ name }) => name)
    ), // picks only the values which are valid query string
    (value, key) =>
      validQueryParams.find(({ name }) => name === key).default === value
  );
  const mappedParams = reduce(
    filteredQueryParams,
    (acc, value, key) => {
      const param = validQueryParams.find(({ name }) => name === key);
      const translatedName = t(get(messages, param.translateParamKey));
      const translatedValue = param.translateValueKey
        ? t(get(messages, `${param.translateValueKey}.${value}`))
        : value;
      return { ...acc, [translatedName]: translatedValue };
    },
    {}
  );

  const uri = stringify(mappedParams);
  const suffix = `${uri ? '?' : ''}${uri}`;
  const routeConstants = getRouteConstantsFromI18n();
  return `${routeConstants.PARTY_SEARCH_URL_ROOT}${suffix}`;
};
