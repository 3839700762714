import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from '../SEO/Link';
import { getBreadCrumbSchema } from '../../utils/metaContentHelper';
import Schema from '../Schema';
import './styles.css';

const BreadCrumb = (props) => {
  let { items } = props;

  return (
    <>
      <Schema data={getBreadCrumbSchema(items)} />
      <ul className="breadcrumb">
        {items.map(({ link, nofollow, title }, index) => {
          let last = index + 1 === items.length;
          return link ? (
            <li key={index}>
              <Link href={link} nofollow={nofollow} title={title}>
                {title}
              </Link>
              {!last && <span className="divider">&frasl;</span>}
              {!last && <span className="divider-mobile">/</span>}
            </li>
          ) : (
            <li key={index} className="active">
              {title}
              {!last && <span className="divider">&frasl;</span>}
              {!last && <span className="divider-mobile">/</span>}
            </li>
          );
        })}
      </ul>
    </>
  );
};

BreadCrumb.defaultProps = {
  items: []
};

BreadCrumb.propTypes = {
  /** BreadCrumb items */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      nofollow: PropTypes.bool,
      title: PropTypes.string
    })
  )
};
export default memo(BreadCrumb);
