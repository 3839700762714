export const nil = () => {};

export const runNTimes = (n = -1, runAction = nil) => {
  let total = 0;
  return () => {
    if (total < n) {
      runAction();
      total += 1;
      return true;
    }
    return false;
  };
};

export const runOnce = (runAction = nil) => runNTimes(1, runAction);
