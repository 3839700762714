import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const OptionSet = ({ children, labelledBy, open, filter }) => {
  return (
    <div
      aria-labelledby={labelledBy}
      className={classnames('option-set', { 'option-set-open': open })}
    >
      {filter}
      <div className="option-set-scroll-area">{children}</div>
    </div>
  );
};

OptionSet.propTypes = {
  children: PropTypes.node,
  /** Filter input */
  filter: PropTypes.node,
  labelledBy: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

const Option = (props) => {
  const selSet =
    props.selected &&
    props.selected.find((element) => element.value === props.option.value);
  const [_selected, setSelected] = useState(selSet);

  const onInteraction = (e) => {
    e.preventDefault();
    if (props.multiple) {
      props.onChange(props.option, !_selected);
    } else {
      props.onChange(props.option, true);
      setSelected(true);
    }

    if (props.onClick) {
      props.onClick(props.option.key);
    }
  };

  const {
    children,
    multiple,
    option: { isTitle }
  } = props;
  return (
    <button
      id="option_button"
      className={classnames('option-item-button', { title: isTitle })}
      onClick={
        !isTitle
          ? onInteraction
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
            }
      }
    >
      {multiple && !isTitle && (
        <input type="checkbox" checked={_selected} readOnly></input>
      )}
      {children}
    </button>
  );
};

Option.defaultProps = {
  multiple: false,
  filter: null
};

Option.propTypes = {
  children: PropTypes.any,
  /** Controlled by parent */
  multiple: PropTypes.bool,
  /** Controlled by parent */
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  /** Will return value and if its selected or unselected for when multiple active else is always true */
  onChange: PropTypes.func.isRequired,
  /** Will return value and if its selected or unselected for when multiple active else is always true */
  onClick: PropTypes.func,
  option: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    isTitle: PropTypes.bool
  })
};

export { OptionSet, Option };
