// Used letters to minimize size, probably not the best approach...
// Preferably, use alphabetical order and letter position in the word.
export const FOOTER_SELECTOR = 'f';
export const IMG_SELECTOR = 'i';
export const LISTING_SELECTOR = 'l';
export const PRICE_DROP_SELECTOR = 'pd';
export const TOGGLE_SELECTOR = 'tg';
export const TOOLBAR_SELECTOR = 'to';

const parseListingImage = (element) => {
  if (!element) {return;}
  const imgs = element.getElementsByTagName('img');
  if (!imgs?.length) {return;}
  const domImg = imgs?.length === 2 ? {imgLogo: imgs[0], img: imgs[1]} : {img: imgs[0]};
  const img = {
    image: domImg.img?.src,
    imageAlt: domImg.img?.alt,
    imageLogo: domImg.imgLogo?.src,
    imageLogoAlt: domImg.imgLogo?.alt
  };
  return img;
};

const parseListingToolbar = (element) => {
  if (!element) {return;}
  const tags = [];
  const items = element?.children;
  if (!items?.length) {return;}
  Array.from(items).forEach((tag) => {
    if (tag.textContent) {
      tags.push({text: tag.textContent});
    }
  });
  return {tags};
};

const parseListingToggle = (element) => {
  if (!element) {return;}
  const button = element.querySelector('button');
  const state = button?.getAttribute('aria-label');
  return {state};
};

const parseBlockPrice = (listing) => {
  const title = listing.querySelector(`[data-e2e='listingName']`)?.textContent;
  const price = listing.querySelector(`[data-e2e='listingPrice']`)?.textContent;
  const priceDrop = listing.querySelector(`[data-name='${PRICE_DROP_SELECTOR}']`)?.textContent;

  return {title, price, priceDrop};
};

const parseSeller = (listing) => {
  const seller = listing.querySelector(`[data-e2e='listingSellerContent']`)?.textContent;
  return {seller};
};

const parseFooter = (listing) => {
  const footer = listing.querySelector(`[data-name='${FOOTER_SELECTOR}']`);
  const contactBtn = footer?.querySelector(`[data-e2e='listingContactButton']`);
  const img = footer?.querySelector('img');
  const imgSrc = img?.src;
  const imgAlt = img?.alt;
  const txtBtn = contactBtn?.textContent;
  return {imgSrc, imgAlt, txtBtn};
};

export const getElementAttrs = (element) => {
  const attributes = {};
  for (const attr of Array.from(element.attributes)) {
    attributes[attr.name] = attr.value;
  }
  return attributes;
};

export const cleanEmpty = (obj)  => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

export const validString = (val) => {
  return val ?? '';
};

export const selectListingByListingName = (element, listingName) => {
  return element?.getAttribute('data-name') === listingName ? element : element?.querySelector(`[data-name='${listingName}']`);
};

//
export const listingStandardParser = (element) => {
  const listing = selectListingByListingName(element, LISTING_SELECTOR);
  if (!listing) {return null;}
  const attributes = getElementAttrs(listing);
  const imgElement = listing.querySelector(`[data-name='${IMG_SELECTOR}']`);
  const toolbar = listing.querySelector(`[data-name='${TOOLBAR_SELECTOR}']`);
  const toogle = listing.querySelector(`[data-name='${TOGGLE_SELECTOR}']`);
  const listingImgs = parseListingImage(imgElement);
  const listingToolbar = parseListingToolbar(toolbar);
  const like = parseListingToggle(toogle);
  const priceBlock = parseBlockPrice(listing);
  const seller = parseSeller(listing);
  const footer = parseFooter(listing);
  return {listingImgs, listingToolbar, like, priceBlock, seller, footer, attributes};
};

export const readListingFromDom = (parentElement) => {
  const parsed = listingStandardParser(parentElement);
  if (!parsed) {return null;}
  const priceBlock = parsed?.priceBlock;
  const listingImages = parsed?.listingImgs;
  const listingToolbar = parsed?.listingToolbar;
  const footer = parsed?.footer;
  const formattedParsedListing = {
    tags: listingToolbar?.tags,
    name: validString(priceBlock?.title),
    image: validString(listingImages?.image),
    imageAlt: validString(listingImages?.imageAlt),
    imageLogo: validString(listingImages?.imageLogo),
    imageLogoAlt: validString(listingImages?.imageLogoAlt),
    price: validString(priceBlock?.price),
    prevPrice: validString(priceBlock?.priceDrop),
    //monthlyPriceText: priceBlock?.monthlyPriceText,
    seller: validString(parsed?.seller?.seller),
    logo: validString(footer?.imgSrc),
    logoAlt: validString(footer?.imgAlt),
    contact: footer?.txtBtn ? {text: footer?.txtBtn} : undefined
  };
  return cleanEmpty(formattedParsedListing);
};
