import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import {
  capitalizeString,
  getFacetTranslation,
  translateMake,
  unhyphenateUrlComponents
} from '../../../utils/commonHelper';
import {
  getActiveParams,
  getDefaultParams,
  generateSearchPath,
  hyphenateUrlComponents, parseSearchBoatsAppParams
} from '../../../utils/urlHelpers/boats';
import { getPartyBrandedSRP } from '../../../utils/urlHelpers/party';
import { capitalizeEachWord } from '@dmm/lib-common/lib/formatting';

import {
  getCurrentLocale,
  getPortalSeparator
} from '../../../utils/language';
import { isSingleFaceted } from '../../../utils/multiFacetHelper';
import { urlFromLanguage } from '../../../utils/seo';
import { getPortalDefaultCountry } from '../../../utils/locationHelper';
import { getConfig } from '../../../config/portal';
import TextTransform from './TextTransform';
import {getFormatMessageFunction} from '../../../tppServices/tppDi';
import {getBoatConstantsFromI18n} from '../../../tppServices/translations/constants';
import { getMessages } from '../../../tppServices/translations/messages';
import { syncTranslationElements } from '../../../tppServices/translations/intlManager';

const boatStopWords = [
  'boat',
  'boats',
  'barco',
  'barcos',
  'bateau',
  'bateaux',
  'barche',
  'barca',
  'boot',
  'boten',
  'boote'
];

const yachtStopWords = [
  'yacht',
  'yachts',
  'yate',
  'yates',
  'jacht',
  'jachten',
  'yachten'
];

const noIndexFacets = ['keyword'];

const noFollowMultifacetedFacets = ['hullMaterial', 'fuelType'];

const noFollowExcludedFacets = ['modal', 'modelRange'];

const noIndexNoFollowValues = [
  'power-other',
  'power-unspec',
  'sail-other',
  'sail-unspec',
  'unpowered-other',
  'unpowered-unspec',
  'other',
  'gasoline'
];

export const getMetaTitle = ({
  params,
  maxPages,
  portal,
  isBranded = false,
  partyDetails = {},
  boatClasses = [],
  forceDynamic = false // temporal flag to only be used in 3 column context
}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let paginationTranslation, titleTranslation;
  const separator = getPortalSeparator();
  const isDynamicSeo =
    forceDynamic ||
    get(getConfig(), 'supports.dynamicSeoTitleDescription', false);
  if (isBranded) {
    const translationParameters = {
      dealerName: partyDetails.name,
      dealerCity: get(partyDetails, 'address.city', ''),
      portal
    };
    titleTranslation = `${t(messages.brandedSearch.SEO.metaElements.title, {
      ...translationParameters
    })}`;
    paginationTranslation = getPaginationTranslation(params, maxPages, 'title');
    return paginationTranslation
      ? `${paginationTranslation} ${separator} ${titleTranslation}`
      : `${titleTranslation}`;
  }
  titleTranslation = `${
    !isDynamicSeo
      ? getSEOElementTranslation(params, portal, 'title')
      : getDynamicSEOElementTranslation({
          params,
          portal,
          element: 'title',
          isClassMultiType: checkClassIsMultiType({ boatClasses, params })
        })
  } ${separator} ${portal}`;
  paginationTranslation = getPaginationTranslation(params, maxPages, 'title');

  return paginationTranslation
    ? `${paginationTranslation} ${separator} ${titleTranslation}`
    : `${titleTranslation}`;
};

export const getMetaDescription = ({
  params,
  maxPages,
  portal,
  isBranded = false,
  partyDetails = {},
  countListings = 0,
  similarBoatsForOEMs = false,
  boatClasses = [],
  forceDynamic = false // temporal flag to only be used in 3 column context
}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const isDynamicSeo =
    forceDynamic ||
    get(getConfig(), 'supports.dynamicSeoTitleDescription', false);
  let descriptionTranslation;
  let paginationTranslation = getPaginationTranslation(
    params,
    maxPages,
    'description'
  );
  const numListings =
    countListings > 10 && !similarBoatsForOEMs ? countListings : '';

  if (isBranded) {
    const translationParameters = {
      dealerName: partyDetails.name,
      dealerCity: get(partyDetails, 'address.city', ''),
      portal
    };
    descriptionTranslation = t(
      messages.brandedSearch.SEO.metaElements.description,
      {
        ...translationParameters
      }
    );
    return paginationTranslation
      ? `${paginationTranslation} | ${descriptionTranslation}`
      : descriptionTranslation;
  }

  descriptionTranslation = isDynamicSeo
    ? getDynamicSEOElementTranslation({
        params,
        portal,
        element: 'description',
        isClassMultiType: checkClassIsMultiType({ params, boatClasses })
      })
    : getSEOElementTranslation(params, portal, 'description', numListings);
  return paginationTranslation
    ? `${paginationTranslation} ${descriptionTranslation}`
    : descriptionTranslation;
};

/**
 * @param {object} params
 * @param {string} params.boatClass
 * @param {string} params.make
 * @param {string} params.type
 * @returns {boolean}
 * @description Returns true if the make name should be used instead of SeoMakeName
 */
export const removeSeoMakeName = (params) => {
  const removeSeoMakeNameWithClass = get(
    getConfig(),
    'supports.removeSeoMakeNameWithClass',
    false
  );
  if (!removeSeoMakeNameWithClass) {
    return false;
  }
  const locale = getCurrentLocale();
  const validLocales = ['es', 'it', 'fr'];
  if (!validLocales.includes(locale)) {
    return false;
  }
  const validDefinedParam = ['boatClass', 'make', 'type'];
  if (validDefinedParam.every((param) => !params[param])) {
    return false;
  }
  for (const param in params) {
    if (!validDefinedParam.includes(param) && params[param]) {
      return false;
    }
  }
  return true;
};

// verify if the class belongs to different types, ex. power-barge and sail-barge
const checkClassIsMultiType = ({ boatClasses, params }) => {
  if (!params.boatClass) {
    return false;
  }

  const className = params.boatClass.split('-')[1];
  const classNameRegex = new RegExp(className);

  return (
    boatClasses.filter((boatClass) => classNameRegex.test(boatClass.value))
      .length > 1
  );
};

export const getH1 = ({
  params,
  portal,
  isBranded = false,
  displayName,
  boatClasses = [],
  forceDynamic = false // temporal flag to only be used in 3 column context
}) => {
  if (removeSeoMakeName(params)) {
    params.make = params.make.replace(' Yachts', '');
  }
  let h1Translation, paginationTranslation;
  const separator = getPortalSeparator();
  const isDynamicSeo =
    forceDynamic ||
    get(getConfig(), 'supports.dynamicSeoTitleDescription', false);

  if (isBranded) {
    h1Translation = displayName;
    paginationTranslation = getPaginationTranslation(params, '', 'h1');
    return paginationTranslation
      ? `${paginationTranslation} ${separator} ${h1Translation}`
      : h1Translation;
  }
  h1Translation = !isDynamicSeo
    ? getSEOElementTranslation(params, portal, 'title')
    : getDynamicSEOElementTranslation({
        params,
        portal,
        element: 'title',
        isClassMultiType: checkClassIsMultiType({ boatClasses, params })
      });
  paginationTranslation = getPaginationTranslation(params, '', 'h1');
  return paginationTranslation
    ? `${paginationTranslation} ${separator} ${h1Translation}`
    : h1Translation;
};

export const getNoFollow = (url, isBranded = false) => {
  const activeParams = getActiveParams(parseSearchBoatsAppParams(url, isBranded));
  const boatsConstants = getBoatConstantsFromI18n();
  const entries = Object.entries(activeParams);
  if (entries.length === 0) {
    return false;
  }
  return !entries.some(([paramKey, paramValue]) => {
    // Prevent /makemodel-{makes} to be used by crawlers for ranking calculations
    if (url.match(boatsConstants.SEARCH_URL_MAKE_MODEL_PATTERN)) {
      return false;
    }

    let noFollow = Object.keys(paramValue).length > 0 || !(paramValue instanceof Object) ? arrayHasValueInObject(noIndexNoFollowValues, paramValue) : false;
    if (noFollow) {
      return !noFollow;
    }
    if (includes(noFollowMultifacetedFacets, paramKey)) {
      noFollow = isSingleFaceted(paramValue);
    }
    if (includes(noFollowExcludedFacets, paramKey)) {
      return true;
    }
    return noFollow;
  });
};

const arrayHasValueInObject = (array, obj) => {
  return array.some(item => {
    if (Object.prototype.hasOwnProperty.call(obj, item)) {
      return true;
    }

    return Object.values(obj).some(
      value => Array.isArray(value) && value.includes(item) || value === item
    );
  });
};


export const getNoIndex = (url, isBranded = false) => {
  const activeParams = getActiveParams(parseSearchBoatsAppParams(url, isBranded));
  const entries = Object.entries(activeParams);
  const boatsConstants = getBoatConstantsFromI18n();
  return entries.some(([paramKey, paramValue]) => {
    // Prevent /makemodel-{makes} and a certain of facets to be indexed
    if (includes(noIndexFacets, paramKey) || url.match(boatsConstants.SEARCH_URL_MAKE_MODEL_PATTERN)) {
      return true;
    }
    const useBrokerSpotlightIndexing = get(
      getConfig(),
      'supports.useBrokerSpotlightIndexing',
      false
    );
    if (useBrokerSpotlightIndexing) {
      const theIndexableFacets = isBranded
        ? boatsConstants.BROKER_SPOTLIGHT_INDEXABLE_FACETS
        : boatsConstants.INDEXABLE_FACETS;
      if (!includes(theIndexableFacets, paramKey)) {
        return true;
      }
    } else {
      const theIndexableFacets = isBranded
        ? boatsConstants.BRANDED_INDEXABLE_FACETS
        : boatsConstants.INDEXABLE_FACETS;
      if (!includes(theIndexableFacets, paramKey)) {
        return true;
      }
    }

    const indexableValue = arrayHasValueInObject(noIndexNoFollowValues, paramValue);

    if (
      includes(
        [
          'multiFacetedBoatTypeClass',
          'makeModel',
          'city',
          'hullMaterial',
          'fuelType'
        ],
        paramKey
      )
    ) {
      return (
        (isBranded && !isEmpty(paramValue)) || !isSingleFaceted(paramValue) || indexableValue
      );
    }

    return indexableValue;
  });
};

export const getLinkRelPrev = () => {
  return '';
};

export const getLinkRelNext = () => {
  return '';
};

const notIndexable = (params) =>
  Object.keys(params).some(
    (key) => !getBoatConstantsFromI18n().INDEXABLE_PARAMS.includes(key) && !isEmpty(params[key])
  );

export const getCanonical = (
  params,
  isBranded = false,
  partyDetails = {},
  salesRep = {},
  i18Service
) => {
  const locale = getCurrentLocale();
  if (notIndexable(params)) {
    return '';
  }
  if (isBranded) {
    return urlFromLanguage( getPartyBrandedSRP(partyDetails, false, null, salesRep) );
  }

  let canonicalUrlParams = {};
  const canonicalValidFilter = [
    'makeModel',
    'condition',
    'page',
    'owner',
    'modelRange',
    'group',
    'worldRegion',
    'hullMaterial',
    'fuelType',
    'region',
    'subdivision',
    'country',
    'city'
  ];
  let defaultParams = getDefaultParams(params);
  const activeParams = getActiveParams(defaultParams);
  const indexableMultifaceted =
    Object.keys(activeParams.multiFacetedBoatTypeClass).length === 1;

  if (indexableMultifaceted) {
    canonicalUrlParams.multiFacetedBoatTypeClass =
      activeParams.multiFacetedBoatTypeClass;
  }

  canonicalValidFilter.forEach((Filter) => {
    if (activeParams[Filter]) {
      canonicalUrlParams[Filter] = activeParams[Filter];
    }
  });
  const searchPath = generateSearchPath(
    canonicalUrlParams,
    getDefaultParams({}),
    false,
    null,
    i18Service
  );
  return urlFromLanguage(searchPath, locale);
};

export const getSEOParams = (params, _makeModel, seoMakeInfo = {}) => {
  const activeParams = getActiveParams(getDefaultParams(params));

  let {
    city,
    condition,
    country,
    forSale,
    fuelType,
    hullMaterial,
    makeModel = {},
    modelRange,
    multiFacetedBoatTypeClass,
    page,
    region,
    subdivision,
    group,
    worldRegion
  } = activeParams;

  let type = get(Object.keys(multiFacetedBoatTypeClass), '0', '');
  let boatClass = get(multiFacetedBoatTypeClass, `${type}.0`, '');
  let _make = get(Object.keys(makeModel), '0', '');
  const _model = get(makeModel, `${_make}.0`, '');
  const selectedMakeModelFacet = _makeModel.find(
    (e) => hyphenateUrlComponents(e.value) === _make
  );
  let makeObj = selectedMakeModelFacet
    ? selectedMakeModelFacet
    : { value: _make };
  let make = get(makeObj, 'value', '');
  const modelObj = unhyphenateUrlComponents(_model);
  const model = capitalizeEachWord(modelObj);
  modelRange =
    modelRange &&
    capitalizeEachWord(unhyphenateUrlComponents(activeParams.modelRange));

  if (seoMakeInfo.seoMakeName && !model) {
    make = seoMakeInfo.seoMakeName;
  }

  return {
    boatClass,
    condition,
    make,
    model,
    modelRange,
    region,
    subdivision,
    page,
    type,
    sellerType: forSale,
    country,
    city,
    fuelType,
    hullMaterial,
    group,
    worldRegion
  };
};

export const getSEOElementTranslation = (
  params,
  portal,
  element,
  numListings = ''
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  // Get all indexable facets values of the current search translated
  let {
    boatClass,
    condition,
    make,
    model,
    modelRange,
    region,
    subdivision,
    type,
    sellerType,
    country,
    city,
    fuelType,
    hullMaterial,
    group
  } = params;
  make = translateMake(make);
  const subdivisionCountry = country || getPortalDefaultCountry();
  const boatsContstants = getBoatConstantsFromI18n();

  boatClass = boatsContstants.allTypes.includes(boatClass) ? undefined : boatClass;
  condition =
    condition &&
    getFacetTranslation(
      `searchPageSEO.indexableFacets.condition[${condition}]`
    ).toLowerCase();
  type = type && getFacetTranslation(type).toLowerCase();
  boatClass =
    boatClass &&
    getFacetTranslation(`classFacetValues[${boatClass}]`).toLowerCase();
  group = group && getFacetTranslation(group);
  region = region && getFacetTranslation(`countryRegions[${region}]`);
  subdivision =
    subdivision &&
    getFacetTranslation(
      `countrySubdivision.${subdivisionCountry}.${subdivision}`
    );
  sellerType = sellerType && getFacetTranslation(`forSaleFacet[${sellerType}]`);
  country = country && getFacetTranslation(`countries[${country}]`);
  city =
    city &&
    city.length > 0 &&
    capitalizeString(unhyphenateUrlComponents(city[0]));
  fuelType =
    fuelType &&
    (getFacetTranslation(`fuelTypeHeadingText[${fuelType[0]}]`)
      ? getFacetTranslation(`fuelTypeHeadingText[${fuelType[0]}]`)
      : getFacetTranslation(`fuelTypeFacetText[${fuelType[0]}]`));
  hullMaterial =
    hullMaterial &&
    getFacetTranslation(`hullMaterialFacetText[${hullMaterial[0]}]`);

  const locationFacet = {};
  locationFacet[
    city ? 'city' : subdivision ? 'subdivision' : region ? 'region' : 'country'
  ] = city || subdivision || region || country;
  const indexableFacets = {
    class: boatClass,
    condition,
    make,
    model,
    modelRange,
    type,
    sellerType,
    ...locationFacet,
    fuelType,
    hullMaterial,
    group
  };

  let messageKey = 'searchPageSEO.metaElements';
  boatsContstants.INDEXABLE_TRANSLATION_PARAMS_PRIORITY.forEach((facet) => {
    if (indexableFacets[facet] && get(messages, `${messageKey}.${facet}`)) {
      messageKey += `.${facet}`;
    }
  });

  // If the resulting key doesn't have a template, remove the last part until getting a valid combination of facets
  while (!get(messages, `${messageKey}.${element}`)) {
    if (messageKey.includes('.')) {
      messageKey = messageKey.slice(0, messageKey.lastIndexOf('.'));
    } else {
      // Couldn't find a message for that element and combination of facets
      return undefined;
    }
  }

  // Inject the parameters to the template and return the capitalized translation
  let translation = t(get(messages, `${messageKey}.${element}`), {
    ...indexableFacets,
    numListings,
    portal
  });

  if (element === 'title') {
    translation = removeDuplicatedBoatYacht(translation);
  }
  return capitalizeString(translation).replace('  ', ' ');
};

// WTF is this function!!!! Horror!!!!
export const getDynamicSEOElementTranslation = ({
  params,
  portal,
  element,
  isClassMultiType = false,
  numListings = ''
}) => {
  const t = getFormatMessageFunction();
  // Get all indexable facets values of the current search translated
  let {
    boatClass,
    condition,
    make,
    model,
    modelRange,
    region,
    subdivision,
    type,
    country,
    city,
    worldRegion,
    fuelType,
    hullMaterial,
    group,
    sellerType
  } = params;
  make = translateMake(make);
  const subdivisionCountry = country || getPortalDefaultCountry();
  const boatsConstants = getBoatConstantsFromI18n();
  const messages = getMessages();
  const isTitle = element.includes('title');
  const isDescription = element.includes('description');
  element = 'dynamic' + element.charAt(0).toUpperCase() + element.slice(1);

  boatClass = boatsConstants.allTypes.includes(boatClass) ? undefined : boatClass;
  condition =
    condition &&
    getFacetTranslation(
      `searchPageSEO.indexableFacets.condition[${condition}]`
    ).toLowerCase();
  type = type && getFacetTranslation(type).toLowerCase();
  boatClass =
    boatClass &&
    getFacetTranslation(`classFacetValues[${boatClass}]`).toLowerCase();
  region = region && getFacetTranslation(`countryRegions[${region}]`);
  subdivision =
    subdivision &&
    getFacetTranslation(
      `countrySubdivision.${subdivisionCountry}.${subdivision}`
    );
  country = country && getFacetTranslation(`countries[${country}]`);
  city =
    city &&
    city.length > 0 &&
    capitalizeString(unhyphenateUrlComponents(city[0]));
  worldRegion =
    worldRegion && getFacetTranslation(`worldRegions[${worldRegion}]`);
  fuelType =
    fuelType &&
    (getFacetTranslation(`fuelTypeHeadingText[${fuelType[0]}]`)
      ? getFacetTranslation(`fuelTypeHeadingText[${fuelType[0]}]`)
      : getFacetTranslation(`fuelTypeFacetText[${fuelType[0]}]`));
  hullMaterial =
    hullMaterial &&
    getFacetTranslation(`hullMaterialFacetText[${hullMaterial[0]}]`);
  group = group && getFacetTranslation(group);
  sellerType = sellerType && getFacetTranslation(`forSaleFacet[${sellerType}]`);

  const locationFacet = {};
  locationFacet[
    city
      ? 'city'
      : subdivision
      ? 'subdivision'
      : region
      ? 'region'
      : country
      ? 'country'
      : 'worldRegion'
  ] = city || subdivision || region || country || worldRegion;
  const indexableFacets = {
    class: new TextTransform(boatClass).startCase().toString(),
    condition: new TextTransform(condition).startCase().toString(),
    make: new TextTransform(make).startCase().toString(),
    model: new TextTransform(model).startCase().toString(),
    modelRange: new TextTransform(modelRange).startCase().toString(),
    type: new TextTransform(type).startCase().toString(),
    fuelType: new TextTransform(fuelType).startCase().toString(),
    hullMaterial: new TextTransform(hullMaterial).startCase().toString(),
    group: new TextTransform(group).startCase().toString(),
    sellerType: new TextTransform(sellerType).startCase().toString(),
    ...locationFacet
  };
  if (boatClass && !isClassMultiType) {
    indexableFacets.type = '';
  }

  if (!type) {
    indexableFacets.class = '';
  }
  if (model && !make) {
    indexableFacets.model = '';
  }
  if (modelRange && !make) {
    indexableFacets.modelRange = '';
  }
  if (modelRange && model) {
    indexableFacets.modelRange = '';
  }
  if (fuelType && hullMaterial) {
    indexableFacets.hullMaterial = '';
  }
  if (group && type) {
    indexableFacets.group = '';
  }
  if (sellerType) {
    indexableFacets.sellerType = `by ${indexableFacets.sellerType}`;
  }

  // eslint-disable-next-line no-restricted-syntax
  if (isDescription && portal.toLowerCase() === 'yachtworld') {
    numListings = '';
  }

  let translation;
  if (
    Object.keys(indexableFacets).filter((f) => indexableFacets[f]).length > 0
  ) {
    let location = city || subdivision || region || country || worldRegion;
    // Inject the parameters to the template and return the capitalized translation
    translation = t(
      get(messages, `searchPageSEO.metaElements.${element}.intro`, ''),
      {
        ...indexableFacets,
        portal,
        numListings
      }
    );
    if (location) {
      translation += t(
        get(messages, `searchPageSEO.metaElements.${element}.location`, ''),
        {
          location
        }
      );
    } else if (!isTitle) {
      translation += t(
        get(
          messages,
          `searchPageSEO.metaElements.${element}.generalLocation`,
          ''
        ),
        {
          portal
        }
      );
    }
    if (!isTitle) {
      translation += t(
        get(messages, `searchPageSEO.metaElements.${element}.outro`, ''),
        {
          make
        }
      );
    }
  } else {
    // Inject the parameters to the template and return the capitalized translation
    translation = t(
      get(messages, `searchPageSEO.metaElements.${element}`, ''),
      {
        ...indexableFacets,
        portal
      }
    );
  }
  return new TextTransform(translation)
    .removeExtraSpaces()
    .removeDoubleBoatsWords()
    .trim()
    .capitalizeFirstLetter()
    .removeExtraSpaces()
    .toString();
};

export const getPaginationTranslation = (params, maxPages, element) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const { page } = params;
  if (page && page !== '1') {
    const message = messages.searchPageSEO.pagination[element];
    return message
      ? t(messages.searchPageSEO.pagination[element], {
          page,
          totalPages: maxPages
        })
      : undefined;
  }
  return '';
};

export const removeDuplicatedBoatYacht = (translation) => {
  const translationWords = translation.split(' ');
  const boatStopWordsInTranslation = translationWords.filter((word) =>
    boatStopWords.includes(word.toLowerCase())
  );
  if (boatStopWordsInTranslation.length > 1) {
    const duplicatedWord = boatStopWordsInTranslation[1];
    const duplicatedWordIndex = translationWords.indexOf(duplicatedWord);
    translationWords.splice(duplicatedWordIndex, 1);
    return translationWords.join(' ');
  }
  const yachtStopWordsInTranslation = translationWords.filter((word) =>
    yachtStopWords.includes(word.toLowerCase())
  );
  if (yachtStopWordsInTranslation.length > 0) {
    const duplicatedBoatWordIndex = translationWords.indexOf(
      boatStopWordsInTranslation[0]
    );
    if (duplicatedBoatWordIndex >= 0) {
      translationWords.splice(duplicatedBoatWordIndex, 1);
    }
    return translationWords.join(' ');
  }
  return translation;
};

export const cleanText = (text) => {
  return new TextTransform(text)
    .trim()
    .capitalizeFirstLetter()
    .removeExtraSpaces()
    .toString();
};
