import { getMessages } from '../tppServices/translations/messages';

export const sortTypeMap = {
  'recommended:desc': 'recommended',
  'updated:desc': 'modified-desc',
  'updated:asc': 'modified-asc',
  'distance:asc': 'proximity-asc',
  'distance:desc': 'proximity-desc',
  'year:desc': 'year-desc',
  'year:asc': 'year-asc',
  'length:desc': 'length-desc',
  'length:asc': 'length-asc',
  'price:asc': 'price-asc',
  'price:desc': 'price-desc',
  'make:asc': 'make-asc',
  'make:desc': 'make-desc',
  'powerhp:asc': 'powerhp-asc',
  'powerhp:desc': 'powerhp-desc',
};

export const getSortNameMap = () => {
  const messages = getMessages();
  return {
    'recommended:desc': messages.searchHeader.sort.recommended,
    'updated:desc': messages.searchHeader.sort.newestUpdated,
    'updated:asc': messages.searchHeader.sort.oldestUpdated,
    'distance:asc': messages.searchHeader.sort.nearest,
    'distance:desc': messages.searchHeader.sort.farthest,
    'year:desc': messages.searchHeader.sort.newestYear,
    'year:asc': messages.searchHeader.sort.oldestYear,
    'length:desc': messages.searchHeader.sort.longest,
    'length:asc': messages.searchHeader.sort.shortest,
    'price:asc': messages.searchHeader.sort.cheapest,
    'price:desc': messages.searchHeader.sort.pricey,
    'make:asc': messages.searchHeader.sort.firstMake,
    'make:desc': messages.searchHeader.sort.lastMake,
    'powerhp:asc': messages.searchHeader.sort.leastPowerful,
    'powerhp:desc': messages.searchHeader.sort.mostPowerful,
  };
};

export const getDefaultSort = (postalCode, sortRecommendedOptionEnabled = false) => {
  if (postalCode) {
    return 'distance:asc';
  }

  if (sortRecommendedOptionEnabled) {
    return 'recommended:desc';
  }

  return 'length:desc';
};

export const getDefaultEnginesSort = (postalCode) => {
  if (postalCode) {
    return 'distance:asc';
  }
  return 'year:desc';
};
