import React from 'react';
import { createContext } from 'react';
import { getConfig } from '../config/portal';

export const initialABTestContext = {
  featureFlags: []
};

export const FEATURE_FLAG_KEYS = {
  THREE_COLUMN: 'three-col',
  SORT: 'ab-sort',
  FUZZY_SPONSORED_BOATS: 'srp-fuzzy-sponsored',
  DEFAULT_RADIUS: 'default-radius-search',
  RANDOMIZED_SPONSORED: 'randomized-sponsored-search',
  LISTING_IMAGE_CAROUSEL: 'listing-image-carousel',
  ENGINE_DETAILS_FILTER: 'engine-details-filter',
  ENGINE_MAKES_FILTER: 'engine-makes-filter',
  BOATS_BDP_FINANCE_V2: 'yw-bdp-finance-v2-cta',
  BOAT_BDP_LEAD_HYGIENE: 'yw-bdp-lead-hygiene',
};


const getFeatureKey = (key) => {
  const kameleoonConfig = getConfig()?.scripts?.kameleoon?.featureFlags;
  return kameleoonConfig?.[key];
};

export const isFeatureFlagActive = (featureKey, featureFlags, cookies) => {
  const envFeatureKey = getFeatureKey(featureKey);
  const isFeatureFlagActive =
    Array.isArray(featureFlags) &&
    !!featureFlags.find((featureFlag) => featureFlag.key === envFeatureKey);

  return isFeatureFlagActive || isFeatureCookiePresent(cookies, envFeatureKey);
};

export const isFeatureCookiePresent = (cookies, featureKey) => {
  const featureFlagsCookie =
    (cookies?.get
      ? cookies.get('kameleoonFeatureFlags')
      : cookies?.kameleoonFeatureFlags) ?? '';
  return featureFlagsCookie.split(',').includes(featureKey);
};

const ABTestContext = createContext(initialABTestContext);

export const ABTestProvider = ABTestContext.Provider;
const ABTestConsumer = ABTestContext.Consumer;

export const withABTestContext = (Component) => {
  const Wrapper = (props) => {
    return (
      <ABTestConsumer>
        {(context) => {
          return <Component abTestContext={context} {...props} />;
        }}
      </ABTestConsumer>
    );
  };

  return Wrapper;
};

export default ABTestContext;
