import React from 'react';
import PropTypes from 'prop-types';

import DefaultLayout from '../../layouts/Default';
import ErrorMessage from '../../components/ErrorMessage';
import {getTPPServices} from '../../tppServices/tppDi';

const ErrorBoundary = (props) => {
  const {languageService} = getTPPServices();
  const routesConstants = languageService.getRouteConstants();
  try {
    if (props.error) {
      // This line of code is used only to reach a 100% in the tests coverage as of now
      // there's no direct way to simulate an erro with @react/testing-library.
      throw props.error;
    } else {
      return props.children;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    error && console.error('ErrorBoundary', error);
    // eslint-disable-next-line no-console
    error.info && console.error('ErrorBoundary', error.info);
    return (
      <DefaultLayout {...props} pageType="notFound">
        <ErrorMessage
          {...props}
          message="Please reset your search and try again."
          altUrl={routesConstants.SEARCH_URL_ROOT}
        />
      </DefaultLayout>
    );
  }
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  error: PropTypes.object
};

export default ErrorBoundary;
