import { addResizeParams } from '../../utils/listingHelper';

export const prepareImageSrc = (media, width, height, intrinsicWidth) => {
  const url = addResizeParams(
    media.url,
    { width, height },
    media.date.modified,
    false,
    'webp'
  );
  return `${url} ${intrinsicWidth}w`;
};
