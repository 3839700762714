// This could completely remove intl provider and config provider.
// Next iterations of language refactor could do that
import {useContext} from 'react';
import {ServiceContext} from '../context/ServicesProvider';
import {mappedValue} from './tppDi';

/**
 * Custom hook to access the tpp container on react
 * @returns {{boatsConstants: (*|{}), configService: *, tpp: *, mappedValue: ((function(*, *, *): string)|*), currentI18n: (*|Error|{}), languageService: *, routesConstants: *}}
 */
const useTPPServices = () => {
  const tpp = useContext(ServiceContext);
  const languageService = tpp.languageService();
  const configService = tpp.configService();
  const currentI18n = languageService.getI18n();
  const boatsConstants = currentI18n?.boatsConstants;
  const routesConstants = currentI18n?.routesConstants;
  return {tpp, languageService, configService, currentI18n, boatsConstants, routesConstants, mappedValue};
};

export {useTPPServices};
