import React from 'react';

/**
 * A Generic HOC that makes a given context accessible within a given component
 * @param {Object} Context - The context to make accessible
 * @param {string} propName - Name of the prop under which the context value
 * will be accessible within the Component
 * @param {ReactNode} Component - The component that will consume the context
 */
export const withContext = (Context, propName) => (Component) => {
  if (!propName) {
    throw new Error(
      `The function "withContext" must be provided a value for "propName".`
    );
  }

  const Wrapper = (props) => {
    return (
      <Context.Consumer>
        {(context) => {
          const contextProp = { [propName]: context };
          return <Component {...contextProp} {...props} />;
        }}
      </Context.Consumer>
    );
  };

  return Wrapper;
};
