import {getBoatConstantsFromI18n} from '../tppServices/translations/constants';

export const DEFAULT_POWER = 'power-all';
export const DEFAULT_SAIL = 'sail-all';
export const DEFAULT_UNPOWERED = 'unpowered-all';

export const legacyMappings = {
  'aft-cabin': ['power-aft'],
  'aft+cabin': ['power-aft'],
  'airboat': ['power-airboat'],
  'aluminum+fish+boats': ['power-aluminum'],
  'aluminum-fish-boats': ['power-aluminum'],
  'angler': ['power-saltfish'],
  'bass+boat': ['power-bass'],
  'bass-boat': ['power-bass'],
  'bay+boat': ['power-bay'],
  'bay-boat': ['power-bay'],
  'bluewater+fishing': ['power-saltfish'],
  'bowrider': ['power-bowrider'],
  'canoe-kayak': ['unpowered-kayak'],
  'catamaran+(power)': ['power-pcatamaran'],
  'catamaran-power': ['power-pcatamaran'],
  'center+console': ['power-center'],
  'center-console': ['power-center'],
  'classic+(power)': ['power-antique'],
  'commercial': ['power-commercial'],
  'commercial-vessels': ['power-commercial'],
  'convertible': ['power-convertible'],
  'cruiser+(power)': ['power-cruiser'],
  'cruiser-(power)': ['power-cruiser'],
  'cruiser-power': ['power-cruiser'],
  'cuddy+cabin': ['power-cuddy'],
  'cuddy-cabin': ['power-cuddy'],
  'deck+boat': ['power-deck'],
  'deck-boat': ['power-deck'],
  'dive+boat': ['power-dive'],
  'dive-boat': ['power-dive'],
  'downeast': ['power-downeast'],
  'dual+console': ['power-dualconsole'],
  'dual-console': ['power-dualconsole'],
  'duck+boat': ['power-jon'],
  'duck-boat': ['power-jon'],
  'express+cruiser': ['power-expresscruiser'],
  'express-cruiser': ['power-expresscruiser'],
  'fish+and+ski': ['power-skifish'],
  'fish-and-ski': ['power-skifish'],
  'flats+boat': ['power-flats'],
  'flats-boat': ['power-flats'],
  'flybridge': ['power-flybridge'],
  'freshwater+fishing': ['power-fresh'],
  'freshwater-fishing-boats': ['power-fresh'],
  'high+performance': ['power-highperf'],
  'high-performance-boats': ['power-highperf'],
  'houseboat': ['power-house'],
  'houseboats': ['power-house'],
  'jet+boat': ['power-jet'],
  'jet-boat': ['power-jet'],
  'jon+boat': ['power-jon'],
  'jon-boat': ['power-jon'],
  'mega+yacht': ['power-mega'],
  'motoryacht': ['power-motor'],
  'motor-yachts': ['power-motor'],
  'other': [
    'power-other',
    'sail-other',
    'unpowered-other'
  ],
  'pilothouse': ['power-pilot'],
  'pontoon': ['power-pontoon'],
  'pontoon-deck-boats': ['power-pontoon'],
  'runabout': ['power-runabout'],
  'saltwater-fishing': ['power-saltfish'],
  'saltwater-fishing-boats': ['power-saltfish'],
  'saltwater+fishing': ['power-saltfish'],
  'ski+and+wakeboard-boat': ['power-skiwake'],
  'ski-and-wakeboard-boat': ['power-skiwake'],
  'ski+and+wakeboard+boat': ['power-skiwake'],
  'ski-wakeboard-boats': ['power-skiwake'],
  'skiff': ['power-skiff'],
  'sport+fisherman': ['power-sportfish'],
  'sport-fisherman': ['power-sportfish'],
  'submersible': ['power-commercial'],
  'trawler': ['power-trawler'],
  'walkaround': ['power-walk'],
  'weekender': ['power-cruiser'],
  'catamaran+(sail)': ['sail-catamaran'],
  'classic+(sail)': ['sail-antique'],
  'classic-(sail)': ['sail-antique'],
  'cruiser+(sail)': ['sail-cruiser'],
  'cruiser-(sail)': ['sail-cruiser'],
  'cruiser/racer': ['sail-racercruiser'],
  'cutter': ['sail-cutter'],
  'daysailor/weekender': ['sail-day'],
  'ketch': ['sail-ketch'],
  'motorsailer': ['sail-motor'],
  'multi+hull': [
    'sail-multihull',
    'sail-trimaran'
  ],
  'multi-hull': [
    'sail-multihull',
    'sail-trimaran'
  ],
  'racer': ['sail-racer'],
  'sloop': ['sail-sloop'],
  'yawl': ['sail-yawl'],
  'canoe/kayak': ['unpowered-kayak'],
  'inflatables': ['power-inflatable'],
  'inflatable-rib-boats': ['power-inflatable'],
  'rigid+inflatable': ['power-rib'],
  'rigid-inflatable': ['power-rib'],
  'tenders/small+boats': [
    'power-dinghy',
    'sail-dinghy',
    'unpowered-dinghy',
    'unpowered-narrow',
    'power-narrow',
    'power-tender',
    'unpowered-tender'
  ],
  'tenders-small-boats': ['power-tender'],
  'pwc-jet-boats': ['power-pwc']
};

export const mappingForDataLayer = {
  'power-sloop': 'launch'
};

export const handleLegacyAllType = (type, category) => {
  if (category === 'all' || category === 'other') {
    switch (type) {
    case 'power': return `power-${category}`;
    case 'sail': return `sail-${category}`;
    case 'unpowered': return `unpowered-${category}`;
    }
  }
  return category;
};

export const mapAllType = (classArr, i18nService) => {
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const allTypes = boatConstants.allTypes;
  if (classArr.some( cl => allTypes.includes(cl))) {
    return [];
  }
  return classArr;
};

export const mapAllClassForType = (multiFacetedBoatTypeClass) => {
  if (multiFacetedBoatTypeClass.power) {
    multiFacetedBoatTypeClass.power = mapAllType(multiFacetedBoatTypeClass.power);
  }
  if (multiFacetedBoatTypeClass.sail) {
    multiFacetedBoatTypeClass.sail = mapAllType(multiFacetedBoatTypeClass.sail);
  }
  if (multiFacetedBoatTypeClass.unpowered) {
    multiFacetedBoatTypeClass.unpowered = mapAllType(multiFacetedBoatTypeClass.unpowered);
  }
  return multiFacetedBoatTypeClass;
};

export const getClassCategory = boatClass => boatClass.split('-')[0];

export const checkNeedsDefaults = (types) => {
  if (types.power) {
    if (types.power.length === 0) {
      types.power.push(DEFAULT_POWER);
    }
  }
  if (types.sail) {
    if (types.sail.length === 0) {
      types.sail.push(DEFAULT_SAIL);
    }
  }
  if (types.unpowered) {
    if (types.unpowered.length === 0) {
      types.unpowered.push(DEFAULT_UNPOWERED);
    }
  }
  return types;
};

export const getLegacyMappingFromBoatClass = boatClass => Object.entries(legacyMappings)
  .filter(([, apiMappings]) => apiMappings.includes(boatClass))
  .reduce((prev, current) => current[0], boatClass);

export const getBoatClassMappingForDataLayer = (boatClass) => {
  if (!boatClass) {return;}

  let mappedBoatClass = boatClass;
  for (const key in mappingForDataLayer) {
    const classRegex = RegExp(`${key.replace('-', '(-| |\\+)')}?`, 'i');
    mappedBoatClass = mappedBoatClass.replace(classRegex, mappingForDataLayer[key]);
  }

  return mappedBoatClass;
};
