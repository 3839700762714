import {getDefaultI18Service} from '../tppDi';

/**
 * Get all the boats constants from the i18n object
 * @param i18Service
 * previously called getCurrentBoats and aliased often as "bts()"
 * @returns {{}}
 */
const getBoatConstantsFromI18n = (i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  return i18Service.getCurrentI18n().boatsConstants;
};

/**
 * Get all routes constants from the i18n object
 * Previously called getCurrentRoutes and aliased often as "bts()"
 * @param i18Service
 * @returns {{}}
 */
const getRouteConstantsFromI18n = (i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  return i18Service.getCurrentI18n().routesConstants;
};

const getMappedValueFromI18n = (i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  return i18Service.mappedValue;
};

export { getBoatConstantsFromI18n, getRouteConstantsFromI18n, getMappedValueFromI18n };
