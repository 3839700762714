import {getCurrentLocale} from '../language';
import {getConfig} from '../../config/portal';
import {getBoatConstantsFromI18n, getMappedValueFromI18n} from '../../tppServices/translations/constants';
import {getFormatMessageFunction} from '../../tppServices/tppDi';
import {multiFacetUrlToObject} from '../multiFacetHelper';
import {getCountryCodeFromURL, initializeGlobalSubdivisionsURLs, unslugify} from './shared';
import { getMessages } from '../../tppServices/translations/messages';

export const parseEnginesAppUrlParams = (boatConstants, config, t, mappedValue) => {
  const messages = getMessages();
  const parseSubdivisionParams = (subdivision = '') => {
    const locale = getCurrentLocale();
    initializeGlobalSubdivisionsURLs(locale);
    if (boatConstants.SEARCH_URL_SUBDIVISION_PATTERN.test(subdivision)) {
      const matches = boatConstants.SEARCH_URL_SUBDIVISION_PATTERN.exec(subdivision);
      let twoLetterSubdivision = matches[1].split('|');
      if (!global.url) {
        twoLetterSubdivision = twoLetterSubdivision.map(subdivision => {
          return subdivision.toUpperCase();
        });
        return {
          subdivision: twoLetterSubdivision[0] //TODO add some validation to this data.
        };
      }
      for (const subdivisionElement of global.url[locale].subdivisions) {
        if (twoLetterSubdivision.includes(subdivisionElement.url)) {
          const subdivisionCode = subdivisionElement.code.toUpperCase().split('-')[1];
          return {
            subdivision: subdivisionCode
          };
        }
        if (twoLetterSubdivision[0] === subdivisionElement.code.toLowerCase().split('-')[1]) {
          const subdivisionCode = subdivisionElement.code.toUpperCase().split('-')[1];
          return {
            subdivision: subdivisionCode
          };
        }
      }
      twoLetterSubdivision = twoLetterSubdivision.map(subdivision => {
        return subdivision.toUpperCase();
      });
      return {
        subdivision: twoLetterSubdivision[0] //TODO add some validation to this data.
      };
    }
    return {};
  };

  const parseRegionParams = (region = '') => {
    if (boatConstants.SEARCH_URL_REGION_PATTERN.test(region)) {
      const matches = boatConstants.SEARCH_URL_REGION_PATTERN.exec(region);
      return {
        region: matches[1]//TODO add some validation to this data.
      };
    }
    return {};
  };

  const parseCountryParams = (country = '') => {
    country = country ? getCountryCodeFromURL(country) : country;
    if (boatConstants.SEARCH_URL_COUNTRY_PATTERN.test(country)) {
      const matches = boatConstants.SEARCH_URL_COUNTRY_PATTERN.exec(country);
      return {
        country: matches[1].toUpperCase()//TODO add some validation to this data.
      };
    }
    return {};
  };

  const parseCityParams = (city = '') => {
    if (boatConstants.SEARCH_URL_CITY_PATTERN.test(city)) {
      const matches = boatConstants.SEARCH_URL_CITY_PATTERN.exec(city);
      let cityValue = matches[1].split('+');
      return {
        city: cityValue
      };
    }
    return {};
  };

  const parseLegacyMakeModelParams = (make = '', model = '') => {
    if (boatConstants.SEARCH_URL_MAKE_PATTERN.test(make)) {
      const makeMatches = boatConstants.SEARCH_URL_MAKE_PATTERN.exec(make);
      const makeValue = unslugify(makeMatches[1], ' ', '-');
      const modelMatches = model ? boatConstants.SEARCH_URL_MODEL_PATTERN.exec(model) : '';
      let modelValue = [];
      if (modelMatches) {
        modelValue = [unslugify(modelMatches[1], ' ', '-')];
      }
      return {
        makeModel: {
          [makeValue]: modelValue
        }
      };
    }
    return {};
  };

  const parseMakeModelParams = (makeModel) => {
    if (boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.test(makeModel)) {
      const matches = boatConstants.SEARCH_URL_MAKE_MODEL_PATTERN.exec(makeModel);
      return {
        makeModel: multiFacetUrlToObject(matches[1], true, '+')
      };
    }
    return {};
  };

  const parseCategoryParams = (category = '') => {
    if (boatConstants.SEARCH_URL_CATEGORY_PATTERN.test(category)) {
      return {
        category: mappedValue(category.substring(category.indexOf('-') + 1).toLowerCase(), messages.categoryFacet)
      };
    }
    return {category: ''};
  };

  const parseEnginePageParams = (page = '') => {
    let def = {page: '1', pageSize: '28'};
    if (boatConstants.SEARCH_URL_PAGE_PATTERN.test(page)) {
      const matches = boatConstants.SEARCH_URL_PAGE_PATTERN.exec(page);
      return {
        page: matches[1],
        pageSize: '28'
      };
    }
    return def;
  };

  const parseEngineSortParams = (sort = '') => {
    const matches = boatConstants.SEARCH_URL_SORT_PATTERN.exec(sort);
    if (matches) {
      const [sortBy, sortOrder] = matches[1].split(':');
      return {sort: `${mappedValue(sortBy, messages.search)}:${mappedValue(sortOrder, messages.sortFacet)}`};
    }
    return {sort: undefined};
  };

  const parseKeywordParams = (keyword = '') => {
    if (boatConstants.SEARCH_URL_KEYWORD_PATTERN.test(keyword)) {
      const matches = boatConstants.SEARCH_URL_KEYWORD_PATTERN.exec(keyword);
      return {
        keyword: matches[1]
      };
    }
    return {keyword: ''};
  };

  const parseConditionParams = (condition = '') => {
    if (boatConstants.SEARCH_URL_CONDITION_PATTERN.test(condition)) {
      const matches = boatConstants.SEARCH_URL_CONDITION_PATTERN.exec(condition);
      return {
        condition: mappedValue(matches[1], messages.conditionFacet)
      };
    }
    return {condition: 'any'};
  };

  const parseYearParams = (year = '') => {
    if (boatConstants.SEARCH_URL_YEAR_PATTERN.test(year)) {
      const matches = boatConstants.SEARCH_URL_YEAR_PATTERN.exec(year);
      const ret = {year: {}};
      if (matches[1]) {
        ret.year.min = matches[1];
      }
      if (matches[2]) {
        ret.year.max = matches[2];
      }
      return ret;
    }
    return {};
  };

  const parseFuelParams = (fuelType = '') => {
    if (boatConstants.SEARCH_URL_FUEL_PATTERN.test(fuelType)) {
      const matches = boatConstants.SEARCH_URL_FUEL_PATTERN.exec(fuelType);
      return {
        fuel: mappedValue(matches[1].toLowerCase(), messages.fuelTypeFacet)
      };
    }
    return {fuel: ''};
  };

  const parsePriceParams = (price = '') => {
    if (boatConstants.SEARCH_URL_PRICE_PATTERN.test(price)) {
      const matches = boatConstants.SEARCH_URL_PRICE_PATTERN.exec(price);
      let value = {min: matches[1]};
      if (matches[2]) {
        value.max = matches[2];
      }
      return {
        price: value,
      };
    }
    return {};
  };

  const parseHorsePowerParams = (powerhp = '') => {
    if (boatConstants.SEARCH_URL_POWER_HP_PATTERN.test(powerhp)) {
      const matches = boatConstants.SEARCH_URL_POWER_HP_PATTERN.exec(powerhp);
      let value = {min: matches[1]};
      if (matches[2]) {
        value.max = matches[2];
      }
      return {
        powerhp: value,
      };
    }
    return {};
  };

  const parseModalParams = (modal = '') => {
    if (boatConstants.SEARCH_URL_MODAL_PATTERN.test(modal)) {
      const matches = boatConstants.SEARCH_URL_MODAL_PATTERN.exec(modal);
      let modals = matches[1].split('+');
      return {
        modal: modals
      };
    }
  };

  const parseByParams = (by = '') => {
    if (boatConstants.SEARCH_URL_BY_PATTERN.test(by)) {
      const matches = boatConstants.SEARCH_URL_BY_PATTERN.exec(by);
      return {
        forSale: mappedValue(matches[1], messages.forSaleFacet)
      };
    }
  };

  const parseOwnerParams = (owner = '') => {
    if (boatConstants.BRANDED_SEARCH_URL_OWNER_PATTERN.test(owner)) {
      return {
        owner
      };
    }
    return {};
  };

  return {
    parseSubdivisionParams,
    parseRegionParams,
    parseCountryParams,
    parseCityParams,
    parseLegacyMakeModelParams,
    parseMakeModelParams,
    parseCategoryParams,
    parseEnginePageParams,
    parseEngineSortParams,
    parseKeywordParams,
    parseConditionParams,
    parseYearParams,
    parseFuelParams,
    parsePriceParams,
    parseHorsePowerParams,
    parseModalParams,
    parseByParams,
    parseOwnerParams
  };
};

// Helper function to get the parser functions
export const getParserEngineCommonFunctions = (i18nService, config) => {
  // calls to getConfig will be updated.
  // istanbul ignore next
  if (!config) {
    // TODO: remove this call when needed
    config = getConfig();
  }
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const t = getFormatMessageFunction(i18nService);
  const mappedValue = getMappedValueFromI18n(i18nService);
  const {
    parseEnginePageParams, parseEngineSortParams
  } = parseEnginesAppUrlParams(boatConstants, config, t, mappedValue, i18nService);
  return {
    parseEnginePageParams,
    parseEngineSortParams
  };
};


