import get from 'lodash/get';
import { getConfig } from '../../config/portal';
import {getCurrentLocale} from '../language';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';

export const getFSBOUrl = (route = 'SELL_URL_ROOT') => {
  const currentLanguage = getCurrentLocale();
  const routeConstants = getRouteConstantsFromI18n();
  const config = getConfig();
  const overrideFSBODomain = get(
    config,
    `languages.${currentLanguage}.fsboUrl`,
    false
  );
  if (overrideFSBODomain) {
    return overrideFSBODomain;
  }
  const type = get(config, `languages.${currentLanguage}.type`, undefined);
  const tld = get(config, `languages.${currentLanguage}.tld`, undefined);
  let domain = get(config, 'host');
  domain = type === 'tld' ? domain.replace(/(\.\w{2,3}){1,2}$/, tld) : domain;
  return `${domain}${routeConstants[route]}`;
};
