import React from 'react';
import PropTypes from 'prop-types';

// export const CommonContext = React.createContext();
export const ServiceContext = React.createContext();

export const ServicesProvider = ({ children, tpp}) => {
  return (
    <ServiceContext.Provider value={tpp}>
      {children}
    </ServiceContext.Provider>
  );
};

ServicesProvider.propTypes = {
  children: PropTypes.node,
  userAgent: PropTypes.string,
  tpp: PropTypes.object
};
