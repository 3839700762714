import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { Cookies } from 'react-cookie';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import { setGenericEvent } from '../../store/actions/dataLayer';
import { PortalConfigContext } from '../../config/portal';
import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

const EVENT_CATEGORY = 'e_gdprCookieTracking';

const CookiesEvent = {
  DISPLAYED: 'displayed',
  OPENED: 'opened',
  ACCEPTED: 'accepted',
  DENIED: 'denied',
  CLOSED: 'closed'
};

const CookiesMessage = ({
  intl,
  readMoreState,
  updateCookies,
  linkOnClick,
  toggleReadMore
}) => {
  const t = intl.formatMessage;
  const messages = getMessages();
  const acceptOnClick = () => {
    updateCookies(true, CookiesEvent.ACCEPTED);
  };

  const denyOnClick = () => {
    updateCookies(false, CookiesEvent.DENIED);
  };

  const closeOnClick = () => {
    updateCookies(false, CookiesEvent.CLOSED);
  };
  return (
    <div className={classnames('cookie-message', 'show')}>
      <button
        type="button"
        aria-label="close"
        className="cookie-close"
        id="cookie-close"
        onClick={() => closeOnClick()}
      ></button>
      <div className="cookie-message-container">
        <div className="cookie-message-text-wrapper">
          <div className="cookie-message-text">
            {t(messages.cookiesDisclaimer.disclaimer.p1)}
          </div>
          <div className="cookie-message-text">
            {t(messages.cookiesDisclaimer.disclaimer.p2)}
          </div>
          <div
            className={classnames(
              'cookie-message-text read-more-target',
              readMoreState ? 'show' : ''
            )}
          >
            {t(messages.cookiesDisclaimer.disclaimer.p3)}
          </div>
          <div
            className={classnames(
              'cookie-message-text read-more-target',
              readMoreState ? 'show' : ''
            )}
          >
            {t(messages.cookiesDisclaimer.disclaimer.p4)}
            <a
              onClick={() => linkOnClick()}
              className="cookie-message-link"
              href={t(get(messages, 'footer.cookiesPolicy.url'))}
              target="_blank"
              rel="noopener noreferrer"
              id="cookies-policy"
            >
              {t(messages.cookiesDisclaimer.disclaimer.policy)}
            </a>
            {t(messages.cookiesDisclaimer.disclaimer.p5)}
          </div>
          <div
            className={classnames(
              'cookie-message-text read-more-target',
              readMoreState ? 'show' : ''
            )}
          >
            {t(messages.cookiesDisclaimer.disclaimer.p6)}
          </div>
          <div
            className={classnames(
              'cookie-message-text read-more-target',
              readMoreState ? 'show' : ''
            )}
          >
            {t(messages.cookiesDisclaimer.disclaimer.p7)}
          </div>
        </div>
        <label
          onClick={() => toggleReadMore()}
          id="read-more-trigger"
          className={classnames(
            'read-more-trigger',
            readMoreState ? 'less' : ''
          )}
        >
          {readMoreState ? t(messages.showLess) : t(messages.showMore)}
        </label>
        <div className="cookie-message-button-wrapper">
          <button
            onClick={() => denyOnClick()}
            className="cookie-message-button"
            id="cookies-off"
          >
            {t(messages.cookiesDisclaimer.deny)}
          </button>
          <button
            onClick={() => acceptOnClick()}
            className="cookie-message-button"
            id="cookies-on"
          >
            {t(messages.cookiesDisclaimer.accept)}
          </button>
        </div>
      </div>
    </div>
  );
};

CookiesMessage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  readMoreState: PropTypes.bool,
  updateCookies: PropTypes.func,
  linkOnClick: PropTypes.func,
  toggleReadMore: PropTypes.func
};

const CookiesDisclaimer = ({ intl, cookies, setGenericEvent }) => {
  const [showCookies, setShowCookies] = useState(false);
  const [readMoreState, setReadMoreState] = useState(false);
  const context = useContext(PortalConfigContext);
  const supportsCmp = context?.supports?.cmp || false;
  const portal = context.name;

  useEffect(() => {
    const cookieControl = get(cookies, 'cookies.cookieControl');
    const gdpr = get(cookies, 'cookies.gdpr');
    const supportsDisclaimer = get(
      context,
      'supports.cookiesDisclaimer',
      false
    );
    if (supportsDisclaimer && !cookieControl && (!gdpr || gdpr === 'true')) {
      setGenericEvent(EVENT_CATEGORY, 'displayed', portal);
      setShowCookies(true);
    }
  }, [cookies]);

  const linkOnClick = () => {
    setGenericEvent(EVENT_CATEGORY, CookiesEvent.OPENED, portal);
  };

  const updateCookies = (ok, genericEvent) => {
    let tillTheEndOfTime = new Date();
    tillTheEndOfTime.setDate(tillTheEndOfTime.getDate() + 99999);
    const data = {
      value: 'true',
      expires: tillTheEndOfTime,
      path: '/'
    };
    const cookieControl = ok ? 'true' : 'false';
    cookies.set('cookieControl', cookieControl, data);
    setGenericEvent(EVENT_CATEGORY, genericEvent, portal);
    setShowCookies(false);
  };

  const toggleReadMore = () => {
    setReadMoreState(!readMoreState);
  };

  const cookiesMessageProps = {
    intl,
    readMoreState,
    updateCookies,
    linkOnClick,
    toggleReadMore
  };
  return showCookies && !supportsCmp ? (
    <CookiesMessage {...cookiesMessageProps} />
  ) : null;
};

CookiesDisclaimer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  cookies: PropTypes.instanceOf(Cookies),
  setGenericEvent: PropTypes.func
};

export default connect(undefined, (dispatch) =>
  bindActionCreators({ setGenericEvent }, dispatch)
)(injectIntl(CookiesDisclaimer));
