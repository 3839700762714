import flatten from 'lodash/flatten';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';

import { getDefaultParams } from './getDefaultParams';
import { generateBrandedOemSearchPath } from './generateBrandedOemSearchPath';
import {
  getFormattedMakeForFacet,
  hyphenateUrlComponents
} from '../boats';
import { translateMake } from '../../commonHelper';
import { toggleSubFacet } from '../../multiFacetHelper';
import { getLengthSymbol } from '../../uomHelper';
import {getRouteConstantsFromI18n} from '../../../tppServices/translations/constants';
import {unslugify} from '../shared';
import { syncTranslationElements } from '../../../tppServices/translations/intlManager';

const formatMake = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  facetValue,
  formattedMake,
  seoMakeInfo
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const newValueSlug = omit(selectedValues, facetValue);
  const routeConstants = getRouteConstantsFromI18n();
  let formattedValue;
  if (!isEmpty(seoMakeInfo) && seoMakeInfo.make === facetValue) {
    formattedValue = `${t(messages.all)} ${seoMakeInfo.seoMakeName}`;
  } else {
    formattedValue = `${t(messages.all)} ${translateMake(
      formattedMake || unslugify(facetValue)
    )}`;
  }
  const isFormatted = !!formattedMake;
  const indexKey = `${facetKey}.${facetValue}`;
  const href = routeConstants.SEARCH_URL_ROOT;

  return {
    ...facetMeta,
    href,
    key: facetKey,
    indexKey,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

// returns the params to be shown in the pill boxes
export const getFormattedParams = (
  activeFacets,
  makeModel = [],
  customUom,
  seoMakeInfo = {}
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const facetMetaMap = {
    length: {
      suffix: getLengthSymbol(customUom?.length),
      format: 'number'
    }
  };
  const { brand } = activeFacets;
  const active = { ...activeFacets };
  delete active.brand;

  const defaultParams = getDefaultParams({});
  const formattedParams = Object.entries(active).map(
    ([facetKey, selectedValues]) => {
      const facetMeta = {
        ...facetMetaMap[facetKey],
        formattedKey: t(messages[facetKey])
      };
      const noFacet = omit(active, facetKey);
      switch (facetKey) {
        case 'makeModel':
          // typeof selectedValues = { type: [class] } or { make: [model] }
          return flatten(
            Object.entries(selectedValues).map(([facetValue, subFacets]) => {
              // Case makeModel: facetValue is a Make
              const formattedMake = getFormattedMakeForFacet(
                facetValue,
                active.makeModel,
                makeModel
              );
              if (subFacets && subFacets.length) {
                return subFacets.map((subFacetValue) =>
                  formatModelClass(
                    facetKey,
                    selectedValues,
                    noFacet,
                    facetMeta,
                    defaultParams,
                    facetValue,
                    subFacetValue,
                    formattedMake,
                    makeModel,
                    brand
                  )
                );
              }
              // Case makeModel: There is always subfacets for type/class
              return formatMake(
                facetKey,
                selectedValues,
                noFacet,
                facetMeta,
                defaultParams,
                facetValue,
                formattedMake,
                seoMakeInfo
              );
            })
          );
        default:
          return formatDefaultFacets(
            facetKey,
            selectedValues,
            noFacet,
            facetMeta,
            defaultParams,
            brand
          );
      }
    }
  );
  return flatten(formattedParams);
};

/**
 * @param facetKey { "makeModel" | "multiFacetedBoatTypeClass" }
 * @param selectedValues {{[string]: [string]}} - Facet values map. Type => [Class] or Make => [Model]
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @param facetValue {string} - Selected Make or Type slug
 * @param subFacetValue {string} - Selected Model or Class slug
 * @param formattedMake {string} - Formatted Make name
 * @param makeModel {[{value: string, count: string, model: [{value: string, count: string}]}]} - Available makes and model `value` is the make's formatted name
 * @returns {object}
 */
// eslint-disable-next-line max-len
const formatModelClass = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  facetValue,
  subFacetValue,
  formattedMake,
  makeModel,
  brand
) => {
  const newValueSlug = {
    ...omit(selectedValues, facetValue),
    ...toggleSubFacet(facetValue, subFacetValue, selectedValues)
  };

  const makeObj = makeModel.find(
    (e) => hyphenateUrlComponents(e.value) === facetValue
  ) || { value: unslugify(facetValue), model: [] };
  const modelObj = makeObj.model.find(
    (e) => hyphenateUrlComponents(e.value) === subFacetValue
  ) || { value: unslugify(subFacetValue) };
  const formattedValue = `${formattedMake || makeObj.value} - ${
    modelObj.value
  }`;
  const isFormatted = !!formattedMake;

  const indexKey = `${facetKey}.${facetValue}.${subFacetValue}`;
  const href = generateBrandedOemSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    { ...defaultParams, brand },
    true
  );

  return {
    ...facetMeta,
    href,
    key: facetKey,
    indexKey,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

/**
 * @param facetKey {string} - condition, length, radius, year, postalCode, etc
 * @param selectedValues {string | { max: string, min: string }} - Facet value
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ prefix?: string, suffix?: string, format?: string, formattedKey: string }}
 * @param defaultParams {object} - Map of facets with default values
 * @returns {object}
 */
const formatDefaultFacets = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  brand
) => {
  const newValueSlug = defaultParams[facetKey];
  const { formatMessage: t, messages } = syncTranslationElements();
  let isFormatted = false;
  let { prefix = '', suffix = '' } = facetMeta;
  if (isObject(selectedValues)) {
    // Case length, year or price
    if (selectedValues.min && !selectedValues.max) {
      suffix = '+ ' + suffix;
    } else if (!selectedValues.min && selectedValues.max) {
      prefix = `${t(messages.upTo)} ${prefix}`;
    } else {
      suffix = ' ' + suffix;
    }
  }

  let formattedValue = selectedValues;
  if (facetKey === 'radius' && has(messages, selectedValues)) {
    formattedValue = t(messages[selectedValues]);
    isFormatted = true;
  }
  if (facetKey === 'sort') {
    const [sortBy, sortOrder] = selectedValues.split(':');
    formattedValue = `${t(messages[sortBy])}:${t(
      messages.sortFacetValues[sortOrder]
    )}`;
    isFormatted = true;
  }
  if (facetKey === 'length') {
    isFormatted = true;
  }

  const href = generateBrandedOemSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    { ...defaultParams, brand },
    true
  );
  return {
    ...facetMeta,
    href,
    key: facetKey,
    value: formattedValue,
    newValue: newValueSlug,
    prefix,
    suffix,
    isFormatted
  };
};
