import { getLegacyMappingFromBoatClass } from './classHelper';
import { unhyphenateUrlComponents } from './commonHelper';

import get from 'lodash/get';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import replace from 'lodash/replace';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import join from 'lodash/join';
import uniq from 'lodash/uniq';


import { floorLength } from './listingHelper';
import { getCurrency, getLength } from './uomHelper';
import { getConfig } from '../config/portal';
import { parseCategoryParams } from './urlHelpers/blog';
import { getBoatClassMappingForDataLayer } from './classHelper';
import {unslugify} from './urlHelpers/shared';

const getParamPageDefaults = (country, portalName) => {
  const pageParamDefaults = {
    description: 'website',
    // country: getConfig().country,
    // portalName: getConfig().fullName,
    country,
    portalName,
    section: '',
    subSection: '',
    pageName: '',
    detailedPageName: ''
  };
  return pageParamDefaults;
};

export const searchFilterHeader = {
  postalCode: 'Location',
  postalCodeRange: 'Location',
  condition: 'Condition',
  make: 'Make',
  model: 'Model',
  type: 'Type',
  class: 'Class',
  yearLow: 'Year',
  yearHigh: 'Year',
  lengthLow: 'Length',
  lengthHigh: 'Length',
  priceLow: 'Price',
  priceHigh: 'Price',
  localArea: 'Location',
  country: 'Location',
  city: 'Location',
  region: 'Location',
  engine: 'Engine Type',
  fuel: 'Fuel Type',
  hull: 'Hull Type',
  // eslint-disable-next-line camelcase
  seller_type: 'Seller Type'
};

export const getPageParams = (pageType, pageData, country, fullName) => {
  const blogCategory = parseCategoryParams(get(pageData, 'category', '')).category;
  const blogArticle = get(pageData, 'article', '');
  const pageParamDefaults = getParamPageDefaults(country, fullName);
  switch (pageType){
  case 'SearchResults':
    return {
      ...pageParamDefaults,
      section: 'search',
      subSection: 'listing search',
      pageName: 'listing search results',
      detailedPageName: 'listing search results'
    };
  case 'BrandedOEMSearchResults':
    return {
      ...pageParamDefaults,
      section: 'manufacturer listings',
      subSection: 'listing search',
      pageName: 'listing search results',
      detailedPageName: 'listing search results',
    };
  case 'Services':
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'resources',
      pageName: 'boat resources',
      detailedPageName: 'boat resources'
    };
  case 'BoatDetails':
    return {
      ...pageParamDefaults,
      section: 'listings',
      subSection: 'boat listings',
      pageName: 'boat details',
      detailedPageName: 'boat details'
    };
  case 'BoatDetailsOEM':
    return {
      ...pageParamDefaults,
      section: 'manufacturer listings',
      subSection: 'boat listings',
      pageName: 'boat details',
      detailedPageName: 'boat details'
    };
  case 'PartySearch':
    return {
      ...pageParamDefaults,
      section: 'search',
      subSection: 'professional search',
      pageName: '/professional search',
      detailedPageName: 'professional search'
    };
  case 'Home':
    return {
      ...pageParamDefaults,
      section: 'home',
      subSection: 'home',
      pageName: '',
      detailedPageName: 'home'
    };
  case 'BrandedSearch':
    return {
      ...pageParamDefaults,
      section: 'search',
      subSection: 'branded search',
      pageName: 'branded search results',
      detailedPageName: pageData.ownerId.toString()
    };
  case 'EditorialPage':
    return {
      ...pageParamDefaults,
      section: 'content',
      subSection: 'editorial features',
      pageName: 'articles',
      detailedPageName: blogArticle ? blogArticle : `articles - ${blogCategory || 'welcome'}`
    };
  case 'EngineDetails':
    return {
      ...pageParamDefaults,
      section: 'listings',
      subSection: 'part-accessory listings',
      pageName: 'engine details',
      detailedPageName: 'engine listing'
    };
  case 'SocialBrowsePage':
    return {
      ...pageParamDefaults,
      section: 'listings',
      subSection: 'boat listings',
      pageName: 'social browse',
      detailedPageName: 'social browse'
    };
  case 'TypePage':
    return {
      ...pageParamDefaults,
      section: 'types',
      subSection: 'boat types',
      pageName: 'boat types',
      detailedPageName: 'boat types'
    };
    case 'ClassPage':
      return {
        ...pageParamDefaults,
        section: 'classes',
        subSection: 'boat class',
        pageName: 'boat class',
        detailedPageName: 'boat class'
      };
  default:
    return pageParamDefaults;
  }
};

export const getConnectionClickData = (imtID, leadType) => {

  const ConnectionClickDefaults = {
    connectionType: leadType,
    connectionCategory: '',
    connectionTarget: '',
    connectionSubjectId: imtID
  };

  switch (leadType) {
  case 'facebook share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'facebook'
    };
  case 'pinterest share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'pinterest'
    };
  case 'whatsapp share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'whatsapp'
    };
  case 'twitter share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'twitter'
    };
  case 'youtube share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'youtube'
    };
  case 'instagram share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'instagram'
    };
  case 'email share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'email'
    };
  case 'google_play share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'Google Play'
    };
  case 'app_store share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'App store'
    };
  case 'print listing':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'soft lead',
      connectionTarget: 'self'
    };
  case 'email listing':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionTarget: 'self'
    };
  case 'phone lead':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'lead',
      connectionTarget: 'seller'
    };
  case 'email lead':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'lead',
      connectionTarget: 'seller'
    };
  case 'save listing':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'soft lead',
      connectionTarget: 'self'
    };
  case 'view all inventory':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'soft lead',
      connectionTarget: 'self'
    };
  case 'view pro phone':
    return {
      ...ConnectionClickDefaults,
      connectionType: 'contact professional',
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  case 'pro contact dealer':
    return {
      ...ConnectionClickDefaults,
      connectionType: 'contact professional',
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  case 'pro send email':
    return {
      ...ConnectionClickDefaults,
      connectionType: 'contact professional',
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  case 'pro get directions':
    return {
      ...ConnectionClickDefaults,
      connectionType: 'map view',
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  case 'website referral':
    return {
      ...ConnectionClickDefaults,
      connectionType: 'website referral',
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  default:
    return ConnectionClickDefaults;
  }
};

const getKeysFromObject = (obj) => {
  let keys = [];
  map(obj, (value, key) => { keys.push(unhyphenateUrlComponents(unslugify(replace(key,/\+/g, ' ')))); });
  return keys.length > 0 ? join(keys, ',') : undefined;
};

const getValuesFromObject = (obj ) => {
  let values = [];
  map(obj, (value) => {
    let vals = [];
    map(value, (val) =>  {
      values.push(unhyphenateUrlComponents(unslugify(replace(getLegacyMappingFromBoatClass(val),/\+/g, ' '))));
    });
    return join(vals, ',');
  });
  return values.length > 0 ? join(values, ',') : undefined;
};

const getRawValuesFromObject = (obj ) => {
  let values = [];
  map(obj, (value) => {
    let vals = [];
    map(value, (val) =>  {
      values.push(getLegacyMappingFromBoatClass(val));
    });
    return join(vals, ',');
  });
  return values.length > 0 ? join(values, ',') : undefined;
};

export const getSearchLabel = (obj) => {
  obj = omit(obj, ['currency', 'uom']);
  let labels = [];
  map(obj,(param, key) => {
    labels.push(searchFilterHeader[key].toLowerCase());
  });
  labels = uniq(labels);
  return join(labels, ',');
};

const joinArray = (arr, separator) => {
  let values = '';
  if (arr && arr.length > 0) {
    arr = map(arr, (val) => { return unhyphenateUrlComponents(unslugify(replace(val,/\+/g, ' '))); });
    values = arr.join(separator);
  }
  return values.length > 0 ? values : undefined;
};

const getCondition = params => {
  const condition = get(params, 'condition');
  return condition === 'any' ? 'all' : condition;
};

export const setHomeSearchData = (params) => {
  const trackData = {
    'boat_type': getKeysFromObject(get(params, 'multiFacetedBoatTypeClass')),
    'class': getValuesFromObject(get(params, 'multiFacetedBoatTypeClass')),
    'make': getKeysFromObject(get(params, 'makeModel')),
    'priceHigh': get(params, 'price.max'),
    'yearLow': get(params, 'year.min'),
    'lengthHigh': get(params, 'length.max'),
    'lengthLow': get(params, 'length.min'),
    'region': get(params, 'region'),
    'condition': get(params, 'condition'),
  };
  return omitBy(trackData, isNil);
};

export const setProLeadData = (params) => {
  const trackData = {
    uniqueId: params.id,
    product: [
      {
        sellerID: params.partyId,
        productInfo: {
          productID: params.partyId
        }
      }
    ]
  };

  return omitBy(trackData, isNil);
};

export const setSearchData = (params, searchType) => {
  const trackData = {
    'postalCode': get(params, 'postalCode'),
    'postalCodeRange': get(params, 'radius'),
    'condition': getCondition(params),
    'make': getKeysFromObject(get(params, 'makeModel')),
    'model': getValuesFromObject(get(params, 'makeModel')),
    'type': getKeysFromObject(get(params, 'multiFacetedBoatTypeClass')),
    'class': getBoatClassMappingForDataLayer(getRawValuesFromObject(get(params, 'multiFacetedBoatTypeClass'))),
    'yearLow': get(params, 'year.min'),
    'yearHigh': get(params, 'year.max'),
    'lengthLow': get(params, 'length.min'),
    'lengthHigh': get(params, 'length.max'),
    'priceLow': get(params, 'price.min'),
    'priceHigh': get(params, 'price.max'),
    'localArea': get(params, 'subdivision') ? `${getConfig().country}-${params.subdivision}` : undefined,
    'country': get(params, 'country'),
    'city': get(params, 'city'),
    'region': get(params, 'region'),
    'engine': get(params, 'engine') !== '' ? get(params, 'engine') : undefined,
    'fuel': joinArray(get(params, 'fuelType'), ','),
    'hull': joinArray(get(params, 'hullMaterial'), ','),
    'currency': get(params, 'price.min') || get(params, 'price.max')  ? getCurrency().toLowerCase() : undefined,
    'uom': get(params, 'length.min') || get(params, 'length.max')  ? getLength() : undefined,
    'seller_type': get(params, 'forSale')
  };

  if (searchType === 'single') {
    trackData.make = trackData.model ? undefined : trackData.make;
    trackData.type = trackData.class ? undefined : trackData.type;
  }

  return omitBy(trackData, isNil);
};

export const buildProduct = (listing, category) => {
  // NOTE - not using config UOM for GA tracking for consistancy.
  let product = {
    imtID: listing.id.toString(),
    condition: listing.condition,
    manufacturer: listing.make,
    length: String(floorLength(get(listing, 'specifications.dimensions.lengths.nominal.ft'), '')),
    state: get(listing, 'location.address.subdivision'),
    category: category,
    city: get(listing, 'location.address.city'),
    country: get(listing, 'location.address.country'),
    listedPrice: !listing.price.hidden ? String(get(listing, 'price.type.amount.USD', '')) : 'Request a Price',
    model: listing.model,
    yearBuilt: String(listing.year),
    productType: listing.type,
    sellerID: String(get(listing, 'owner.id')),
    productClass: get(listing, 'class'),
    horsePower: get(listing, 'power.hp') ? String(get(listing, 'power.hp')) : null
  };

  return omitBy(product, isNil);
};
