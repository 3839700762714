import {definedValue} from './index';
import {readListingFromDom} from './parsers/listingParsers';
export const THREE_COL_FEATURED_BOATS = 'featured-boats';
export const THREE_COL_LISTINGS_CONTAINER = '3-col-listings-container';
export const FUZZY_SPONSORED_CAROUSEL = 'sponsored-listings-carousel';
export const THREE_COL_LISTINGS_SRP = 'three-col-listings';
export const SSR_METADATA = 'data-ssr-meta';
const metaSelector = `[${SSR_METADATA}]`;

const getAllAttributes = (elementAttr) => {
  const attributes = {};
  for (const attr of Array.from(elementAttr)) {
    attributes[attr.name] = attr.value;
  }
  return attributes;
};

const yearMakeModelFromName = (name, make) => {
  const i = name.indexOf(' ');
  if (i === -1) {
    return {year: '', make, model: ''};
  }
  const year = +name.substring(0, i);
  const makeModel = definedValue(name.substring(i + 1), '');
  const model = makeModel.replace(make, '').trim();
  return {year, make, model};
};

const parseAttributes = (element) => {
  const attributes = getAllAttributes(element.attributes);
  const id = +definedValue(attributes?.['data-reporting-click-product-id'], '');
  const meta = definedValue(attributes?.[SSR_METADATA], '');
  const elementType = definedValue(attributes?.['data-reporting-click-listing-type'], '');
  const [make, boatClass, nominalLength, subdivision, priceAmount] = meta.split('|');
  return {id, make, boatClass, nominalLength, subdivision, elementType, priceAmount, attributes};
};

const createParsedListing = (listingProps, childLink) => {
  const name = definedValue(listingProps.name, '');
  const {id, make: parsedMake, boatClass, nominalLength, subdivision, elementType, priceAmount, attributes} = parseAttributes(childLink);
  const {year, make, model} =  yearMakeModelFromName(name, parsedMake);
  return { ...listingProps, year, make, model, id, class: boatClass, boatClass, nominalLength, subdivision, elementType, priceAmount, attributes };
};

const readChildLink = (childLink) => {
  const listingProps = readListingFromDom(childLink);
  if (!listingProps) {
    return null;
  }
  return createParsedListing(listingProps, childLink);
};

const readBoats = (container) => {
  const domElements = container.querySelectorAll(metaSelector);
  let elements = [];
  for (let childLink of domElements) {
    const boatParsed = readChildLink(childLink);
    if (boatParsed) {
      boatParsed.ssrParsed = true;
      elements.push(boatParsed);
    }
  }
  return elements;
};

const getListingsFromContainer = (container) => {
  const testId = container.getAttribute('data-testid');
  let boats = [];
  boats = readBoats(container);
  return {testId, listings: boats};
};

export const parseThreeColListingsFromDom = (parsableSelector = THREE_COL_LISTINGS_SRP) => {
  const listingContainer = document.getElementById(parsableSelector);
  const containers = listingContainer.getElementsByClassName('container');
  const domListings = {};

  for (let listingContainer of containers) {
    const {testId, listings} = getListingsFromContainer(listingContainer);
    if (testId in domListings) {
      domListings[testId].push(...listings);
    } else {
      domListings[testId] = listings;
    }
  }
  return domListings;
};
