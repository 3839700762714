export const TRACKING_EVENTS = {
  SEARCH: 'e_search',
  KEYWORD_SEARCH: 'e_keywordSearch',
  REVEAL_PHONE_NUMBER: 'e_revealNumber',
  DIAL_PHONE_NUMBER: 'e_telephoneClick',
};

export const PRODUCT_CATEGORY = {
  BOAT: 'boat',
  ENGINE: 'engine',
  NAVIGATION_BODY: 'navigation - body',
  PREMIUM_BROKER: 'premium broker banner'
};

export const TRACKING_EVENT_LABEL = {
  PAGINATION: {
    next: 'next page',
    previous: 'previous page'
  }
};

export const VIDEO_TOUR_OPEN_EVENT = 'video tour open';
