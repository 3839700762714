import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import * as actions from './store/actions';
import ErrorBoundary from './components/ErrorBoundary';
import Routes from './routes';

import './Defaults.css';
import './App.css';

const App = (props) => {
  return (
    <ErrorBoundary>
      <Routes {...props} />
    </ErrorBoundary>
  );
};

export default withRouter(connect(
  state => state,
  dispatch => bindActionCreators({
    ...actions,
  }, dispatch),
)(App));
