import React from 'react';
import PropTypes from 'prop-types';
import ReactPannellum from 'react-pannellum';

const Image360 = ({ media, id, style, draggable = true, mouseZoom = true }) => {
  const defaultStyle = {
    width: '100%'
  };

  return (
    <ReactPannellum
      id={id}
      sceneId={id}
      imageSource={media.url.replace('LARGE', 'XLARGE')}
      style={style ? style : defaultStyle}
      config={{ autoLoad: true, draggable, mouseZoom, showFullscreenCtrl: false }}
    />
  );
};

export default Image360;

Image360.propTypes = {
  media: PropTypes.object,
  id: PropTypes.string,
  style: PropTypes.object,
  draggable: PropTypes.bool,
  mouseZoom: PropTypes.bool
};
