export const DATALAYER_INIT = 'DATALAYER_INIT';
export const DATALAYER_FAILURE = 'DATALAYER_FAILURE';
export const DATALAYER_EVENT = 'DATALAYER_EVENT';
export const DATALAYER_ECOMMERCE = 'DATALAYER_ECOMMERCE';
export const DATALAYER_PRO_LEAD = 'DATALAYER_PRO_LEAD';
export const DATALAYER_PRODUCT_IMPRESSION = 'DATALAYER_PRODUCT_IMPRESSION';
export const DATALAYER_PRODUCT_ADD = 'DATALAYER_PRODUCT_ADD';
export const DATALAYER_PRODUCT_CLICK = 'DATALAYER_PRODUCT_CLICK';
export const DATALAYER_ADD_LEAD = 'DATALAYER_ADD_LEAD';
export const DATALAYER_SEARCH = 'DATALAYER_SEARCH';
export const DATALAYER_SEARCH_FILTER = 'DATALAYER_SEARCH_FILTER';
export const DATALAYER_GENERIC_EVENT = 'DATALAYER_GENERIC_EVENT';
export const DATALAYER_PREMIUM_PLACEMENT_IMPRESSION = 'DATALAYER_PREMIUM_PLACEMENT_IMPRESSION';
export const DATALAYER_PREMIUM_PLACEMENT_CLICK = 'DATALAYER_PREMIUM_PLACEMENT_CLICK';
export const DATALAYER_COLLAPSE_TRACKING = 'DATALAYER_COLLAPSE_TRACKING';
export const DATALAYER_CONTACTFORM_SUBMIT = 'DATALAYER_CONTACTFORM_SUBMIT';
export const DATALAYER_CONTACTFORM_OPEN = 'DATALAYER_CONTACTFORM_OPEN';
export const DATALAYER_PHONE_CALL = 'DATALAYER_PHONE_CALL';
export const DATALAYER_PHONE_REVEAL = 'DATALAYER_PHONE_REVEAL';
export const DATALAYER_TELEPHONE_CLICK = 'DATALAYER_TELEPHONE_CLICK';
export const DATALAYER_CAROUSEL_CHANGE_ITEM = 'DATALAYER_CAROUSEL_CHANGE_ITEM';
export const DATALAYER_CAROUSEL_THUMBNAIL_CLICK = 'DATALAYER_CAROUSEL_THUMBNAIL_CLICK';
export const DATALAYER_CAROUSEL_MODAL_OPEN = 'DATALAYER_CAROUSEL_MODAL_OPEN';
export const DATALAYER_EXIT_LINK_IMPRESSION = 'DATALAYER_EXIT_LINK_IMPRESSION';
export const DATALAYER_EXTERNAL_LINK_IMPRESSION = 'DATALAYER_EXTERNAL_LINK_IMPRESSION';
export const DATALAYER_SOCIAL_MEDIA = 'DATALAYER_SOCIAL_MEDIA';
export const DATALAYER_CUSTOM_VARIABLE = 'DATALAYER_CUSTOM_VARIABLE';
export const DATALAYER_SOFT_LEAD_EVENT = 'DATALAYER_SOFT_LEAD_EVENT';
export const DATALAYER_LISTING_VIEW = 'DATALAYER_LISTING_VIEW';
export const DATALAYER_CONNECTION_CLICK = 'DATALAYER_CONNECTION_CLICK';
export const DATALAYER_CLEAR_ECOMMERCE = 'DATALAYER_CLEAR_ECOMMERCE';
export const DATALAYER_SEARCH_COUNT = 'DATALAYER_SEARCH_COUNT';
export const DATALAYER_NAVIGATION_CLICK = 'DATALAYER_NAVIGATION_CLICK';
