import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';

import { capitalizeEachWord } from '@dmm/lib-common/lib/formatting';
import { capitalizeString, getFacetTranslation, translateMake } from '../../../../utils/commonHelper';
import {
  getActiveParams,
  getDefaultParams,
  generateSearchPath,
  hyphenateUrlComponents,
  unhyphenateUrlComponents,
  parseSearchEngineAppParams
} from '../../../../utils/urlHelpers/engines';
import {
  getPortalSeparator
} from '../../../../utils/language';
import { urlFromLanguage } from '../../../../utils/seo';

import { getPartyBrandedSRP } from '../../../../utils/urlHelpers/party';
import { isSingleFaceted } from '../../../../utils/multiFacetHelper';
import {getBoatConstantsFromI18n} from '../../../../tppServices/translations/constants';
import { syncTranslationElements } from '../../../../tppServices/translations/intlManager';

export const getCanonical = (params, isBranded = false, partyDetails = {}, i18nService) => {
  let canonicalUrlParams = {};
  const defaultParams = getDefaultParams(params);
  const activeParams = getActiveParams(defaultParams);
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const activeCanonicalParamsCount = Object.keys(activeParams)
    .filter(key => !boatConstants.NON_CANONICAL_PARAMS.includes(key)).length;
  if (isBranded) {
    return urlFromLanguage(getPartyBrandedSRP(partyDetails, false, null));
  }
  if (activeParams.makeModel) {
    canonicalUrlParams.makeModel = activeParams.makeModel;
  }
  let locationParams = {
    region: activeParams.region,
    subdivision: activeParams.subdivision,
    country: activeParams.country,
    city: activeParams.city
  };
  locationParams = omitBy(locationParams, isNil);
  if (!isEmpty(locationParams)) {
    canonicalUrlParams = { ...canonicalUrlParams, ...locationParams };
  }
  if (activeParams.condition) {
    if (activeCanonicalParamsCount === 1) {
      canonicalUrlParams = { condition: activeParams.condition };
    } else if (activeCanonicalParamsCount === 2 && activeParams.category) {
      canonicalUrlParams = {
        condition: activeParams.condition,
        category: activeParams.category
      };
    }
  }
  if (activeParams.category) {
    canonicalUrlParams.category = activeParams.category;
  }
  if (activeParams.fuel) {
    canonicalUrlParams.fuel = activeParams.fuel;
  }
  if (canonicalUrlParams.category && canonicalUrlParams.fuel) {
    if (!isEmpty(canonicalUrlParams.makeModel)) {
      delete canonicalUrlParams.category;
      delete canonicalUrlParams.fuel;
    }
  }
  if (canonicalUrlParams.category && canonicalUrlParams.makeModel && !isEmpty(locationParams)) {
    if (canonicalUrlParams.makeModel[Object.keys(canonicalUrlParams.makeModel)[0]].length === 0) {
      delete canonicalUrlParams.category;
    }
  }
  if (canonicalUrlParams.fuel) {
    if (!canonicalUrlParams.category && !isEmpty(canonicalUrlParams.makeModel) && !isEmpty(locationParams)) {
      delete canonicalUrlParams.fuel;
    }
  }
  if (activeParams.page) {
    canonicalUrlParams = { ...canonicalUrlParams, page: activeParams.page };
  }
  if (activeParams.owner) {
    canonicalUrlParams = { ...canonicalUrlParams, owner: activeParams.owner };
  }
  return urlFromLanguage(generateSearchPath(canonicalUrlParams, getDefaultParams({}), false));
};

export const getH1 = (params, portal, isBranded = false, partyDetails = {}) => {
  let h1Translation;

  if (isBranded) {
    h1Translation = partyDetails.displayName;
    return h1Translation;
  }
  h1Translation = getSEOElementTranslation(params, portal, 'title');
  return `${h1Translation}`;
};

export const getMetaDescription = (
  params,
  maxPages,
  portal,
  isBranded = false,
  partyDetails = {},
  countListings = 0
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let descriptionTranslation, paginationTranslation;
  const numListings = countListings > 10 ? countListings : '';
  if (isBranded) {
    const translationParameters = {
      dealerName: partyDetails.name,
      dealerCity: get(partyDetails, 'address.city', ''),
      portal,
    };
    descriptionTranslation = t(messages.brandedSearch.SEO.metaElements.description,
      { ...translationParameters } );
    paginationTranslation = getPaginationTranslation(params, maxPages, 'description');
    return paginationTranslation ? `${paginationTranslation} | ${descriptionTranslation}` : descriptionTranslation;
  }
  descriptionTranslation = getSEOElementTranslation(params, portal, 'description', numListings);
  paginationTranslation = getPaginationTranslation(params, maxPages, 'description');
  return paginationTranslation ? `${descriptionTranslation} ${paginationTranslation}` : descriptionTranslation;
};

export const getMetaTitle = (
  params,
  maxPages,
  portal,
  isBranded = false,
  partyDetails = {}
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  let paginationTranslation, titleTranslation;

  if (isBranded) {
    const translationParameters = {
      dealerName: partyDetails.name,
      dealerCity: get(partyDetails, 'address.city', ''),
      portal,
    };
    titleTranslation = `${t(messages.brandedSearch.SEO.metaElements.title,
      { ...translationParameters } )}`;
    paginationTranslation = getPaginationTranslation(params, maxPages, 'title');
    return paginationTranslation ?
      `${titleTranslation} - ${paginationTranslation}` : titleTranslation;
  }
  const separator = getPortalSeparator();
  titleTranslation = `${getSEOElementTranslation(params, portal, 'title')} ${separator} ${portal}`;
  paginationTranslation = getPaginationTranslation(params, maxPages, 'title');

  return paginationTranslation ? `${titleTranslation} | ${paginationTranslation}` : `${titleTranslation}`;
};

export const getNoFollow = (url, isBranded = false, config, i18nService) => {
  const activeParams = getActiveParams(parseSearchEngineAppParams(url, isBranded, config, i18nService));
  return !Object.keys(activeParams).includes('modal');
};

// we have to temporarily disable meta robots for urls shaped:
// https://<domain>/<boats-or-engines-base-basepath-translated>/<dealer-translated>-<dealer-name>-<dealer-ID>/
// we could use this function as a "wildcard" for all the urls that we want to disable
// TODO: update when the change of urls is finished

export const alternativeNoIndexEnginesUrl = (url) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const srp = t(messages.enginesSearchRoot);
  const dealerStr = t(messages.brandedSearchRoot);
  const regex =  new RegExp(`/${srp}/${dealerStr}-(.+)-(\\d+)`);
  return regex.test(url);
};

export const getNoIndex = (url, isBranded = false, i18nService, config) => {
  const activeParams = getActiveParams(parseSearchEngineAppParams(url, isBranded, config, i18nService));
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  if (alternativeNoIndexEnginesUrl(url)) {
    return true;
  }
  return Object.entries(activeParams).some(([paramKey, paramValue]) => {
    if (!includes(isBranded ? boatConstants.BRANDED_INDEXABLE_FACETS : boatConstants.ENGINES_INDEXABLE_FACETS, paramKey)) {
      return true;
    }
    if (includes(['makeModel', 'city'], paramKey) && !isEmpty(paramValue)) {
      return isBranded || !isSingleFaceted(paramValue);
    }
    return false;
  });
};

export const getPaginationTranslation = (params, maxPages, element) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const { page } = params;
  if (page && page !== '1') {
    const message = messages.searchPageSEO.pagination[element];
    return message ? t(messages.searchPageSEO.pagination[element], { page, totalPages: maxPages }) : undefined;
  }
  return '';
};

export const getSEOElementTranslation = (params, portal, element, numListings = '', i18nService) => {
  // Get all indexable facets values of the current search translated
  let {
    city,
    condition,
    country,
    engineType,
    fuelType,
    make,
    model,
    region,
    sellerType,
    subdivision
  } = params;
  const { formatMessage: t, messages } = syncTranslationElements(i18nService);
  make = translateMake(make);
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  condition = condition &&
    getFacetTranslation(`searchPageEnginesSEO.indexableFacets.condition[${condition}]`).toLowerCase();
  engineType = engineType &&
    getFacetTranslation(`searchPageEnginesSEO.indexableFacets.engineType[${engineType}]`).toLowerCase();
  fuelType = fuelType && getFacetTranslation(`searchPageEnginesSEO.indexableFacets.fuel[${fuelType}]`);
  region = region && getFacetTranslation(`countryRegions[${region}]`);
  subdivision = subdivision && getFacetTranslation(`countrySubdivision[${subdivision}]`);
  sellerType = sellerType && getFacetTranslation(`forSaleFacet[${sellerType}]`);
  country = country && getFacetTranslation(`countries[${country}]`);
  // All we can do here is capitalize the text as no translations were provided.
  city = city && city.length > 0 && capitalizeString(city[0]);

  // Get the complete key of the i18n template prioritizing as per the SEO SRP indexation docs (ADB-112)
  const indexableFacets = { condition, engineType, fuelType, make, model, region,
    subdivision, sellerType, country, city };
  let messageKey = 'searchPageEnginesSEO.metaElements';
  boatConstants.INDEXABLE_TRANSLATION_PARAMS_PRIORITY.forEach((facet) => {
    if (indexableFacets[facet] && get(messages, `${messageKey}.${facet}`)) {
      messageKey += `.${facet}`;
    }
  });

  // If the resulting key doesn't have a template, remove the last part until getting a valid combination of facets
  while (!get(messages, `${messageKey}.${element}`)) {
    if (messageKey.includes('.')) {
      messageKey = messageKey.slice(0, messageKey.lastIndexOf('.'));
    } else {
      // Couldn't find a message for that element and combination of facets
      return undefined;
    }
  }

  // Inject the parameters to the template and return the capitalized translation
  const translation = t(get(messages, `${messageKey}.${element}`), {
    ...indexableFacets,
    numListings,
    portal
  });
  return capitalizeString(translation).replace('  ', ' ');
};

export const getSEOParams = (params, _makeModel) => {
  let {
    category,
    city,
    condition,
    country,
    forSale,
    fuel,
    makeModel = {},
    page,
    region,
    subdivision
  } = getActiveParams(getDefaultParams(params));
  let _make = get(Object.keys(makeModel), '0', '');
  const _model = get(makeModel, `${_make}.0`, '');
  const selectedMakeModelFacet = _makeModel.find(e =>
    hyphenateUrlComponents(e.value) === _make
  );
  let makeObj = selectedMakeModelFacet ? selectedMakeModelFacet : { value: _make };
  const modelObj = unhyphenateUrlComponents(_model);

  const make = get(makeObj, 'value', '');
  const model = capitalizeEachWord(modelObj);

  return {
    condition, fuelType: fuel, engineType: category, make, model, region, subdivision, page,
    sellerType: forSale, country, city
  };
};
