import React from 'react';
import PropTypes from 'prop-types';
import {getTPPServices} from '../tppServices/tppDi';
import {getTPPContainerKey} from '../tppServices/crossEnvHelpers';

export const startConfigGetter = () => {
  let config = null;
  const noConfig = () => {
    throw new Error('No portal config found');
  };
  const findConfig = (containerName = getTPPContainerKey()) => {
    const {configService} = getTPPServices(containerName);
    config = configService?.getConfig();
    return config || noConfig();
  };
  const getConfig = (containerName) => {
    return config || findConfig(containerName);
  };
  return getConfig;
};

export const getConfig = startConfigGetter();

export const PortalConfigContext = React.createContext();

export const PortalConfigProvider = ({ children }) => {
  return <PortalConfigContext.Provider value={getConfig()}>
    {children}
  </PortalConfigContext.Provider>;
};

PortalConfigProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object
};

const PortalConfigConsumer = PortalConfigContext.Consumer;

export const withPortalConfigContext = (Component) => {
  const Wrapper = (props) => {
    return (
      <PortalConfigConsumer>
        {(context) => {
          return <Component configContext={context} {...props} />;
        }}
      </PortalConfigConsumer>
    );
  };

  return Wrapper;
};
