/**
 * This file contains the main functions to create and manage the TPP container.
 * It is a "kind of" dependency injection container that allows us to store the language service and the config service.
 * The tpp container is a singleton and it is memoized.
 * Theoretically we could add more services to the container, but we are not doing it now.
 * It can work inside and outside React.
 * It has a server side and client side wrapper of an event dispatcher to fire custom events.
 */
import {
  CONFIG_SERVICE,
  CONFIG_STARTED, getPortalName,
  getTPPContainerKey,
  LANGUAGE_SERVICE,
  memoTpp,
  TPP_STARTED,
  tppEventEmitter
} from './crossEnvHelpers';
import {createConfigService} from './configService';
import {createLanguageService} from './languageService';
import {nil} from '../utils/runOnceHelper';
import {normalizeString} from '@dmm/lib-common/lib/formatting';
import {redirectToNewLanguage, translateUrlByLangCode, translateUrlByLangCodeSync} from './translations/helpers';
import {temporaryI18nContainer} from './translations/intlManager';
import { startMessages } from './translations/messages';

/**
 * configContainer is a simple container that allows us to store tppContainer data.
 * @returns {{get: ((function(*): (null|*))|*), started: boolean, has: (function(*): boolean), register: register, empty: empty}}
 */
const configContainer = () => {
  let dependencies = {};
  let started = false;
  const register = (name, dependency) => {
    dependencies[name] = dependency;
  };

  const has = (name) => {
    return !!dependencies[name];
  };

  const get = (name) => {
    if (!has(name)){
      return null;
    }
    return dependencies[name];
  };

  const empty = () => {
    dependencies = {};
  };

  return { register, get, has, started, empty };
};

/**
 * Creates a tpp container object that will expose the language service and the config service.
 * Both services allow us to manage the configuration of the portal and the translations for each language.
 * @returns the tpp object with all the services and the event dispatcher.
 * @param containerName name of the container
 * @param startTimeout: time to wait for the language to load and service to start. If it does not start in this time, we throw an error.
 */
const createTPP = (containerName) => {
  const container = configContainer();
  const eventDispatcher = tppEventEmitter();
  const languageService = () => container.get(LANGUAGE_SERVICE);
  const configService = () => container.get(CONFIG_SERVICE);
  const getService = (name) => container.get(name);
  const register = (name, dependency) => container.register(name, dependency);
  // useful to debug
  const unsafeUUID = Math.random();
  /**
   * We need to start configService and languageService to make the portal usable
   * @param configService: to deal with the portal
   * @param pathname
   * @param host
   * @param defaultLangDict we have a global translationDictionary object to store the dictionaries.
   * We can override it and not use that.
   * @returns {Promise<boolean>}
   */
  const start = async (configService, pathname, host, defaultLangDict = true) => {
    eventDispatcher.dispatchEvent(TPP_STARTED, {name: 'tmp started'});
    if (container.started) {
      return true;
    }
    const languageService = await createLanguageService(configService, pathname, host, defaultLangDict);
    container.register(LANGUAGE_SERVICE, languageService);
    container.register(CONFIG_SERVICE, configService);
    const config = configService.getConfig();
    const startedMessages = await startMessages();
    // when config is async, use tppStarted to return a promise
    // and launch an event to let the app know it is ready
    if (config.name && languageService.getI18n()) {
      container.started = startedMessages;
      eventDispatcher.dispatchEvent(TPP_STARTED, {name: config.name, language: languageService.getLanguage(), origin: 'createTPP'});
      return true;
    }
    return false;
  };

  const empty = () => {
    const ls = languageService() || {empty: nil};
    const cfg = configService() || {empty: nil};
    // does not build using optional chaining!
    ls.empty();
    cfg.empty();
    container.empty();
    container.started = false;
  };

  const tpp = {
    /**
     * We expose the language service.
     */
    languageService,
    /**
     * Utility to dispatch events both server side and client side.
     */
    eventDispatcher,
    /**
     * Quick and dirty way to ensure we are working with one container.
     * Useful for debugging and testing
     */
    unsafeUUID,
    /**
     * We expose the config service.
     */
    configService,
    /**
     * We expose the container name.
     */
    containerName,
    /**
     * Clear all services and the container.
     */
    empty,
    /**
     * Initialize language and config services.
     */
    start,
    /**
     * Register a service in the container.
     */
    register,
    /**
     * Get a service from the container.
     */
    getService,
    /**
     * Check if the container is started.
     */
    started: () => container.started
  };
  const translateUrl = translateUrlByLangCode(tpp);
  const redirectNewLanguage = redirectToNewLanguage(tpp);
  const translateUrlSync = translateUrlByLangCodeSync(tpp);
  /**
   * Useful to translate urls
   * @type {(function(*, *): Promise<string>)|*}
   */
  tpp.translateUrl = translateUrl;
  /**
   * Useful to translate urls synchronously
   * @type {function(*, *): string}
   */
  tpp.translateUrlSync = translateUrlSync;
  /**
   * Useful to redirect to a new language
   * @type {(function(*, *): Promise<null>)|*}
   */
  tpp.redirectToNewLanguage = redirectNewLanguage;
  return tpp;
};

/**
 * Returns a TPP container.
 * @param containerName the key we use to store in memory the container.
 * @returns {{configService: (function(): *), containerName, languageService: (function(): *), start: ((function(*, *, *): Promise<boolean>)|*), started: (function(): boolean), empty: empty, register: (function(*, *): void)}|*}
 * @constructor
 */
let TPPServiceContainer = (containerName) => {
  const container = memoTpp(containerName);
  if (!container) {
    let tpp = createTPP(containerName);
    memoTpp(containerName, tpp);
    return tpp;
  }
  return container;
};

/**
* TODO: use on refactor of config
 * Starts a new TPP container.
 * Useful for tests.
 * It might allow to hot change the full page with a different language and config.
 * @param key the key we use to store in memory the container.
 * @param config the config object
 * @param pathname i.e '/fr' for French, '/es' for Spanish
 * @param host i.e localhost:3000
 * @returns {Promise<{configService: (function(): *), containerName, languageService: (function(): *), start: ((function(*, *, *): Promise<boolean>)|*), started: (function(): boolean), empty: empty, register: (function(*, *): void)}|*>}
 */

/**
 * Returns tpp container object, language service and the config service.
 * This function might return different stuff depending on the globally
 * set container key.
 * This is not the preferred solution but the refactor required to allow a proper
 * dependency injection is too big, so relying on a global key is preferred.
 * Anyway, it is better to rely just on a global key than to rely on very
 * big global objects...
 * @param containerName the global container key
 * @returns {{configService: *, languageService: *, tpp: *}}
 * @throws Error if services are not started
 */
const getTPPServices = (containerName = '') => {
  const containerKey = containerName || getTPPContainerKey();
  const tpp = TPPServiceContainer(containerKey);
  assertTpp(tpp);
  return {languageService: tpp.languageService(), configService: tpp.configService(), tpp};
};

const loadConfigService = async (containerName) => {
  const tpp = TPPServiceContainer(containerName);
  let configService = tpp?.configService();
  if (!configService) {
    const eventDispatcher = tppEventEmitter();
    // istanbul ignore else
    if (typeof window !== 'undefined') {
      configService = await createConfigService();
      const started = await configService.start(null, getPortalName());
      eventDispatcher.dispatchEvent(CONFIG_STARTED, {configService, started});
    }
  }
  // Server side we already have the config from middleware
  return configService;
};

/**
 * These functions will throw if language is not initialized and that is the expected behaviour
 * We will be using the default key to get the service in the container, so these functions will not be injectable
 * We can override the default service in the arguments of the functions that uses them i.e:
 * getFormatMessageFunction(customLanguageService) or getRouteConstantsFromI18n(customLanguageService)
 */
/**********************************/
/*    DEFAULT SERVICE HELPERS     */
/**********************************/
/**
 * This function returns the current language service. We memoized it to avoid multiple calls.
 * @returns {function(): *}
 */
const langServices = (reset) => {
  // memoized languageService object
  let memoLangService = null;
  const findLangService = () => {
    const theKey = getTPPContainerKey();
    const {languageService} = getTPPServices(theKey);
    memoLangService = languageService;
    return memoLangService;
  };
  if (reset){
    memoLangService = null;
  }
  //return () => memoLangService || findLangService();
  return () => {
    if (!memoLangService){
      return findLangService();
    }
    return memoLangService;
  };
};

let memoizedLangServices = langServices();

/**
 * This function returns the current i18n container object in the language service.
 * routes and boats are "aliases" for simplicity on refactor
 * @returns{I18nContainer: {
 *  intl: ReactIntlObject<{formatMessage: (key:string) => string, messages: object}>,
 *  routesConstants: {[string]: string},
 *  boatsConstants: {[string]: string},
 *  routes: routeConstants,
 *  boats: boatConstants,
 *  addPathnameLocale: *,
 *  host: *
 *  }
 */
const getCurrentI18n = () => {
  const languageService = memoizedLangServices();
  return languageService.getI18n();
};
/**
 * This function returns the current host in the language service. I.e 'http://localhost:3000'
 * @returns string
 */
const getCurrentHost = () => {
  const languageService = memoizedLangServices();
  return languageService.getHost();
};

const getUntranslatedItem = (matcher, item) => {
  const i18n = getCurrentI18n();
  const itemValue = item.split('-').slice(1).join('-');
  const translations = i18n?.intl?.messages || {};
  const regexMatcher = new RegExp(matcher);
  for (const [key, value] of Object.entries(translations)) {
    if (
      regexMatcher.test(key) &&
      normalizeString(value) === normalizeString(itemValue)
    ) {
      return key.replace(matcher, '');
    }
  }
  return '';
};

/**
 * This function maps some translated key-value pair in the url with its key in the "params" object
 * we use to parse the url and know filters after that to know the general state.
 * It is an important functions for TPP and has an absolutely meaningless name
 * TODO: Rethink the name and the way we parse the url
 * @param localizedValue
 * @param facetMessages
 * @param facetMessagesFormatter
 * @returns {string}
 */
export const mappedValue = (
  localizedValue,
  facetMessages,
  facetMessagesFormatter
) => {
  const languageService = memoizedLangServices();
  const i18n = languageService.getI18n();
  const intl = i18n.intl;
  const englishValue = Object.keys(facetMessages).find((key) => {
    const translatedValue = intl.formatMessage(facetMessages[key]);
    const formattedValue = facetMessagesFormatter
      ? facetMessagesFormatter(translatedValue)
      : translatedValue;
    return formattedValue === localizedValue;
  });
  return englishValue || localizedValue;
};

/**
 * These helpers are set here a default to quickly deal with usage in the application
 * during translations refactor.
 * They should be replaced by the proper methods.
 */
const resetMemoizedLangServices = () => {
  memoizedLangServices = langServices(true);
};

// defaultI18service is a quick and very bad idea that has to be improved.
// We should not rely on a default service and tpp container should always be available,
// injectable and started on demand.
// but the omnipresence of formatMessage (t), mappedValue, bts, rts makes it hard to refactor.
// TODO: step by step remove all reference and clean up
const defaultI18Service = {
  languageService: memoizedLangServices,
  getCurrentI18n,
  getCurrentHost,
  getUntranslatedItem,
  mappedValue,
  name: 'default',
  reset: resetMemoizedLangServices
};

const getDefaultI18Service = () => {
  return defaultI18Service;
};
/**********************************/
/* END OF DEFAULT SERVICE HELPERS */
/**********************************/

export const temporaryIntlContainer = (overrideLocale, key = getTPPContainerKey()) => {
  const {languageService, configService} = getTPPServices(key);
  const host = languageService.getHost();
  const config = configService.getConfig();
  const translationMessages = languageService.allServiceTranslations();
  const tempIntl = temporaryI18nContainer(overrideLocale, config, translationMessages, host);
  return tempIntl;
};
/**
 * This function returns the current intl.formatMessage function (aliased as "t" often) from react-intl.
 * We could (preferably?) use it from useIntl or useTppServices getTppServices.
 * It will throw if the language is not initialized.
 *
 * @param i18Service: {
 * languageService: function,
 * getCurrentI18n: function,
 * getCurrentHost: function,
 * getUntranslatedItem: function,
 * mappedValue: function
 * }
 * @returns {function(key:string): string}
 */
const getFormatMessageFunction = (i18Service, tempLocale) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  if (tempLocale) {
    // This is sync. It will only work if language for tempLocale is already loaded.
    const tmpI18n = temporaryIntlContainer(tempLocale);
    return tmpI18n.intl.formatMessage;
  }
  const formattedMessage = i18Service.languageService?.()?.formatMessage?.();
  if (typeof formattedMessage !== 'function') {
    const {languageService} = getTPPServices();
    const i18n = languageService.getI18n();
    return i18n.intl.formatMessage;
  }
  return formattedMessage;
};

const assertTpp = (tpp) => {
  if (!tpp?.started()) {
    throw new Error('You need to start tpp services with proper translations');
  }
};

const assertExists = (value) => {
  if (!value) {
    throw new Error('Value does not exist');
  }
};

// used for tests, not testable
// istanbul ignore next
const startTestMock = () => {
  let mockServiceContainer = null;
  const mockTPPServiceContainer = (mocked) => {
    mockServiceContainer = TPPServiceContainer;
    TPPServiceContainer = mocked;
    const mockRestore = () => {
      TPPServiceContainer = mockServiceContainer;
      mockServiceContainer = null;
    };
    return {mockRestore};
  };
  return mockTPPServiceContainer;
};

/**
 * Since the TPPServiceContainer memoizes stuff and has
 * global data (config and langs) needed for the application,
 * we might need to mock it on tests.
 * i. e.
 *     const mocked = () => ({configService: () => null});
 *     const mockedContainer = tppContainerMock(mocked);
 * Once the test finishes we must restore it or other tests will fail
 * mockedContainer.mockRestore();
 * @type {function(*): {mockRestore: function(): void}}
 */
const tppContainerMock = startTestMock();

export {tppContainerMock, TPPServiceContainer, loadConfigService, getTPPServices, getDefaultI18Service, getFormatMessageFunction, assertTpp, assertExists};
