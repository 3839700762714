import {getTPPServices} from './tppDi';
import {nil} from '../utils/runOnceHelper';
const EXPRESS_BRIDGE_SERVICE = 'EXPRESS_BRIDGE_SERVICE';
export const EXPRESS_BRIDGE = {
  LISTING_PARSABLE: 'LISTING_PARSABLE',
};

/**
 * This object allows us to pass data from react to express on the server.
 * A singleton object is created that we can fill with information while react is rendering
 * and then read from express before returning the response.
 */
const createExpressBridgeService = () => {
  if (typeof window !== 'undefined') {
    return {getExpressShared: nil, shareWithExpress: nil };
  }
  const sharedBridgeValues = {};
  const shareWithExpress = (key, value) => {
    sharedBridgeValues[key] = value;
  };
  const getExpressShared = () => {
    return sharedBridgeValues;
  };
  const emptyShared = () => {
    Object.keys(sharedBridgeValues).forEach((key) => {
      delete sharedBridgeValues[key];
    });
  };
  return {
    /**
     * returns the shared values
     */
    getExpressShared,
    /**
     * shares a value with express
     * @param key
     * @param value
     */
    shareWithExpress,
    /**
     * clears the shared values
     */
    emptyShared
  };
};

const getExpressBridgeService = (tpp) => {
  if (!tpp) {
    const tppServices = getTPPServices();
    tpp = tppServices.tpp;
  }

  if (!tpp?.register) {
    throw new Error('Invalid TPP object used in getExpressBridgeService');
  }
  let expressBridgeService = tpp.getService(EXPRESS_BRIDGE_SERVICE);
  if (expressBridgeService) {
    return expressBridgeService;
  }
  expressBridgeService = createExpressBridgeService();
  tpp.register(EXPRESS_BRIDGE_SERVICE, expressBridgeService);
  return expressBridgeService;
};

export { getExpressBridgeService };
