import {configImport} from '../config/importConfig';

// eslint-disable-next-line func-style
function initConfigService() {
  const loadConfig = async (portal) => {
    const {default: portalConfig} = await configImport(portal);
    return portalConfig;
  };

  const createConfigService = async (config) => {
    let configuration;

    const getConfig = () => {
      if (!configuration) {
        throw new Error('Configuration not loaded');
      }
      return configuration;
    };
    const start = async (cfg,  source ) => {
      if (cfg) {
        configuration = cfg;
        return true;
      }
      if (source) {
        try {
          configuration = await loadConfig(source);
          return true;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('init portal Error loading config -> ', e);
        }
      }
      return false;
    };
    const empty = () => {
      configuration = null;
    };
    const started = () => !!configuration;
    await start(config);
    let configService = {getConfig, start, configuration, loadConfig, empty, started};
    return configService;
  };
  return {createConfigService};
}

const {createConfigService} = initConfigService();
export {createConfigService};
