export const BLOG_ARTICLE_TYPE = 'portal_platform_post';
export const BLOG_RELATED_ARTICLES_MAX = 3;
export const CUSTOM_META_DESCRIPTION_LENGTH = 24;
export const DEBOUNCE_TIMEOUT_LONG = 1000;
export const DEBOUNCE_TIMEOUT_SHORT = 500;
export const GDPR_COUNTRY_CODES = ['AT', 'BE', 'BG', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'UK'];
export const HEADER_OFFSET = 51;
export const ONE_YEAR_MILLIS = 31536000000;
export const SHOW_BOAT_NAME_MIN_LENGTH = 24;
export const ALLOWED_PARAMS_FOR_OEMDETAILS = ['enableOEM', 'OEMDetails', 'wordsmithContentType', 'relatedBoatArticles', 'videoType', 'distance','facets','fields','multiFacetedMakeModel','pageSize','page','portal','sort','useMultiFacetedFacets','exactMakeMatch','exactModelMatch', 'enableSponsoredSearch','locale','currency'];
export const ENGLISH_LOCALES = ['us', 'au', 'en', 'uk'];
