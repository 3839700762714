import React from 'react';
import PropTypes from 'prop-types';

const OptionalScripts = ({page}) => {
  if (!['SearchResults', 'BrandedOEMSearchResults'].includes(page)) {
    return (
      <>
        <script
          async type="text/javascript"
          src="//platform-api.sharethis.com/js/sharethis.js#property=#{property?._id}&product=custom-share-buttons"></script>
      </>
    );
  }
  return null;
};

OptionalScripts.propTypes = {
  page: PropTypes.string
};

export default OptionalScripts;
