import { matchPath } from 'react-router';
import { getDefaultParams } from './getDefaultParams';
import {
  getBoatConstantsFromI18n,
  getMappedValueFromI18n,
  getRouteConstantsFromI18n
} from '../../../tppServices/translations/constants';

import {parseReplacers} from '../boatsConstantsReplacers';
import {getConfig} from '../../../config/portal';
import {getFormatMessageFunction} from '../../../tppServices/tppDi';
import {getCommonBoatParsers} from '../boatsConstantsParsers';

// use by parseBrandedOemSearchParams to get oem params
export const parseReplaceOemParams = (brand = '', model = '', i18nService, config) => {
  // model value example with 2 models: model-fx-cruiser-svho+model-242-limited-s
  if (brand) {
    if (!config) {
      // TODO: remove this call when needed
      config = getConfig();
    }
    const boatConstants = getBoatConstantsFromI18n(i18nService);
    const routeConstants = getRouteConstantsFromI18n(i18nService);
    const t = getFormatMessageFunction(i18nService);
    const mappedValue = getMappedValueFromI18n(i18nService);
    const parserReplacers = parseReplacers(boatConstants, config, t, mappedValue);
    const { parseReplaceMakeModelParams, parseReplaceLegacyMakeModelParams } = parserReplacers;
    let makeModelParams;
    const {
      SEARCH_URL_ITEM_MODEL,
      SEARCH_URL_ITEM_MAKEMODEL,
      SEARCH_URL_ITEM_MAKE
    } = routeConstants;

    const hasManyModels =
      (model.match(new RegExp(SEARCH_URL_ITEM_MODEL, 'g')) || []).length > 1;
    if (hasManyModels) {
      // models in makeModel format - makeModel-yamaha:model1+yamaha:model2
      const models = model.replace(
        new RegExp(`${SEARCH_URL_ITEM_MODEL}-`, 'g'),
        `${brand}:`
      );
      makeModelParams = parseReplaceMakeModelParams(
        `${SEARCH_URL_ITEM_MAKEMODEL}-${models}`
      );
    } else if (model) {
      makeModelParams = parseReplaceLegacyMakeModelParams(
        `${SEARCH_URL_ITEM_MAKE}-${brand}`,
        model
      );
    } else {
      makeModelParams = parseReplaceMakeModelParams(
        `${SEARCH_URL_ITEM_MAKEMODEL}-${brand}`
      );
    }

    return {
      enableOEM: true,
      ...makeModelParams,
      facets: [
        'makeModel',
        'class',
        'priceRevisedSince',
        'minMaxPercentilPrices',
        'model',
        'minYear',
        'maxYear',
        'activities'
      ].join(','),
      enableSponsoredSearch: false,
      condition: 'new',
      isOemSearch: true
    };
  }

  return {};
};

// use to parse the branded srp url to get the search params for fetching the api
// url format: brands/yamaha/model-190-fsh+model-190-fsh-deluxe/length-10,10000/
export const parseBrandedOemSearchParams = (url, i18nService, config) => {
  if (!url) {
    return {};
  }
  // istanbul ignore next
  if (!config) {
    // TODO: remove this call when needed
    config = getConfig();
  }
  const t = getFormatMessageFunction(i18nService);
  const boatsConstants = getBoatConstantsFromI18n(i18nService);
  const mappedValue = getMappedValueFromI18n(i18nService);
  const parserReplacers = parseReplacers(boatsConstants, config, t, mappedValue);
  const {parsePageParams} = getCommonBoatParsers(i18nService, config);
  const {
    parseReplaceKeywordParams, parseReplaceLengthParams, parseReplaceSortParams,
    parseReplaceRadiusParams
  } = parserReplacers;
  const { BRANDED_OEM_SEARCH_URL_PATTERN } = boatsConstants;
  let { params } = matchPath(decodeURI(url), BRANDED_OEM_SEARCH_URL_PATTERN);

  return {
    ...parseReplaceRadiusParams(params.radius),
    ...parseReplaceLengthParams(params.length),
    ...parsePageParams(params.page, true),
    ...parseReplaceSortParams(params.sort, params.postalCode),
    ...parseReplaceKeywordParams(params.keyword),
    ...parseReplaceOemParams(params.brand, params.model, i18nService, config)
  };
};

export const parseBrandedOemSearchAppParams = (url, i18nService) => {
  if (!url) {
    return {};
  }
  const boatsConstants = getBoatConstantsFromI18n(i18nService);
  const searchPattern = boatsConstants.BRANDED_OEM_SEARCH_URL_PATTERN;
  const matched = matchPath(decodeURI(url), searchPattern);
  if (matched) {
    let { params } = matched;
    return getDefaultParams(params);
  }
  return {};
};
