import get from 'lodash/get';

export const isBrokerBDP = (listing, portalConfig) => {
  const newDesignActive = get(portalConfig, 'pages.details.boatDetailsPageRedesign', false);
  return newDesignActive?.active && !listing?.isOemModel;
};

export const isBoatDetailsPageRedesign = (portalConfig) => {
  const newDesignActive = get(portalConfig, 'pages.details.boatDetailsPageRedesign', false);
  return newDesignActive?.active;
};

export const isContactFormSticky = (portalConfig) => {
  return portalConfig?.pages?.details?.boatDetailsContactFormSticky ?? false;
};
