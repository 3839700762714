import { CookieType } from '@dmm/lib-node-cookies/dist/utils/trustarc';

export const UNAVAILABILITY_REDIRECT_COOKIE = 'unavailabilityRedirectCookie';
export const REDUCTION_REDIRECT_COOKIE = 'reductionRedirectCookie';
export const APP_BANNER_COOKIE = 'appBannerCookie';
export const BOAT_DETAIL_QUERY_STRING_COOKIE =
  'BOAT_DETAIL_QUERY_STRING_COOKIE';
export const GDPR_COOKIE = 'gdpr';
export const SAVE_SEARCH_POPUP_SHOWN = 'saveSearchPopupShown';
export const UOM_COOKIE = 'uom';
export const KAMELEOON_VISITOR_CODE = 'kameleoonVisitorCode';
export const KAMELEOON_FEATURE_FLAGS = 'kameleoonFeatureFlags';
export const PERMUTIVE_ID = 'permutive-id';
export const TRIDENT_ADVERTISED_RATE = 'advertisedRate';

export const COOKIE_CLASSIFICATION_CMP = {
  [UNAVAILABILITY_REDIRECT_COOKIE]: CookieType.FUNCTIONAL,
  [REDUCTION_REDIRECT_COOKIE]: CookieType.FUNCTIONAL,
  [APP_BANNER_COOKIE]: CookieType.REQUIRED,
  [SAVE_SEARCH_POPUP_SHOWN]: CookieType.REQUIRED,
  [UOM_COOKIE]: CookieType.REQUIRED,
  [KAMELEOON_VISITOR_CODE]: CookieType.REQUIRED,
  [PERMUTIVE_ID]: CookieType.ADVERTISING,
  [TRIDENT_ADVERTISED_RATE]: CookieType.ADVERTISING
};
