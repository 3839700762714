/* istanbul ignore next */
const nil = () => {};

/* istanbul ignore next */
const emitterServerSide = (emitter) => {
  let theListener = nil;
  return () => ({
    dispatchEvent: (event, data) => {
      emitter.emit(event, {detail: data});
    },
    on: (event, callback) => {
      theListener = callback;
      emitter.on(event, callback);
    },
    off: (event, callback) => {
      emitter.off(event, callback || theListener);
    },
    emitter
  });
};

/* istanbul ignore next */
const emitterClientSide = (emitter) => {
  let theListener = nil;
  return () => ({
    dispatchEvent: (eventName, data) => {
      const event = new CustomEvent(eventName, { detail: data });
      emitter.dispatchEvent(event);
    },
    on: (eventName, listener) => {
      theListener = listener;
      emitter.addEventListener(eventName, listener);
    },
    off: (eventName, callback) => {
      emitter.removeEventListener(eventName, callback || theListener);
    },
    emitter
  });
};

const safeProcess = () => typeof process !== 'undefined' ? process : {};
/* istanbul ignore next */
const safeWindow = () => (typeof window !== 'undefined' ? window : {});


/**
 * Get the portal name from the environment variables.
 * process.env.PORTAL_NAME -> used in the server side
 * window.__PORTAL_NAME__ -> used in the client side
 * process.env.REACT_APP_PORTAL_NAME -> used in the client side during development
 */
const getPortalName = () => {
  const portal = process.env.PORTAL_NAME || safeWindow().__PORTAL_NAME__ || process.env.REACT_APP_PORTAL_NAME || '';
  if (!portal) {
    throw new Error('No portal name available');
  }
  return portal;
};

/* istanbul ignore next */
const eventEmitter = () => {
  if (typeof process !== 'undefined' && process.release?.name) {
    const { EventEmitter } = require('events');
    const emitter = new EventEmitter();
    return emitterServerSide(emitter);
  } else if (typeof window !== 'undefined') {
    return emitterClientSide(window);
  }
};

const createMemo = () => {
  const memoStore = {};
  const memo = (memoKey, memoObject) => {
    if (memoKey) {
      if (memoObject) {
        // Should we throw an error if the key already exists?
        memoStore[memoKey] = memoObject;
        return;
      }
      return memoStore[memoKey];
    }
  };
  const deleteMemo = (memoKey) => {
    if (memoKey) {
      delete memoStore[memoKey];
    }
  };
  return {memoTpp: memo, deleteMemoTpp: deleteMemo};
};

const {memoTpp, deleteMemoTpp} = createMemo();

// This is the default key for the container.
// Even though is not currently prepared, the base has been set to be able to change portal on the fly during development.
// By using several container keys we could just force the container to be reloaded with the new key and the new portal.
const TPP_CONTAINER = 'TPP_CONTAINER';

// Useful to change di container globally.
// Easier to manage the hard dependency injection required than to rewrite everything to
// allow a container to be passed as a parameter.
const globalKeyManager = () => {
  let defaultContainerKey = TPP_CONTAINER;
  const setTPPContainerKey = (containerKey) => {
    if (typeof containerKey !== 'string') {
      throw new Error('Container key must be a string');
    }
    const tmpTpp = memoTpp(containerKey);
    if (!tmpTpp?.started()) {
      throw new Error('You must create and start a TPP container before set its key as default');
    }
    defaultContainerKey = containerKey;
  };

  const clearTPPKey = () => {
    defaultContainerKey = TPP_CONTAINER;
  };

  const getTPPContainerKey = () => defaultContainerKey;

  return {setDefaultTPPContainerKey: setTPPContainerKey, getTPPContainerKey, clearTPPKey};
};

const pathNameFromHistory = (history) => {
  let serverPathname = history?.location?.pathname || '';
  let clientPathname = history?.pathname || '';
  return serverPathname || clientPathname;
};

const {setDefaultTPPContainerKey, getTPPContainerKey, clearTPPKey} = globalKeyManager();

const CONFIG_STARTED = 'CONFIG_STARTED';
const CONFIG_ERROR = 'CONFIG_ERROR';
const TPP_STORED = 'TPP_STORED';
const TPP_STARTED = 'TPP_STARTED';
const CONFIG_SERVICE = 'configService';
const LANGUAGE_SERVICE = 'languageService';
const tppEventEmitter = eventEmitter();

export {
  createMemo,
  memoTpp,
  deleteMemoTpp,
  CONFIG_STARTED,
  TPP_STORED,
  TPP_STARTED,
  CONFIG_ERROR,
  tppEventEmitter,
  CONFIG_SERVICE,
  LANGUAGE_SERVICE,
  setDefaultTPPContainerKey,
  getTPPContainerKey,
  clearTPPKey,
  TPP_CONTAINER,
  safeProcess,
  pathNameFromHistory,
  getPortalName
};
