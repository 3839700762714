import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { matchPath } from 'react-router';
import {
  getDefaultParams,
  getActiveParams
} from '../../../utils/urlHelpers/boats';
import {getBoatConstantsFromI18n} from '../../../tppServices/translations/constants';
/**
 * @typedef {Object} PropConfig
 * @property {string} key they to look for on params
 * @property {string} value the value to expect the key param to have
 * @property {string} default the value to be set if the value matches

/**
 * Goes through all the anchors and applies the config used to add seo props on the anchors following rules
 * @param {string} content _html
 * @param {{props:[PropConfig]} , seo: string} config
 */
export const editContentAnchors = (content, config, i18nService) => {
  if (!config.seo) {
    return content;
  }
  const anchorRegex = /<a.*?>/g;
  const anchorPropsRegex = /<a(.*?)\/?>/;
  const hrefRegex = /href="(https?:\/\/[^/]+)?(?<pathname>.*?)"/;
  const anchors = content.match(anchorRegex) || [];
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  let newContent = '' + content;
  for (const anchor of anchors) {
    let newProps = [];
    const hrefMatch = hrefRegex.exec(anchor);
    const pathname = (hrefMatch && hrefMatch.groups.pathname) || '/';
    const { params = {} } = matchPath(pathname, boatConstants.SEARCH_URL_PATTERN) || {};
    const seoParams = getActiveParams(getDefaultParams(params));

    if (isEmpty(seoParams.multiFacetedBoatTypeClass)) {
      delete seoParams.multiFacetedBoatTypeClass;
    }

    let value = '';

    if (seoParams.multiFacetedBoatTypeClass || seoParams.makeModel) {
      value = getSEOElement(seoParams);
    } else {
      value = values(seoParams).join(' ');
    }

    for (const propConfig of config.props) {
      if (seoParams[propConfig.key] === propConfig.value) {
        value = propConfig.default;
        break;
      }
    }

    if (value) {
      newProps.push(`${config.seo}="${value}"`);
    }

    if (newProps.length > 0) {
      const propsExec = anchorPropsRegex.exec(anchor);
      newContent = newContent.replace(
        anchor,
        `<a${propsExec[1]} ${newProps.join(' ')}>`
      );
    }
  }
  return newContent;
};

const getSEOElement = (params) => {
  let value = [];

  if (params.multiFacetedBoatTypeClass) {
    for (let [, _class] of Object.entries(params.multiFacetedBoatTypeClass)) {
      value.push(_class.join(' '));
    }
  }
  if (params.makeModel) {
    for (let [make, model] of Object.entries(params.makeModel)) {
      value.push(make);
      if (model.length > 0) {
        value.push(model.join(''));
      }
    }
  }

  return value.join(' ');
};
