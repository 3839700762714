import {getBoatConstantsFromI18n} from '../../../tppServices/translations/constants';
import {getCommonBoatParsers} from '../boatsConstantsParsers';

// transform the match params from oem srp into normal makeModel
// match params = { brand: makeName, model: model-model1+model-model2}
// normal makeModel = { makeName: [model1, model2]}
const getMakeModelFromMatchParams = (brand, model = '', i18Service = null) => {
  const makeModel = { [brand]: [] };
  if (model) {
    model.split('+').forEach((modelStr) => {
      const modelName =
        getBoatConstantsFromI18n(i18Service).SEARCH_URL_MODEL_PATTERN.exec(modelStr)[1];
      makeModel[brand].push(modelName);
    });
  }

  return makeModel;
};

export const getDefaultParams = (params, config, i18nService) => {
  const makeModel = getMakeModelFromMatchParams(params.brand, params.model);
  const {parsePageParams, parseLengthParams, parseSortParams} = getCommonBoatParsers(i18nService, config);
  const parsed = {
    ...parseLengthParams(params.length),
    ...parseSortParams(params.sort),
    ...parsePageParams(params.page, true),
    makeModel,
    brand: params.brand
  };
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const defaults = {
    ...boatConstants.DEFAULT_REFINE_SEARCH
  };

  return {
    ...defaults,
    ...parsed
  };
};
