export default class TextTransform {
  constructor(_text) {
    this.withContent = _text ? true : false;
    this.safeValue = _text || '';
  }

  toString() {
    return this.safeValue;
  }

  trim() {
    if (this.withContent) {
      return new TextTransform(this.safeValue.trim());
    }
    return this;
  }

  toLowerCase() {
    if (this.withContent) {
      return new TextTransform(this.safeValue.toLowerCase());
    }
    return this;
  }

  removeExtraSpaces() {
    if (this.withContent) {
      return new TextTransform(this.safeValue.replace(/\s+/g, ' '));
    }
    return this;
  }

  capitalizeFirstLetter() {
    if (this.withContent) {
      const value = this.safeValue.charAt(0).toUpperCase() + this.safeValue.slice(1);
      return new TextTransform(value);
    }
    return this;
  }

  startCase() {
    if (this.withContent) {
      let separator = [' ', '&', '.'];
      let final = this.safeValue;
      for (const sep of separator) {
        final = final
          .split(sep)
          .map(x => new TextTransform(x).capitalizeFirstLetter().toString())
          .join(sep);
      }
      return new TextTransform(final);
    }
    return this;
  }

  removeDoubleBoatsWords() {
    if (this.withContent) {
      let text = this.safeValue;
      // eslint-disable-next-line max-len
      const regex = /([\s.]{0,1})(boats|yacht|yachts|houseboats|wraceboats|powerboats|powerboat|ship|ships|boote|\(fischerboote\)|\(rib\)|boten|bateaux|barcos|barche|båter|både|båtar|myytävät) (boats|boote|boten|bateaux|barcos|barche|båter|både|båtar|Båtar|myytävät)/gi;
      const rt = regex.exec(text);
      if (rt) {
        const prefix = rt[1];
        const keyword = rt[2];
        const toReplace = `${prefix}${keyword}`;
        text = text.replace(regex, toReplace);
      }
      return new TextTransform(text);
    }
    return this;
  }

  spaceAtTheEnd(safeAdd = true) {
    if (this.withContent) {
      if (safeAdd) {
        return new TextTransform(this.safeValue + (this.safeValue.endsWith(' ') ? '' : ' '));
      }
      return new TextTransform(this.safeValue + ' ');
    }
    return this;
  }

  commaAtTheEnd(safeAdd = true) {
    if (this.withContent) {
      if (safeAdd) {
        return new TextTransform(this.safeValue + (this.safeValue.endsWith(',') ? '' : ','));
      }
      return new TextTransform(this.safeValue + ',');
    }
    return this;
  }

  removeSpaceForFullStop() {
    if (this.withContent && this.safeValue.length > 2 && this.safeValue[this.safeValue.length - 2] === ' ' && this.safeValue[this.safeValue.length - 1] === '.') {
      return new TextTransform(this.safeValue.substring(0, this.safeValue.length - 2) + '.');
    }
    return this;
  }

  handleMessageSailTypeSelected(isBoatTypeSail, isSailboatsUppercase = true) {
    if (this.withContent && isBoatTypeSail) {
      return new TextTransform(this.safeValue.replace(/(sail )?boats for sale/gmi, `${isSailboatsUppercase ? 'S' : 's'}ailboats for sale`));
    }
    return this;
  }
}
