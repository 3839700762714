import get from 'lodash/get';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { getMediaImages } from './listingHelper';
import { getCurrency } from './uomHelper';
import { getBoatUrl } from './urlHelpers/boat';
import { getConfig } from '../config/portal';

export const getAggregateOfferSchema = (
  listing,
  listingCount,
  urlPath,
  makeModel,
  metaName,
  metaDescription,
  minMaxPrices,
  host
) => {
  const minPrice = minMaxPrices.find((obj) => {
    return obj.value === 'minPrice';
  });
  const maxPrice = minMaxPrices.find((obj) => {
    return obj.value === 'maxPrice';
  });

  let brand = {};
  if (!isEmpty(makeModel)) {
    brand = {
      'brand': {
        '@type': 'brand',
        'name': makeModel[0].value
      }
    };
  }

  const mainEntityOfPage = `${host || getConfig().host}${urlPath}`;

  let results = {
    '@context': 'https://schema.org',
    '@type': 'Vehicle',
    'mainEntityOfPage': mainEntityOfPage,
    'name': metaName,
    'description': metaDescription,
    'offers': {
      '@type': 'AggregateOffer',
      'priceCurrency': getCurrency(),
      'lowPrice': minPrice && minPrice.count.toFixed(0),
      'highPrice': maxPrice && maxPrice.count.toFixed(0),
      'offerCount': listingCount
    }
  };

  const listingImages = getMediaImages(listing);
  if (!isEmpty(listingImages)) {
    results = Object.assign(results, {
      'image': {
        '@type': 'ImageObject',
        contentUrl: listingImages[0].url,
        height: listingImages[0].height,
        width: listingImages[0].width
      }
    });
  }

  return Object.assign(results, brand);
};

export const getProductSchema = (listing, { getTitle, getCurrency }) => {
  let firstImage = first(listing.media);
  const description = get(listing, 'description', '');
  const htmlTagSanitizerRegex = /(<([^>]+)>)/gi;
  const sanitizedDescription = description.replace(htmlTagSanitizerRegex, '');
  if (has(listing.price, 'type') && firstImage) {
    return {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: getTitle(listing),
      image: firstImage.url,
      description: sanitizedDescription,
      url: getBoatUrl(listing),
      brand: {
        '@type': 'Organization',
        name: get(listing, 'make', '')
      },
      offers: {
        '@type': 'Offer',
        availability: 'https://schema.org/InStock',
        priceCurrency: getCurrency(),
        price: get(listing, `price.type.amount.${getCurrency()}`, ''),
        seller: {
          '@type': getSellerEntity(listing),
          name: get(listing, 'contact.name')
        },
        url: getBoatUrl(listing)
      },
      weight: getWeightsMap(listing),
      itemCondition: getListingCondition(listing),
      productID: listing.id
    };
  }
};

export const getArticleSchema = (article) => {
  const image = get(article, 'content.featuredImage', undefined);
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    'headline': article.title,
    'image': image ? [image] : [],
    'datePublished': article.dateCreated,
    'dateModified': article.dateModified,
    'author': [
      {
        '@type': 'Person',
        'name': article.authorName
      }
    ]
  };
};

export const getCarouselSchema = (ItemList) => {
  const carouselSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    'itemListElement': []
  };
  ItemList.forEach((item, index) => {
    carouselSchema.itemListElement.push({
      '@type': 'ListItem',
      'position': index,
      'url': item.url
    });
  });
  return carouselSchema;
};

export const getListingCondition = (listing) => {
  switch (listing.condition) {
    case 'used':
      return 'UsedCondition';
    case 'new':
    default:
      return 'NewCondition';
  }
};

export const getWeightsMap = (listing) => {
  const weights = get(listing, 'specifications.weights');
  let weightList = [];
  if (has(weights, 'dry.lb')) {
    weightList.push({
      '@type': 'QuantitativeValue',
      unitText: 'lb',
      value: weights.dry.lb
    });
  }
  if (has(weights, 'dry.kg')) {
    weightList.push({
      '@type': 'QuantitativeValue',
      unitText: 'kg',
      value: weights.dry.kg
    });
  }
  return weightList;
};

export const getSellerEntity = (listing) => {
  return listing.isFSBO ? 'Person' : 'Organization';
};

export const getBreadCrumbSchema = (items) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((element, index) => {
      return generateBreadCrumbSchema(index, element.link, element.title);
    })
  };
};

const generateBreadCrumbSchema = (index, link, title) => {
  return {
    '@type': 'ListItem',
    position: index,
    item: {
      '@id': link,
      name: title
    }
  };
};

export const getFaqPageSchema = (dynamicContent) => {
  const questionsAndAnswers = generateQuestionSchema(dynamicContent);
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questionsAndAnswers
  };
};

const generateQuestionSchema = (dynamicContent) => {
  const questionsAndAnswers = [];
  Object.keys(dynamicContent).forEach((key) => {
    questionsAndAnswers.push({
      '@type': 'Question',
      name: dynamicContent[key].title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: dynamicContent[key].paragraphs.join(' ')
      }
    });
  });
  return questionsAndAnswers;
};

export const parseHomeSchema = (config = {}, locale = 'en') => {
  const { client, header, languages, pages } = config;
  const endpoint = client?.cdn?.endpoint || '';
  const cfgLanguages = languages || {};
  const logo =
    cfgLanguages[locale]?.alternativeAssets?.header?.logo || header?.logo || '';
  const logoUrl = `${endpoint}${logo}`;
  const homeSchema = pages?.home?.seoConfig?.homeSchema || {};
  const { name, socialLinks, url } = homeSchema;
  const schemaName = name?.[locale] || name?.en || '';
  return { schemaName, socialLinks, logoUrl, homeUrl: url };
};

const validHomeSchema = (host, config, getLocale) => {
  if (!config || !host || typeof getLocale !== 'function') {
    return false;
  }
  return true;
};

export const getHomeWebsiteSchema = (host, config, getLocale) => {
  if (!validHomeSchema(host, config, getLocale)) {
    return null;
  }
  const locale = getLocale();
  const { schemaName } = parseHomeSchema(config, locale);
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: schemaName,
    url: host
  };
};

export const sameUrl = (url1, url2) => {
  const a = url1.replace(/^(https?:\/\/)|\/$/g, '').replace('www.', '');
  const b = url2.replace(/^(https?:\/\/)|\/$/g, '').replace('www.', '');
  return a === b;
};

const showSameAs = (socialLinks, homeUrl, url) => {
  const hasLinks = socialLinks?.length;
  return hasLinks && sameUrl(homeUrl, url);
};

export const getHomeOrganizationSchema = (url, config, getLocale) => {
  if (!validHomeSchema(url, config, getLocale)) {
    return null;
  }
  const locale = getLocale();
  const { schemaName, logoUrl, socialLinks, homeUrl } = parseHomeSchema(
    config,
    locale
  );
  const sameAs = showSameAs(socialLinks, homeUrl, url)
    ? { sameAs: socialLinks }
    : {};
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: schemaName,
    url: url,
    logo: logoUrl,
    ...sameAs
  };
};
