import {fetchAndStartMessages} from './messages';

const doExtendTranslations = (baseTranslations, overrideTranslations) => {
  const newTranslations = {...baseTranslations};
  for (const key of Object.keys(overrideTranslations)) {
    newTranslations[key] = overrideTranslations[key];
  }
  return newTranslations;
};

/**
 *
 * @param language the language of the portal
 * @param supportedLanguages all languages the portal supports from config
 * @param messages loaded from json for a concrete language
 * @param defaultFormattedMessages usually English default
 * @returns {{}|(* & {})}
 */
const overrideLanguages = (language, supportedLanguages, messages, defaultFormattedMessages) => {
  const flattenFormattedMessages = (formattedMessages, key) => {
    if (supportedLanguages[language] === undefined) {
      return {};
    }

    const formattedMessage = supportedLanguages[language].overrideTranslations[key] ||
      messages[key] ||
      // istanbul ignore next
      defaultFormattedMessages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const configTranslationOverrides = (language, messages, enTranslationMessages, supportedLanguages, defaultLangCode, config) => {
  if (!supportedLanguages) {
    supportedLanguages = config.languages;
    defaultLangCode = config?.language;
  }
  let defaultFormattedMessages = {};
  // When the language we are using is not the default language we need to set up overrides for english
  if (language !== defaultLangCode) {
    defaultFormattedMessages = overrideLanguages(defaultLangCode, supportedLanguages, enTranslationMessages, {});
  }

  const overridenLanguages = overrideLanguages(language, supportedLanguages, messages, defaultFormattedMessages);
  return overridenLanguages;
};

export const loadTranslationJson = async (locale) => {
  switch (locale) {
    case 'au':
      return import('../../translations/au.json');
    case 'de':
      return import('../../translations/de.json');
    case 'dk':
      return import('../../translations/dk.json');
    case 'en':
      return import('../../translations/en.json');
    case 'es':
      return import('../../translations/es.json');
    case 'fi':
      return import('../../translations/fi.json');
    case 'fr':
      return import('../../translations/fr.json');
    case 'gr':
      return import('../../translations/gr.json');
    case 'hr':
      return import('../../translations/hr.json');
    case 'it':
      return import('../../translations/it.json');
    case 'nl':
      return import('../../translations/nl.json');
    case 'no':
      return import('../../translations/no.json');
    case 'pl':
      return import('../../translations/pl.json');
    case 'se':
      return import('../../translations/se.json');
    case 'tr':
      return import('../../translations/tr.json');
    case 'uk':
      return import('../../translations/uk.json');
    case 'us':
      return import('../../translations/us.json');
    default:
      return import('../../translations/en.json');
  }
};

const loadTranslationMessages = async (locale) => {
  const {default: translationJson} = await loadTranslationJson(locale);
  return translationJson;
};


const prepareAndStartMessages = async (defaultLang) => {
  await fetchAndStartMessages(defaultLang);
};

export const prepareDefaultTranslation = () => {
  let defaultTranslation = null;
  const noDefaultTranslation = () => {
    throw new Error('Default translation not set');
  };
  const getDefaultTranslation = () => defaultTranslation || noDefaultTranslation();
  const loadDefaultTranslation = async () => {
    if (defaultTranslation) {
      return defaultTranslation;
    }
    defaultTranslation = await loadTranslationMessages('en');
    await prepareAndStartMessages(defaultTranslation);
    return defaultTranslation;
  };
  return {getDefaultTranslation, loadDefaultTranslation};
};

const {getDefaultTranslation, loadDefaultTranslation} = prepareDefaultTranslation();

const loadFormattedLanguages = async (language, config) => {
  const extendableTranslation = ['uk', 'us', 'au'].find((lang) => lang === language);
  const englishDefault = await loadDefaultTranslation();
  const supportedLanguages = config.languages;
  const defaultLangCode = config?.language;
  if (extendableTranslation) {
    const overrideTranslation = await loadTranslationMessages(extendableTranslation);
    const extended = doExtendTranslations(englishDefault, overrideTranslation);
    return configTranslationOverrides(extendableTranslation, extended, englishDefault, supportedLanguages, defaultLangCode, config);
  }
  const baseTranslation = await loadTranslationMessages(language);
  return configTranslationOverrides(language, baseTranslation, englishDefault, supportedLanguages, defaultLangCode, config);
};

// Server Side we keep all the stuff in memory as we used to do...
const startAllTranslations = () => {
  let serverSideTranslations = {};
  const loadAllTranslations = async (config) => {
    const langs = ['en', 'ca', 'es', 'fr', 'dk', 'de', 'it', 'nl', 'no', 'fi', 'se', 'hr', 'gr', 'pl', 'tr', 'pt', 'uk', 'us', 'au'];
    const configLangs = Object.keys(config.languages);
    for (let lang of configLangs) {
      if (langs.includes(lang)) {
        const langKey = `${lang}`;
        serverSideTranslations[langKey] = await loadFormattedLanguages(lang, config);
      }
    }
    return serverSideTranslations;
  };
  return loadAllTranslations;
};

const loadAllTranslations = startAllTranslations();


export {loadFormattedLanguages, configTranslationOverrides, loadAllTranslations, getDefaultTranslation, loadDefaultTranslation};
