import { generatePath, matchPath } from 'react-router';
import { getBoatsConstants } from '../../constants/boats';
import {
  getBoatConstantsFromI18n,
  getMappedValueFromI18n,
  getRouteConstantsFromI18n
} from '../../tppServices/translations/constants';
import { syncTranslationElements } from '../../tppServices/translations/intlManager';
import { getMessages } from '../../tppServices/translations/messages';

export const formatBlogCategoriesForUrl = (category) => {
  const invalidChars = new RegExp(`[^${getBoatsConstants().ALLOWED_CHARACTERS_IN_BLOG_CATEGORY_URL}]+`, 'g');
  const formattedCategory = category.replace(invalidChars, '');
  const hyphenateCategory = formattedCategory.toLowerCase().split(' ').join('-');
  return hyphenateCategory;
};

export const generateBlogPath = (changes, urlProps) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const boatConstants = getBoatConstantsFromI18n();
  const config = getRouteConstantsFromI18n();
  const searchPattern = boatConstants.BLOG_URL_PATTERN;
  const initialParams = {
    ...urlProps,
    ...changes
  };
  const finalParams = {};

  if (initialParams.category) {
    const categoryTranslation = t(messages.blog.categories[initialParams.category]);
    finalParams.category =  formatBlogCategoriesForUrl(categoryTranslation);
  }
  else if (initialParams.article) {
    finalParams.article =  decodeURI(initialParams.article);
  }

  if (initialParams.page && initialParams.page > 1) {
    finalParams.page =  `${config.BLOG_URL_ITEM_PAGE}-${initialParams.page}`;
  }

  return generatePath(searchPattern, finalParams);
};

export const generateBlogRedirectPath = (changes, urlProps) => {
  const boatConstants = getBoatConstantsFromI18n();
  const searchPattern = boatConstants.BLOG_URL_PATTERN;
  const initialParams = {
    ...urlProps,
    ...changes
  };
  const finalParams = {};

  if (initialParams.category) {
    finalParams.category = decodeURI(initialParams.category);
  }
  if (initialParams.article) {
    finalParams.article =  decodeURI(initialParams.article);
  }

  return generatePath(searchPattern, finalParams);
};

export const parseBlogParams = (url) => {
  if (!url) {return {};}
  const boatConstants = getBoatConstantsFromI18n();
  const { params } = matchPath(decodeURI(url), boatConstants.BLOG_URL_PATTERN);
  const parsedParams = {...parseCategoryParams(params.category), ...parseArticleParams(params.article)};
  parsedParams.page = params.page ? Number(params.page.split('-')[1]) : 1;
  return parsedParams;
};

export const parseCategoryParams = (category = '', i18nService) => {
  const mappedValue = getMappedValueFromI18n(i18nService);
  const boatConstants = getBoatConstantsFromI18n();
  const messages = getMessages();
  if (boatConstants.BLOG_URL_CATEGORY_PATTERN.test(category)) {
    const matches = boatConstants.BLOG_URL_CATEGORY_PATTERN.exec(category);
    return {
      category: mappedValue(matches[1], messages.blog.categories, formatBlogCategoriesForUrl)
    };
  }
  return {};
};

export const parseArticleParams = (article = '') => {
  const boatConstants = getBoatConstantsFromI18n();
  if (boatConstants.BLOG_URL_ARTICLE_PATTERN.test(article)) {
    const matches = boatConstants.BLOG_URL_ARTICLE_PATTERN.exec(article);
    return {
      name: matches[1]
    };
  }
  return {};
};
