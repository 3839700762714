import get from 'lodash/get';
import {getConfig} from '../config/portal';
import {getBoatsConstants} from '../constants/boats';
import {getActiveParamsLength} from './urlHelpers/boats';

export const getPortalDefaultCountry = () => get(getConfig(), 'country');

const handleForceFilter = (filter, country) => {
  const portalFiltersConfig = get(getConfig(), 'pages.searchResults.facets', []);
  const filterConfig = portalFiltersConfig.find((config) => config.name === filter);
  if (
    filterConfig.forceDisabledForCountries &&
    filterConfig.forceDisabledForCountries.includes(country?.toUpperCase())
  ) {
    return false;
  }
  if (
    filterConfig.forceEnabledForCountries &&
    filterConfig.forceEnabledForCountries.includes(country?.toUpperCase())
  ) {
    return true;
  }
};

const isFilterEnabledForPortal = (filter) => {
  const portalFiltersConfig = get(getConfig(), 'pages.searchResults.facets', []);
  const filterConfig = portalFiltersConfig.find((config) => config.name === filter);
  return Boolean(filterConfig);
};

export const portalHasCountryFilter = () => {
  return isFilterEnabledForPortal('country');
};

export const countryHasRegionFilter = (country) => {
  const filterConfig = get(getBoatsConstants().LOCATION_FILTERS_CONFIG, `${country?.toUpperCase()}.grouping`, false);
  return filterConfig && isFilterEnabledForPortal('region');
};

export const isRegionFilterEnabled = (selectedCountry) => {
  const forceFilter = handleForceFilter('region', selectedCountry);
  if (forceFilter !== undefined) {
    return forceFilter;
  }

  const country = portalHasCountryFilter() ? selectedCountry : getPortalDefaultCountry();
  return countryHasRegionFilter(country);
};

export const countryHasSubdivisionFilter = (country) => {
  const filterConfig = get(getBoatsConstants().LOCATION_FILTERS_CONFIG, `${country?.toUpperCase()}.subdivision`, false);
  return filterConfig && isFilterEnabledForPortal('subdivision');
};

export const isSubdivisionFilterEnabled = (selectedCountry, selectedRegion) => {
  const forceFilter = handleForceFilter('subdivision', selectedCountry);
  if (forceFilter !== undefined) {
    return forceFilter;
  }

  const country = portalHasCountryFilter() ? selectedCountry : getPortalDefaultCountry();
  const isRegionRequired = countryHasRegionFilter(country);
  const countryHasTheFilter = countryHasSubdivisionFilter(country);
  return isRegionRequired ? Boolean(selectedRegion) && countryHasTheFilter : countryHasTheFilter;
};

export const countryHasCityFilter = (country) => {
  const filterConfig = get(getBoatsConstants().LOCATION_FILTERS_CONFIG, `${country?.toUpperCase()}.city`, false);
  return filterConfig && isFilterEnabledForPortal('city');
};

export const isCityFilterEnabled = (selectedCountry, selectedSubdivision) => {
  const forceFilter = handleForceFilter('city', selectedCountry);
  if (forceFilter !== undefined) {
    return forceFilter;
  }

  const country = portalHasCountryFilter() ? selectedCountry : getPortalDefaultCountry();
  const isSubdivisionRequired = countryHasSubdivisionFilter(country);
  const countryHasTheFilter = countryHasCityFilter(country);
  return isSubdivisionRequired ? Boolean(selectedSubdivision) && countryHasTheFilter : countryHasTheFilter;
};

export const isFilteringOnlyByCity = (params) => {
  let {country, region} = params;
  if (isRegionFilterEnabled(country) && isSubdivisionFilterEnabled(country, region)) {
    return getActiveParamsLength(params) === 4;
  }
  if (isSubdivisionFilterEnabled(country, region)) {
    return getActiveParamsLength(params) === 3;
  }
  return getActiveParamsLength(params) === 2;
};

export const isFilteringOnlyBySubdivision = (params) => {
  let {country} = params;
  if (isRegionFilterEnabled(country)) {
    return getActiveParamsLength(params) === 3;
  }
  return getActiveParamsLength(params) === 2;
};

export const isFilteringOnlyByRegion = (params) => {
  return getActiveParamsLength(params) === 2;
};

export const isFilteringOnlyByCountry = (params) => {
  return getActiveParamsLength(params) === 1;
};

export const shouldForceCities = (lang, defaults = getBoatsConstants()) => {
  const {LOCATION_FILTERS_CONFIG} = defaults;
  const country = LOCATION_FILTERS_CONFIG[lang];
  return country &&
    !country.grouping &&
    !country.subdivision &&
    country.city &&
    country.forceCities;
};
