import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { genericButtonClickEvent } from '../../../../store/actions/dataLayer';
import { createSubscription } from '../../../../store/actions';
import ConfirmationModal from '../../../ConfirmationModal';
import './styles.css';
import { getMessages } from '../../../../tppServices/translations/messages';

const NewsletterSubscription = (props) => {
  const [checked, setChecked] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [email, setEmail] = useState('');
  const messages = getMessages();

  const handleSignOnOnClick = () => {
    setChecked(!checked);
  };

  const handleEmailOnChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    props.genericButtonClickEvent('newsletter', 'subscription', 'marketing');
    props.createSubscription(email);
    setShowConfirmation(true);
    setEmail('');
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const {
    intl: { formatMessage: t }
  } = props;

  return (
    <div
      id="newsletter-subscription"
      className={classNames('newsletter-subscription', { disabled: !checked })}
    >
      <div className={classNames('newsletter-envelope', 'newsletter-icon')} />
      <label className="newsletter-message">
        {t(messages.footer.newsletterSubscription.description)}
      </label>
      <div className="newsletter-container">
        <form
          onSubmit={(e) => handleEmailSubmit(e)}
          className="newsletter-email-container"
        >
          <div className="flexbox">
            <label className="visually-hidden" htmlFor="newsletter-email">
              {t(messages.footer.newsletterSubscription.enterEmail)}
            </label>
            <input
              disabled={!checked}
              id="newsletter-email"
              name="newsletter-email"
              placeholder={t(messages.footer.newsletterSubscription.enterEmail)}
              required
              type="email"
              onChange={(e) => handleEmailOnChange(e)}
              value={email}
            />
            <button id="newsletter-subscribe" disabled={!checked} type="submit">
              {t(messages.footer.newsletterSubscription.subscribe)}
            </button>
          </div>
        </form>
      </div>
      <div className="newsletter-container">
        <label className="newsletter-sign-up">
          {t(messages.footer.newsletterSubscription.signUp)}
          <input
            id="newsletter-sign-up"
            type="checkbox"
            checked={checked}
            onChange={handleSignOnOnClick}
          />
          <span className={classNames('checkmark', { checked })}></span>
        </label>
      </div>
      <div className="newsletter-container">
        <label className="newsletter-agreement">
          {t(messages.footer.newsletterSubscription.disclaimer)}{' '}
          <a
            href={t(messages.footer.termsAndConditions.url)}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t(messages.footer.termsAndConditions.name)}
          </a>{' '}
          {t(messages.footer.newsletterSubscription.disclaimerAnd)}{' '}
          <a
            href={t(messages.footer.privacyPolicy.url)}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t(messages.footer.privacyPolicy.name)}
          </a>
        </label>
      </div>
      <ConfirmationModal
        close={handleConfirmationClose}
        message={t(messages.footer.newsletterSubscription.confirmation)}
        show={showConfirmation}
      />
    </div>
  );
};

NewsletterSubscription.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  genericButtonClickEvent: PropTypes.func.isRequired,
  createSubscription: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    searchParams: get(state, 'app.params', {})
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      genericButtonClickEvent,
      createSubscription
    },
    dispatch
  )
)(NewsletterSubscription);
