import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Schema = ({ data }) => {
  const script = (
    <script type="application/ld+json">{JSON.stringify(data)}</script>
  );

  return <Helmet>{script}</Helmet>;
};

Schema.defaultProps = {
  data: {}
};

Schema.propTypes = {
  /** Data to add as a Helmet script */
  data: PropTypes.object
};

export default Schema;
