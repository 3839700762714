import get from 'lodash/get';

export const buildRelatedListingParamsFromListing = (config, paramList, listing) => {
  const params = {
    pageSize: get(config, 'pages.details.additionalLeads.pageSize', 3),
    sort: get(config, 'pages.details.additionalLeads.sort', 'random')
  };

  params['!id'] = get(listing, 'id');

  if (paramList.includes('location') && get(listing, 'location.coordinates[0]') && get(listing, 'location.coordinates[1]')) {
    params.lat = get(listing, 'location.coordinates[1]');
    params.lon = get(listing, 'location.coordinates[0]');
  }
  if (paramList.includes('make')) {
    params.make = get(listing, 'make');
  }
  if (paramList.includes('model')) {
    params.model = get(listing, 'model');
  }
  if (paramList.includes('class')) {
    params.class = get(listing, 'class');
  }

  return params;
};
