import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import { injectIntl } from 'react-intl';
import { isOuterClick } from '../../utils';

import { normalizeString } from '../../utils/commonHelper';
import './styles.css';
import { getMessages } from '../../tppServices/translations/messages';

const Dropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(props.value);
  const [optionMessage, setOptionMessage] = useState(
    props.value[0] ? props.value[0][props.optionKey] : ''
  );

  const [focus, setFocus] = useState(false);
  const [filterBy, setFilterBy] = useState('');
  const [_options, setOptions] = useState(props.options);

  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const messages = getMessages();
  const getId = () => {
    return props.label.toLowerCase().replace(/\s/g, '-') + '-dropdown';
  };

  const handleDocumentClick = (e) => {
    const outerClicked = isOuterClick(e.target, containerRef.current);
    if (outerClicked) {
      setFocus(false);
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (!isEqual(props.value, selectedOption)) {
      setSelectedOption(props.value);
      setOptionMessage(props.value[0] ? props.value[0][props.optionKey] : '');
    }

    if (props.options.length !== _options.length) {
      setOptions(props.options);
    }

    if (filterBy && props.filter) {
      setOptions(
        props.options.filter((content) => {
          return normalizeString(content[props.optionKey])
            .toLowerCase()
            .includes(normalizeString(filterBy).toLowerCase());
        })
      );
    }
  }, [props.value, props.options, filterBy]);

  const getNewSelectedOption = (option, toggle) => {
    if (props.multiple) {
      if (toggle) {
        // Add element
        return selectedOption.filter(
          (element) => element.value === option.value
        ).length
          ? selectedOption
          : [...selectedOption, option];
      }
      // Remove element
      return selectedOption.filter((element) => element.value !== option.value);
    }
    return [option];
  };

  const getInputValue = (option, newSelectedOption) => {
    const {
      multiple,
      intl: { formatMessage: t },
      translateOption,
      optionKey
    } = props;

    let inputValue;

    if (multiple) {
      inputValue = t(messages.dropdown.selectedOptionNumber, {
        num: newSelectedOption.length
      });
    } else if (translateOption) {
      inputValue = translateOption(newSelectedOption);
    } else {
      inputValue = option[optionKey];
    }

    return inputValue;
  };

  const onOptionSelect = (option, toggle) => {
    const { multiple, setValue } = props;
    const newSelectedOption = getNewSelectedOption(option, toggle);
    setValue(newSelectedOption);
    setOptionMessage(getInputValue(option, newSelectedOption));
    setSelectedOption(newSelectedOption);
    if (!multiple) {
      setFocus(false);
    }
  };

  const multipleOptionMessage = () => {
    const { label, placeholder } = props;
    if (selectedOption.length === 0) {
      return placeholder || label;
    }
    return optionMessage;
  };

  const getInput = () => {
    const { error, label, placeholder, setSize, success, value, disabled } =
      props;
    const textMessage = multipleOptionMessage();

    return (
      <div
        className={classnames('dropdown-input-container', {
          'dropdown-input-container-open': focus,
          'dropdown-input-container-success': success,
          'dropdown-input-container-error': error
        })}
      >
        <label id={`${getId()}-label`} hidden>
          {label}
        </label>
        <input
          aria-labelledby={`${getId()}-label`}
          aria-haspopup="true"
          aria-expanded={focus}
          autoComplete="off"
          className={classnames('dropdown-input', {
            'dropdown-input-active':
              (value && value.length) || optionMessage.length,
            'dropdown-input-success': success,
            'dropdown-input-error': error
          })}
          id={getId()}
          readOnly
          size={setSize && textMessage.length + 2}
          value={textMessage}
          placeholder={placeholder || label}
          disabled={disabled}
          list={getId()}
          onClick={disabled ? undefined : () => setFocus(!focus)}
        />
      </div>
    );
  };

  const handleEnterPress = (e) => {
    const options = _options;
    const option = options[0];

    if (e.keyCode === 13 && option) {
      e.preventDefault();
      const { value } = option;
      setFilterBy(value);
      setOptionMessage(value);
      setSelectedOption([option]);
      onOptionSelect(option, true);
    }

    return null;
  };

  const getFilterInput = () => {
    const {
      filter,
      intl: { formatMessage: t },
      disabled
    } = props;
    if (filter) {
      return (
        <input
          id={getId() + '-filter'}
          aria-labelledby={getId()}
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
          onKeyDown={(e) => handleEnterPress(e)}
          placeholder={t(messages.filter)}
          ref={inputRef}
          disabled={disabled}
        />
      );
    }
    return null;
  };

  return (
    <div
      id="dropdown"
      className={classnames('dropdown-container', {
        [props.className]: Boolean(props.className)
      })}
      ref={containerRef}
    >
      {!props.hideLabel && (
        <label
          className={classnames('dropdown-label', {
            'dropdown-label-success': props.success,
            'dropdown-label-error': props.error
          })}
          htmlFor={getId()}
        >
          {props.label}
        </label>
      )}
      {getInput()}
      {!props.disabled &&
        props.render &&
        props.render(_options, {
          filter: getFilterInput(),
          open: focus,
          selected: selectedOption,
          multiple: props.multiple,
          onChange: onOptionSelect,
          labelledBy: getId()
        })}
      <span
        id="dropdown_message"
        className={classnames('dropdown-message', {
          'dropdown-message-error': props.error
        })}
      >
        {props.message}
      </span>
    </div>
  );
};

Dropdown.defaultProps = {
  className: '',
  error: false,
  filter: false,
  message: '',
  multiple: false,
  optionKey: 'value',
  placeholder: '',
  success: false,
  disabled: false,
  hideLabel: false
};

Dropdown.propTypes = {
  /** Custom class for better control in how the component should look by its parent */
  className: PropTypes.string,
  /** Error flag if true render border red and the x mark*/
  error: PropTypes.bool,
  /** Enables Filter on dropdown (does not work with multiple option) */
  filter: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  /** Input Label & Placeholder if it is not defined*/
  label: PropTypes.string.isRequired,
  /** message if not empty will render and inherits color if error */
  message: PropTypes.string,
  /** Enables selecting multiple options from the dropdown */
  multiple: PropTypes.bool,
  /** Array of options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  /** Key to display on option selected */
  optionKey: PropTypes.string,
  /** Placeholder if not set label be used instead */
  placeholder: PropTypes.string,
  /** Receives a function that call with (options,child-props) and render it inside the component */
  render: PropTypes.func,
  /** Sets the size attribute of the input HTML tag based on the string length */
  setSize: PropTypes.bool,
  /** Callback function call when value is updated */
  setValue: PropTypes.func.isRequired,
  /** If true render the border green and show the ✓ mark */
  success: PropTypes.bool,
  /** If the selected option needs to be translated, this function should be provided */
  translateOption: PropTypes.func,
  /** The current value  */
  value: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool
};

export default injectIntl(Dropdown);
